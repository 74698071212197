import React from 'react';
import { useDispatch } from 'react-redux';
import { AcquiringCommissions, getQrRequest } from '@modules/orders';
import { RoundIcon } from '@components/loader';
import { Text } from '@components/ui';
import NotifyLoader from '../NotifyLoader';
import { Hooks } from '@utils';
import { PAYMENT_METHODS } from '@components/common/PaymentContainer/constants';
import sbpPaymentImageWhite from '@assets/media/order-detail/sbpPaymentImageWhite.svg';

import {
  Wrapper,
  Title,
  IframeContainer,
  IframeLoaderContainer,
  PaymentHeader,
  MobilePaymentHeader,
  MobilePaymentItem,
  MobilePaymentList,
  MobilePaymentHeaderLeft,
  SBPPaymentButton,
  SBPContainer,
  BlueText,
} from './Payment.styles';
import { PaymentMethod } from '@components/common/PaymentContainer/PaymentContainer';
import PaymentIframe from '@components/common/PaymentContainer/PaymentIframe';

interface Props {
  acquiringCommissions: AcquiringCommissions[];
  canPay: boolean;
  chosenPaymentMethod: string;
  height: string;
  isNotifyLoader: boolean;
  isPaymentLoader?: boolean;
  paymentMethods: PaymentMethod[];
  paymentQr: { svg: string } | null;
  paymentTinkoffQrNumber: number | null;
  paymentTinkoffUrl: string | null;
  paymentUrl: string | null;
  qrCode: string | null;
  orderNumber: number;
  orderPositionIds: string[];

  setChosenPaymentImage(img: string): void;

  setChosenPaymentMethod(method: string): void;

  setQrCodeIsLoading(state: boolean): void;

  setSbpModalIsOpen(state: boolean): void;
}

const PaymentMobile: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { isMobile } = Hooks.useMediaSizes();
  const {
    acquiringCommissions,
    canPay,
    chosenPaymentMethod,
    height,
    isNotifyLoader,
    isPaymentLoader,
    paymentMethods,
    paymentQr,
    paymentTinkoffQrNumber,
    paymentTinkoffUrl,
    paymentUrl,
    qrCode,
    orderNumber,
    orderPositionIds,
  } = props;

  const choosePaymentClick = (item: PaymentMethod) => {
    if (item.label === PAYMENT_METHODS.SBP && paymentTinkoffQrNumber) {
      if (!paymentQr) {
        props.setQrCodeIsLoading(true);
      }
      dispatch(
        getQrRequest({
          orderNumber: orderNumber,
          orderPositionIds: orderPositionIds,
          tinkoffSettingType: 0,
          tinkoffQrType: isMobile ? 1 : 0,
        }),
      );
      props.setSbpModalIsOpen(true);
    } else {
      props.setChosenPaymentMethod(item.label);
      props.setChosenPaymentImage(item.img);
      if (item?.value) {
        dispatch(item.value);
      }
    }
  };

  const paymentMethod = (item: PaymentMethod, index: number) => {
    const isActive = chosenPaymentMethod === item.label;
    const isSBP = item.label === PAYMENT_METHODS.SBP;
    return (
      <>
        <MobilePaymentItem
          isActive={isActive}
          key={index}
          onClick={() => choosePaymentClick(item)}
        >
          <MobilePaymentHeader>
            <MobilePaymentHeaderLeft>
              <img src={item.img} />
              <div> {item.label} </div>
            </MobilePaymentHeaderLeft>

            <BlueText>
              {item.label !== PAYMENT_METHODS.CREDIT ? (
                <>
                  {item.amount
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                  &nbsp;₽
                </>
              ) : (
                <> &nbsp;</>
              )}
            </BlueText>
          </MobilePaymentHeader>
          {isSBP && (
            <SBPContainer>
              <Text>
                Нажмите на кнопку и оплатите через мобильное приложение банка.
              </Text>
              <SBPPaymentButton>
                <div>
                  <span>Оплатить через СБП</span>
                  <img
                    alt={'Иконка СБП'}
                    width={48}
                    height={24}
                    src={sbpPaymentImageWhite}
                  />
                </div>
              </SBPPaymentButton>
            </SBPContainer>
          )}
          {isActive && paymentUrl && !isPaymentLoader ? (
            <PaymentIframe
              acquiringCommission={acquiringCommissions[1]}
              chosenPaymentMethod={chosenPaymentMethod}
              height={height}
              paymentTinkoffUrl={paymentTinkoffUrl}
              paymentUrl={paymentUrl}
              qrCode={qrCode}
            />
          ) : (
            isActive && (
              <IframeContainer
                isMTS={chosenPaymentMethod === PAYMENT_METHODS.CREDIT}
                isINT={chosenPaymentMethod === PAYMENT_METHODS.FOREIGN_CARD}
                isHeight={height !== '0px'}
                isSBP={chosenPaymentMethod === PAYMENT_METHODS.SBP}
              >
                <IframeLoaderContainer>
                  <RoundIcon />
                </IframeLoaderContainer>
              </IframeContainer>
            )
          )}
        </MobilePaymentItem>
      </>
    );
  };

  return (
    <Wrapper>
      {isNotifyLoader && <NotifyLoader />}
      <PaymentHeader>
        <Title>Оплата заказа</Title>
        <Text>
          Выберите любой подходящий способ для оплаты вашего бронирования №
          {orderNumber}
        </Text>
      </PaymentHeader>
      {canPay && (
        <MobilePaymentList>
          {paymentMethods.map((item, index) => paymentMethod(item, index))}
        </MobilePaymentList>
      )}
    </Wrapper>
  );
};

export default PaymentMobile;
