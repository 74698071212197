import React from 'react';
import styled from 'styled-components';
import Text from '@components/ui/Typography';
import Tippy from '@tippyjs/react';
import { Helper } from '@utils';
import { FlightType } from '@components/booking/FlightDetail/types';
import AviaFeatures from '@components/common/FlightSegment/AviaFeatures';
import FlightTypeIconList from '@components/common/FlightTypeIconList';

interface Props {
  fromCityName: string;
  toCityName: string;
  airlinesInfo?: {
    name: string;
    code: string;
  }[];
  flightIndex?: number;
  flight_Type: FlightType | FlightType[];
  price: number;
  features: {
    applicability: string;
    type: string;
    content: string;
  }[];
}

const HeadTitle = styled(Text)`
  display: block;
  padding-left: 7px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Flex = styled.div`
  display: flex;

  & > div + div {
    margin-left: 6px;
  }
`;

const AirlineLogo = styled.img.attrs({ width: 25, height: 25 })`
    border-radius: 50%;
    
    & + & {
        right: 6px;
        position: relative;
    }
`;

const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 10px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const HeadLeftPart = styled.div`
  display: flex;
  align-items: center;
`;

const HeadRightPart = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * Компонент для отображения заголовка сегмента авиабилета
 * @param fromCityName откуда
 * @param toCityName куда
 * @param airlinesInfo информация о перевозчиках
 * @param flightIndex индекс перелета
 * @param flight_Type тип перелета
 * @param price цена
 * @param features список сервисов
 * @constructor
 */
const AviaSegmentHeader: React.FC<Props> = ({
  fromCityName,
  toCityName,
  airlinesInfo,
  flightIndex,
  flight_Type,
  price,
  features,
}) => {
  const airlinesIcons = (
    airlines: {
      name: string;
      code: string;
    }[],
  ) => {
    return airlines.map((airline) => {
      return (
        <Tippy key={airline.code} content={airline.name} offset={[0, 16.5]}>
          <AirlineLogo
            src={Helper.getAirlinesLogoUrl(airline.code, 50, 50, false)}
          />
        </Tippy>
      );
    });
  };

  return (
    <HeadContainer>
      <HeadLeftPart>
        {!!airlinesInfo &&
          airlinesInfo?.length > 0 &&
          airlinesIcons(airlinesInfo)}
        <HeadTitle fontWeight={'600'}>
          {flightIndex !== undefined
            ? flightIndex
              ? 'Обратно:'
              : 'Туда:'
            : ''}{' '}
          {fromCityName} — {toCityName}
        </HeadTitle>
        <Flex>
          <FlightTypeIconList
            flightTypes={
              Array.isArray(flight_Type) ? flight_Type : [flight_Type]
            }
          />
        </Flex>
      </HeadLeftPart>
      <HeadRightPart>
        <AviaFeatures price={price} features={features} />
      </HeadRightPart>
    </HeadContainer>
  );
};

export default AviaSegmentHeader;
