import {
  PassengerTypes,
  AvailableActions,
  FieldsTypes,
  EventSegmentsTypes,
  AddictionalServiceTypes,
} from './enums';
import { BusOrderListPosition, TrainOrderListPosition } from '@modules/orders';
import { TechLanding } from '@app/components/common';
import { FareFeatureStatus, FromToEntity } from '@modules/booking';
import { FlightType } from '@components/booking/FlightDetail/types';

export interface SegmentEvents {
  arrivalCity: {
    code: string;
    name: string;
  };
  departureCity: {
    code: string;
    name: string;
  };
  fields: {
    type: FieldsTypes;
    description: string;
    oldValue: string;
    newValue: string;
  }[];
  type: EventSegmentsTypes;
}

//TODO: add type for passengersEvents
export interface Notification {
  type: string;
  supportTicketNumber: number;
  calculationId: string;
  price: number;
  route: string;
  segmentEvents?: SegmentEvents[];
  passengersEvents?: any;
}

export type FareFamily = {
  arrivalAirportCode: string;
  carrier: string;
  code: string;
  departureAirportCode: string;
  features: {
    applicability: FareFeatureStatus;
    type: string;
    descriptionEng: string;
    descriptionRus: string;
  }[];
  name: string;
  routes?: FromToEntity[];
  serviceClass: string;
};

export type Tariff = {
  fareFamilies: FareFamily[] | null;
  adultPrice: number;
  childPrice: number;
  infantPrice: number;
  description: string;
  gds: string;
  serviceClass: null;
  currencyCode: string;
  isExchangeable: boolean;
  isRefundable: boolean;
  hasConnectingFlights: boolean;
};

export type AncillaryServices = {
  aviaOrderPositionId: string;
  aviaPassengerId: string;
  aviaSegmentIds: string[] | null;
  value: number;
  quantity: number;
  name: string;
  type: string;
  gds: string;
  carrier: string;
  isRefundable: true;
  isEchangeable: true;
  emDcode: string | null;
  basePrice: number;
  commisson: number;
  agentExtraCharge: number;
  price: number;
  id: string;
  status: { code: string; description: string };
  segmentFromTo?: string;
  passangerName?: string;
};

export interface BaseDescription {
  code: string;
  name: string;
}

export type Segment = {
  airplane: AirplaneDescription;
  arrivalAirport: AirportDescription;
  arrivalCity: BaseDescription;
  arrivalDateTime: string;
  // arrivalDateTimeUtc: string;
  bookingClass: string;
  bookingClassRu: string;
  cabinDesignator: null;
  departureAirport: AirportDescription;
  departureCity: BaseDescription;
  departureDateTime: string;
  // departureDateTimeUtc: string;
  duration: number;
  farefamilyMarketingName: null;
  flightNumber: string;
  flight_Type: FlightType;
  id: string;
  index: number;
  isLowcoster: boolean;
  // logoUrl: string | null;
  marketingAirline: AirlineDescription;
  operatingAirline: AirlineDescription;
  status: { code: string; description: string };
  stopOvers: Transfer[] | [];
  techLandings: TechLanding[] | [];
  classCode: string;
};

export type SegmentGroup = {
  arrivalAirport: AirportDescription;
  arrivalCity: BaseDescription;
  arrivalDateTime: string;
  departureAirport: AirportDescription;
  departureCity: BaseDescription;
  departureDateTime: string;
  duration: number;
  index: number;
  segments: Segment[];
};

export interface AirportDescription extends BaseDescription {
  terminal: string;
}

export interface AirplaneDescription extends BaseDescription {
  nameEn: string;
}

export interface AirlineDescription extends BaseDescription {
  logoUrl: string | null;
}

export type Transfer = {
  arrivalDateTime: string;
  departureDateTime: string;
  duration: number;
};

export enum AviaTicketStatusCode {
  Refunded = 'Refunded',
  Voided = 'Voided',
  Unknown = 'Unknown',
  Exchanged = 'Exchanged',
  PartiallyRefunded = 'PartiallyRefunded',
  PartiallyExchanged = 'PartiallyExchanged',
  Active = 'Active',
}

export type AviaTickets = {
  arrivalAirportCode: string;
  departureAirportCode: string;
  eTicketNumber: string;
  id: string;
  status?: {
    code: AviaTicketStatusCode;
    description: string;
  };
};

export type Passenger = {
  passengerType: keyof typeof PassengerTypes;
  aviaTickets: AviaTickets[];
  phone: string | null;
  email: string | null;
  firstName: string;
  lastName: string;
  patronymic: string;
  gender: string;
  citizen: string;
  birthDate: string;
  passengerId: string;
  document: {
    type: string;
    number: string;
    issueDate: string | null;
    issueDateExist: boolean;

    // TODO: TTE-9176 отрефакторить типы
    documentType?: string;
    documentNumber?: string;
  };
  loyalityCard: string | null;
  passengerPriceDetalization: {
    basePrice: number;
    taxes: number;
    subAgentExtraCharge: number;
    agentExtraCharge: number;
    discount: number;
  };

  // TODO: TTE-9176 отрефакторить типы
  citizenshipLabel?: string;
  passengerCategoryType?: keyof typeof PassengerTypes;
};

export interface PriceDetalizationItem {
  priceType: number;
  description: string;
  value: number;
  quantity: number | null;
  detalization: PriceDetalizationItem[];
}

export interface PriceDetalization {
  description: string;
  detalization: PriceDetalizationItem[];
  priceType: number;
  quantity: number | null;
  value: number;
}

export type GeneratedTuiOrderServiceApiDomainModelOrderProjectionsAirline = {
  /** Код */
  code?: string | null;

  /** Название */
  name?: string | null;

  /** Лого урл */
  logoUrl?: string | null;
};

export type GeneratedTuiOrderServiceApiDomainModelOrderProjectionsSegmentOnlineBookingCode =
  {
    code?: string | null;

    /** Авиакомпании совершающие перелет по номеру брони */
    airlines?:
      | GeneratedTuiOrderServiceApiDomainModelOrderProjectionsAirline[]
      | null;
  };

export type OrderPosition = {
  id: string;
  amount: number;
  index: number;

  statusDescription: string;
  bookingCode: string;
  validationAirline: string;
  validationAirlineName: string;

  segmentGroups: SegmentGroup[];
  cancellationTime: string;
  status: { code: string; description: string };
  paymentStatus: { code: string; description: string };
  isAnnulatedOrderPosition: boolean;
  flight_Type: FlightType;
  aviaOrderPosition: {
    externalId: null;
    issuedTime: null;

    code: string;
    description: string;
  };
  seatsAssignment: Seats[];
  createdDate: string;
  tariff: Tariff;
  luggages:
    | {
        passengerId: string;
        items: AncillaryServices[];
      }[]
    | [];
  meals:
    | {
        passengerId: string;
        items: AncillaryServices[];
      }[]
    | [];
  otherAncillaryServices:
    | {
        passengerId: string;
        items: AncillaryServices[];
      }[]
    | [];
  passengers: Passenger[];
  isActive: boolean;
  extraCharge: {
    agentExtraCharge: number;
    basePrice: number;
    discount: number;
    subAgentExtraCharge: number;
    taxes: number;
  };
  availableActions: (keyof typeof AvailableActions)[];
  orderPositionAmount: PriceDetalization;
  isTrainOrder?: boolean;
  airlineOnlineBookingCode?: {
    /** Номер брони, который мы получаем от поставщика */
    fullName?: string | null;

    /** Преобразованный номер брони */
    bookingCodes?:
      | GeneratedTuiOrderServiceApiDomainModelOrderProjectionsSegmentOnlineBookingCode[]
      | null;
  };
};

export interface Customer {
  email: string | null;
  externalId: null;
  firstName: string | null;
  id: string | null;
  lastName: string | null;
  phone: string | null;
  agreementToMailing: boolean;
}

export interface ContactInfo {
  contactName: string | null;
  contactEmail: string | null;
  contactPhone: string | null;
}

export interface Airline extends BaseDescription {
  logoUrl: string | null;
}

export interface InsuranceProductEntity {
  name: string;
  description: string;
  policies: {
    id: string;
    policyId: string;
    passenger: {
      aviaPassengerId: string;
      firstName: string;
      lastName: string;
      patronymic: string;
    };
    policyUrl: string;
    price: number;
    status: string;
    number: string;
  }[];
  paymentStatus: StatusEntity;
}

interface StatusEntity {
  code: string;
  description: string;
}

export interface InsuranceEntity {
  orderPositionId: string;
  route: string;
  products: InsuranceProductEntity[];
  paymentStatus: StatusEntity;
  status: StatusEntity;
}

export interface Seats {
  passengerId: string;
  items: ItemsEntity[];
}

export interface ItemsEntity {
  id: string;
  aviaOrderPositionId: string;
  aviaPassengerId: string;
  aviaSegmentIds: string[];
  value?: null;
  quantity?: null;
  name: string;
  type: string;
  gds: string;
  seatNumber?: null;
  carrier: string;
  isRefundable: boolean;
  isEchangeable: boolean;
  emDcode?: null;
  rfic: string;
  rfisc: string;
  basePrice: number;
  commission: number;
  discount: number;
  agentExtraCharge: number;
  price: number;
  ancillaryServiceAmount: AncillaryServiceAmount;
  status: Status;
  passangerName?: string;
}

export interface AncillaryServiceAmount {
  priceType: number;
  description: string;
  value: number;
  quantity?: null;
  detalization?: DetalizationEntity[] | null;
}

export interface DetalizationEntity {
  priceType: number;
  description: string;
  value: number;
  quantity?: null;
  detalization?: null[] | null;
}

export interface Status {
  code: string;
  description: string;
}

export interface AcquiringCommissions {
  acquiringAmount: number;
  acquiringPercent: number;
  amount: number;
  paymentType: string;
}

export interface OrderOrPaymentStatus {
  code: string;
  description: string;
}

export type AvailableOrderActionsType = (
  | 'PAYMENT'
  | 'EXTRAPAYMENT'
  | 'DISPLAY_ADDITIONAL_SERVICES'
  | 'PAYMENT_RECEIPT'
  | 'RAILWAY_ORDER_CANCEL'
)[];

export interface GetOrderByNumberDto {
  amount: number;
  amountExtraPayment: number;
  agentName: string;
  acquiringCommissions: AcquiringCommissions[];
  amountTickets: number;
  arrivalDate: string;
  backOfficeId: number;
  salesChannelType: string;
  salesChannel: string;
  createdDate: string;
  customerId: string;
  departureDate: string;
  id: string;
  orderNumber: number;
  orderStatus: OrderOrPaymentStatus;
  paymentStatus: OrderOrPaymentStatus;
  flightSearchParameters?: any;
  cancellationTime: string;
  orderPositions: OrderPosition[];
  hasConnectingFlights: boolean;
  busOrderPositions:
    | {
        orderPosition: BusOrderListPosition;
      }[]
    | [];
  railwayOrderPositions:
    | {
        orderPosition: TrainOrderListPosition;
      }[]
    | [];
  customer: Customer;
  contactsInfo: ContactInfo;
  isLowcoster: boolean;
  annulatedAviaOrderPositions: OrderPosition[];
  orderAmount: PriceDetalization;
  additionalServices: {
    id: string;
    type: AddictionalServiceTypes;
    amount: number;
    number: number;
    status: {
      code: string;
      description: string;
    };
    paymentStatus: {
      code: string;
      description: string;
    };
    additionalContactInfo: {
      contactName: string;
      contactEmail: null;
      contactPhone: string;
    } | null;
    cancellationTime: string | null;
  }[];
  availableActions: AvailableOrderActionsType;
  insurances: InsuranceEntity[];
  invoice: {
    items: { price: number; description: string }[];
    total: number;
  };
  notifications: Notification[];
  documents: {
    type: string;
    title: string;
    subTitle: string;
    url: string | null;
    orderPositionId: string | null;
  }[];
  supportTickets: {
    bookingCode: string;
    createDate: string;
    flightType: string | null;
    id: string;
    messagesCount: number;
    number: number;
    status: {
      code: string;
      description: string;
    };
    subject: {
      code: string;
      description: string;
    };
    unreadMessagesNumber: number;
  }[];
  availableCreditPaymentMethods: {
    creditPaid: boolean;
    creditEnough: boolean;
    errorText: string;
  } | null;
  availableShipmentAllowed: {
    Description: null | string;
    availableIssue: boolean;
    errorText: null | string;
    orderSum: null | number;
    ticketIssue: boolean;
    title: null | string;
  } | null;
}
