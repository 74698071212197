import React from 'react';
import { RoundIcon } from '@components/loader';
import { Hooks } from '@utils';
import Button from '@components/ui/Button';

import {
  StyledCloseIcon,
  SpbHeader,
  SbpLeftSide,
  SbpRightSide,
  BlockText,
  QRCodeLoaderContainer,
  QRCodeText,
  PaymentButtonContainer,
  FlexAlign,
  StyledPopup,
} from './Payment.styles';
import { PaymentMethod } from '@components/common/PaymentContainer/PaymentContainer';

interface Props {
  sbpModalIsOpen: boolean;
  qrCodeIsLoading: boolean;
  isProcessing?: boolean;
  paymentMethod: PaymentMethod;
  qrCode: string | null;
  setSbpModalIsOpen(state: boolean): void;
  setQrCodeIsLoading(state: boolean): void;
}

const QRCodePopup: React.FC<Props> = (props) => {
  const { isMobile } = Hooks.useMediaSizes();
  const {
    sbpModalIsOpen,
    qrCodeIsLoading,
    isProcessing,
    paymentMethod,
    qrCode
  } = props;

  const handleCloseSbpModal = () => {
    props.setSbpModalIsOpen(false);
    props.setQrCodeIsLoading(false);
  };


  return (
    <StyledPopup onClose={handleCloseSbpModal} open={sbpModalIsOpen}>
      {qrCodeIsLoading ? (
        <QRCodeLoaderContainer>
          <RoundIcon />
          {isProcessing && <QRCodeText>Обработка платежа</QRCodeText>}
        </QRCodeLoaderContainer>
      ) : (
        <>
          <SpbHeader>
            <SbpLeftSide>
              <img
                src={paymentMethod.img}
                style={{
                  marginRight: 7,
                }}
              />{' '}
              <span>{paymentMethod.label}</span>
              <div
                style={{
                  color: '#4872F2',
                  marginLeft: 7,
                }}
              >
                <>
                  {paymentMethod.amount
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                  &nbsp;₽
                </>
              </div>
            </SbpLeftSide>
            <SbpRightSide>
              <StyledCloseIcon onClick={handleCloseSbpModal} />
            </SbpRightSide>
          </SpbHeader>
          {isMobile ? (
            <PaymentButtonContainer>
              <Button
                type={'primary'}
                color={'yellow'}
                onClick={() => {
                  if (qrCode) {
                    window.location.href = qrCode;
                  }
                }}
              >
                Оплатить
              </Button>
            </PaymentButtonContainer>
          ) : (
            !!qrCode && (
              <>
                <BlockText style={{ marginBottom: 20 }}>
                  Отсканируйте QR-код с помощью своего смартфона или
                  мобильного приложения банка. <br /> После успешной оплаты
                  билеты будут направлены вам по электронной почте.
                </BlockText>
                <FlexAlign>
                  <img
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(
                      qrCode,
                    )}`}
                  />
                </FlexAlign>
              </>
            )
          )}
        </>
      )}
    </StyledPopup>
  );
};

export default QRCodePopup;
