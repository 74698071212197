import React from 'react';
import { useSelector } from 'react-redux';
import { getPriceDetail, getSelectedFare } from '@modules/booking';
import { ApplicationState } from '@modules/index';
import PassengerPrice from '@components/booking/PassengerPrice/PassengerPrice';
import { PAYMENT_METHODS } from '@components/common/PaymentContainer/constants';

const AviaPassengerPriceBooking: React.FC = () => {
  const { total, subagentTotal, detailList } = useSelector(getPriceDetail);
  const fare = useSelector(getSelectedFare);
  const isShowCommission = useSelector(
    (state: ApplicationState) => state?.user?.userCommissionStatus
  );

  return (
    <PassengerPrice
      priceType={'avia'}
      title={'Тариф'}
      total={total}
      subagentTotal={subagentTotal}
      detailList={detailList}
      fare={fare}
      isShowCommission={isShowCommission}
      chosenPaymentMethod={PAYMENT_METHODS.RF_CARD}
    />
  );
};

export default AviaPassengerPriceBooking;
