import React from 'react';
import {
  FLIGHT_TYPES,
  FlightType,
} from '@components/booking/FlightDetail/types';
import FlightTypeIcon from '@components/common/FlightTypeIcon';
import styled from 'styled-components';

interface Props {
  flightTypes: FlightType[];
}

const List = styled.ul`
  list-style-type: none;
  display: flex;
  gap: 8px;
  padding: 0;
`;

const ListItem = styled.li`
  &:first-child {
    margin-left: 8px;
  }

  @media (max-width: 767px) {
    &:first-child {
      margin-left: 0;
    }
  }
`;

const FlightTypeIconList: React.FC<Props> = (props) => {
  const { flightTypes } = props;

  if (!flightTypes || flightTypes.length === 0) {
    return null;
  }

  const sortedFlightTypes = React.useMemo(() => {
    const order = [
      FLIGHT_TYPES.REGULAR,
      FLIGHT_TYPES.LOWCOSTER,
      FLIGHT_TYPES.CHARTER,
    ];
    return flightTypes
      .slice()
      .sort((a, b) => order.indexOf(a) - order.indexOf(b));
  }, [flightTypes]);

  return (
    <List>
      {sortedFlightTypes.map((flightType, index) => (
        <ListItem key={index}>
          <FlightTypeIcon flightType={flightType} />
        </ListItem>
      ))}
    </List>
  );
};

export default FlightTypeIconList;
