import React from 'react';
import packageJson from '../package.json';
// import moment from 'moment';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, MainTheme } from './theme';
import 'rc-slider/assets/index.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import './assets/css/main.css';
import store from '@modules/index';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'reactjs-popup/dist/index.css';
import 'react-datepicker/dist/react-datepicker.css';
import Router from '@navigation';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { YMInitializer } from 'react-yandex-metrika';
import * as Sentry from '@sentry/react';
import 'moment/locale/ru';
import useLogSessionId from '@modules/logs/useLogSessionId';
// import { BUILD_TIME } from './buildTime';

registerLocale('ru', ru);
setDefaultLocale('ru');

function App() {
  const logSessionId = useLogSessionId();

  React.useEffect(() => {
    console.log(
      '%c🚀 Версия приложения: ' + packageJson.version,
      'font-size:10px',
    );
    // console.log(
    //   "%c🚀 Дата сборки: " + moment(BUILD_TIME).format('DD.MM.YYYY HH:mm'),
    //   "font-size:14px"
    // );
    const handleResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={MainTheme}>
        {process.env.REACT_APP_MODE !== 'test' && (
          <YMInitializer
            options={{
              webvisor: true,
              defer: true,
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
            }}
            version={'2'}
            accounts={[93517693]}
          />
        )}

        <GlobalStyles />
        <Provider store={store}>
          <Router />
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default Sentry.withProfiler(App);
