import {
  FlightSearchDTO,
  PromotionsDto,
  IFlightSearchStateBase,
  FlightSegmentPlace,
  Filter,
  Airline,
  Airport,
  SearchWithReturnParams,
} from './types';
import { CitySearchDTO } from '../simpleSearch';
import moment from 'moment';
import { cityFrom, cityTo } from 'lvovich';
import { normalizeSearchResponse } from './normalizeSearchResponse';
import { Api, Helper } from '@utils';
import { AVIA_SERVICE_HOST } from '@modules/constants';
import _ from 'lodash';

const URI = process.env.REACT_APP_FLIGHTS_API as string;
const ENDPOINT = process.env.REACT_APP_FLIGHTS_SEARCH as string;

const createAviaUrl = Api.createFetchUrl(AVIA_SERVICE_HOST, URI);
const createCitiesUrl = Api.createFetchUrl(
  AVIA_SERVICE_HOST,
  process.env.REACT_APP_CITIES_API as string,
);

export function searchWithReturn({
  origin,
  destination,
  forwardDate,
  backwardDate,
  passengers: { adults, children, infants },
  category,
  agentSamoCode,
  saleChannel,
  pluginToken,
  cache,
}: SearchWithReturnParams) {
  const fromTo = `${origin}-${destination}`;
  const toFrom = `${destination}-${origin}`;
  const passengers = `${adults}-${children}-${infants}`;

  if (pluginToken !== '') {
    return Api.request({
      url: createAviaUrl(
        `${ENDPOINT}/${fromTo}/${forwardDate}/${toFrom}/${backwardDate}/${passengers}/${category}/false/${
          saleChannel ? saleChannel : 'z'
        }`,
        {
          nextDate: false,
          debug: true,
          // gds: 'S7Ndc',
          agentCode: agentSamoCode ? agentSamoCode : '',
          cache: cache,
        },
      ),
      headers: { 'X-Account': pluginToken },
    })
      .then(Api.statuses)
      .then((data) => Api.json(data) as Promise<FlightSearchDTO>)
      .then(normalizeSearchResponse)
      .then((data) => ({ ...data, filter: createFilter(data) }))
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  } else
    return Api.request({
      url: createAviaUrl(
        `${ENDPOINT}/${fromTo}/${forwardDate}/${toFrom}/${backwardDate}/${passengers}/${category}/false/${
          saleChannel ? saleChannel : 'z'
        }`,
        {
          nextDate: false,
          debug: true,
          // gds: 'S7Ndc',
          agentCode: agentSamoCode ? agentSamoCode : '',
          cache: cache,
        },
      ),
    })
      .then(Api.statuses)
      .then((data) => Api.json(data) as Promise<FlightSearchDTO>)
      .then(normalizeSearchResponse)
      .then((data) => ({ ...data, filter: createFilter(data) }))
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
}

interface SearchOneWayParams {
  origin: string;
  destination: string;
  forwardDate: string;
  passengers: {
    adults: string;
    children: string;
    infants: string;
  };
  category: string;
  agentSamoCode?: number;
  saleChannel?: string;
  pluginToken: string;
  cache?: boolean;
}

export function searchOneWayDirection({
  origin,
  destination,
  forwardDate,
  passengers: { adults, children, infants },
  category,
  agentSamoCode,
  saleChannel,
  pluginToken,
  cache,
}: SearchOneWayParams) {
  const fromTo = `${origin}-${destination}`;
  const passengers = `${adults}-${children}-${infants}`;

  if (pluginToken !== '') {
    return Api.request({
      url: createAviaUrl(
        `${ENDPOINT}/${fromTo}/${forwardDate}/${passengers}/${category}/false/${
          saleChannel ? saleChannel : 'z'
        }`,
        {
          nextDate: false,
          debug: true,
          // gds: 'S7Ndc',
          agentCode: agentSamoCode ? agentSamoCode : '',
          cache: cache,
        },
      ),
      headers: { 'X-Account': pluginToken },
    })
      .then(Api.statuses)
      .then((data) => Api.json(data) as Promise<FlightSearchDTO>)
      .then((data) => normalizeSearchResponse(data, true))
      .then((data) => ({ ...data, filter: createFilter(data) }))
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  } else
    return Api.request({
      url: createAviaUrl(
        `${ENDPOINT}/${fromTo}/${forwardDate}/${passengers}/${category}/false/${
          saleChannel ? saleChannel : 'z'
        }`,
        {
          nextDate: false,
          debug: true,
          // gds: 'S7Ndc',
          agentCode: agentSamoCode ? agentSamoCode : '',
          cache: cache,
        },
      ),
    })
      .then(Api.statuses)
      .then((data) => Api.json(data) as Promise<FlightSearchDTO>)
      .then((data) => normalizeSearchResponse(data, true))
      .then((data) => ({ ...data, filter: createFilter(data) }))
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
}

export const getCityListByStr = function (str: string): Promise<CitySearchDTO> {
  return Api.request({
    url: createCitiesUrl(process.env.REACT_APP_CITY_SEARCH as string, {
      value: str,
    }),
  })
    .then(Api.statuses)
    .then(Api.json);
};

export const getPromitions = function () {
  return Api.request({
    url: process.env.REACT_APP_GET_PROMOTION as string,
  })
    .then(Api.statuses)
    .then((data) => Api.json(data) as Promise<PromotionsDto>);
};

//LEGACY
//

function createFilter(data: IFlightSearchStateBase): Filter {
  let flightsDurations: {
    from: FlightSegmentPlace;
    to: FlightSegmentPlace;
    max: number;
    min: number;
    tickets: number[];
  }[] = [];

  const transfers: { list: Set<number>; tickets: number[] } = {
    list: new Set(),
    tickets: [],
  };

  const transferDurationList: number[] = [];
  const prices: number[] = [];
  const flightTypes: string[] = [];
  const airGds: string[] = [];
  let airGdsValues: { [key: string]: boolean } = {};
  const airlines: { [key: string]: Airline } = {};
  const airlinesValues: { [key: string]: boolean } = {};
  //TODO
  const timeArr: {
    from: {
      city: string;
      label: string;
      min: number;
      max: number;
      values: number[];
      tickets: number[];
    };
    to: {
      city: string;
      label: string;
      min: number;
      max: number;
      values: number[];
      tickets: number[];
    };
  }[] = [];

  data.references.Airlines.forEach((airline) => {
    airlines[airline.code] = airline;
    // airlinesValues[airline.code] = true;
  });

  const airports: {
    label: string;
    airports: { [key: string]: Airport };
    tickets: string[];
  }[] = [];
  const ticketAirlines: string[][][] = [];
  const baggageList: (string | null)[] = [];
  const hasConnections: boolean[] = [];

  const baggageFilterList: Set<string> = new Set();

  data.flightsList.items.forEach((ticket, index) => {
    prices.push(ticket.prices[0]);
    flightTypes.push(ticket.flights[0].segments[0].flight_Type);
    airGds.push(ticket.airGds);

    let stopsCount = 0;
    let transferDuration = 0;
    let count = 0;
    ticketAirlines[index] = [];

    baggageList.push(
      ticket.baggage === null
        ? null
        : ticket.baggage.value > 0
          ? 'yes'
          : ticket.baggage.value < 0
            ? 'mix'
            : 'no',
    );
    if (ticket.baggage) {
      baggageFilterList.add(
        ticket.baggage.value > 0
          ? 'C багажом'
          : ticket.baggage.value < 0
            ? 'Смешанный багаж'
            : 'Без багажа',
      );
    }

    //

    hasConnections.push(ticket.hasConnectingFlights === false ? false : true);

    ticket.flights.forEach((flight, key) => {
      const from = flight.segments[0].from;
      const to = flight.segments[flight.segments.length - 1].to;
      if (!timeArr[key]) {
        timeArr[key] = {
          from: {
            city: from.city,
            label: 'Вылет из ' + cityFrom(from.city),
            min: 0,
            max: 0,
            values: [],
            tickets: [],
          },
          to: {
            city: to.city,
            label: 'Прибытие в ' + cityTo(to.city),
            min: 0,
            max: 0,
            values: [],
            tickets: [],
          },
        };
      }

      if (!airports[count]) {
        airports[count] = {
          label: 'Вылет из ' + cityFrom(from.city),
          airports: {},
          tickets: [],
        };
        airports[count + 1] = {
          label: 'Прибытие в ' + cityTo(to.city),
          airports: {},
          tickets: [],
        };
      }

      timeArr[key].from.tickets.push(
        moment(
          flight.segments[0].fromDate + ' ' + flight.segments[0].fromTime,
        ).unix(),
      );

      timeArr[key].to.tickets.push(
        moment(
          flight.segments[flight.segments.length - 1].toDate +
            ' ' +
            flight.segments[flight.segments.length - 1].toTime,
        ).unix(),
      );

      airports[count].airports[from.code] = {
        name: from.airport,
        code: from.code,
        checked: false,
      };

      airports[count].tickets.push(from.code);

      airports[count + 1].airports[to.code] = {
        name: to.airport,
        code: to.code,
        checked: false,
      };

      airports[count + 1].tickets.push(to.code);
      count += 2;
      if (flight.stops.length > stopsCount) {
        stopsCount = flight.stops.length;
      }

      const flightTransferDuration = flight.stops.reduce((a, b) => {
        return a < b.duration ? b.duration : a;
      }, 0);

      if (flightTransferDuration > transferDuration) {
        transferDuration = flightTransferDuration;
      }

      if (!flightsDurations[key]) {
        flightsDurations[key] = {
          from: flight.from,
          to: flight.to,
          max: 0,
          min: 0,
          tickets: [],
        };
      }

      // let duration = 0;
      // let fromDate: null | Moment = null;
      const airlines = new Set<string>();

      flight.segments.forEach((segment) => {
        airlines.add(segment.airlineCode);
        airlines.add(segment.operatingAirlineCode);
        // const toDate = moment(`${segment.toDate} ${segment.toTime}`);

        // if (fromDate) {
        //   duration += toDate.diff(fromDate, 'minutes');
        // }
        // fromDate = moment(`${segment.fromDate} ${segment.fromTime}`);
        // duration += toDate.diff(fromDate, 'minutes');
      });
      flightsDurations[key].tickets[index] = flight.duration;
      ticketAirlines[index].push(Array.from(airlines));
      ticketAirlines[index].push(Array.from(airlines));
    });

    transferDurationList.push(transferDuration); // add logic change to newTransfers
    transfers.list.add(stopsCount);
    transfers.tickets.push(stopsCount);
  });

  airGdsValues = airGds.reduce((obj: Record<string, boolean>, item) => {
    obj[item] = false;
    return obj;
  }, {});

  flightsDurations = flightsDurations.map(({ from, to, tickets }) => {
    return {
      from,
      to,
      min: Math.min(...tickets.filter(Boolean)),
      max: Math.max(...tickets.filter(Boolean)),
      tickets,
    };
  });

  Array.from(new Set(_.flattenDeep(ticketAirlines)))
    .map((x) => {
      return data.references.Airlines.find((y) => y.code === x) as any;
    })
    .forEach((x) => {
      airlinesValues[x.code] = false;
    });

  return {
    isFiltered: false,
    flightsDurationsList: flightsDurations,
    connections: hasConnections,
    transfers: {
      list: Array.from(transfers.list).map((val) => {
        return { val, checked: false };
      }),
      tickets: transfers.tickets,
    },
    flightTypes,
    transferDuration: {
      min: Math.min(...transferDurationList),
      max: Math.max(...transferDurationList),
      tickets: transferDurationList,
    },
    prices: {
      min: Math.min(...prices),
      max: Math.max(...prices),
      tickets: prices,
    },
    airlines: Array.from(new Set(_.flattenDeep(ticketAirlines)))
      .map((x) => {
        return data.references.Airlines.find((y) => y.code === x) as any;
      })
      .sort(
        (
          a: { code: string; name: string; logoUrl: string },
          b: {
            code: string;
            name: string;
            logoUrl: string;
          },
        ) => a.name?.localeCompare(b.name),
      )
      .map((x) => ({ ...x, icon: Helper.getAirlinesLogoUrl(x.code, 48, 48) })),
    airlinesTickets: ticketAirlines,
    airports: airports.map((airport) => {
      return {
        ...airport,
        airports: Object.entries(airport.airports).map(([_, val]) => val),
      };
    }),
    airGds: airGds,
    baggageFilter: baggageList,

    time: timeArr.map((el) => {
      const fromMax = Math.max(...el.from.tickets);
      const fromMin = Math.min(...el.from.tickets);
      const toMax = Math.max(...el.to.tickets);
      const toMin = Math.min(...el.to.tickets);
      return {
        ...el,
        from: {
          ...el.from,
          min: fromMin,
          max: fromMax,
          values: [fromMin, fromMax],
        },
        to: {
          ...el.to,
          min: toMin,
          max: toMax,
          values: [toMin, toMax],
        },
      };
    }),
    baggage: Array.from(baggageFilterList)
      .sort((a, b) => a.length - b.length)
      .map((el) => ({
        checked: false,
        label: el,
      })),
    values: {
      airports: airports.map((airport) => []),
      flightsDuration: flightsDurations.map(({ tickets }) => {
        return [
          Math.min(...tickets.filter(Boolean)),
          Math.max(...tickets.filter(Boolean)),
        ];
      }),
      prices: [Math.min(...prices), Math.max(...prices)],
      transfer: [],
      transferDuration: [
        Math.min(...transferDurationList),
        Math.max(...transferDurationList),
      ],
      airlines: airlinesValues,
      flightTypes: [],
      airGdsValues: airGdsValues,
    },
    isDefault: {
      flightDuration: true,
      airport: true,
      prices: true,
      transferDuration: true,
      transfers: true,
      airlines: true,
      time: true,
      baggage: true,
      flightTypes: true,
      airGds: true,
      connections: true,
    },
  };
}
