import React from 'react';
import moment from 'moment';
import { FlightItemActiveProps } from '../types';
import styled from 'styled-components';
import Text from '@components/ui/Typography';
import AviaMainSegment from '../../../common/FlightSegment/AviaMainSegment';
import SegmentList from '../../../common/FlightSegment/SegmentList';
import { useTicketProps } from '../useTicketProps';
import { getFeatureIcon } from '../utils';
import _ from 'lodash';
import AviaSegmentHeader from '@components/common/FlightSegment/AviaSegmentHeader';
import infoIconBlack from '@assets/media/flight-search/infoIconBlack.svg';
import Chip from '@components/ui/Chip/Chip';
import { NavLink } from 'react-router-dom';
import { setConnectionFilter } from '@app/modules/main';
import { useDispatch } from 'react-redux';
import FlightTypeIconList from '@components/common/FlightTypeIconList';

const HeadTitle = styled(Text)`
  display: block;
  padding-left: 7px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Container = styled.div`
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    :not(:first-child) {
      margin-top: 10px;
    }
  }
`;

const Flex = styled.div`
  display: flex;

  & > div + div {
    margin-left: 6px;
  }
`;

const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 10px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const HeadContainerMobile = styled(HeadContainer)`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
  }
`;

const FeaturesWrapper = styled.div`
  display: flex;

  & > div {
    margin-right: 8px;
  }

  & > :last-child {
    margin-right: 0;
  }
`;

const AdditionalIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const AbsoluteBlockTippy = styled.div`
  position: absolute;
  width: 248px;
  height: 135px;
  background: white;
  box-shadow: 0 15px 20px 0 #10101026;
  font-size: 12px;
  padding: 12px;
  z-index: 1000;
  bottom: 45px;
  @media (max-width: 1200px) {
    top: 80px;
  }
`;

const FlightItem: React.FC<FlightItemActiveProps> = (props) => {
  const { segments, stops } = props;
  const fromDate = moment(`${segments[0].fromDate} ${segments[0].fromTime}`);
  const { features, prices } = useTicketProps();
  const dispatch = useDispatch();

  const [isAbsoluteShown, setIsAbsoluteShown] = React.useState(false);

  const toDate = moment(
    `${segments[segments.length - 1].toDate} ${
      segments[segments.length - 1].toTime
    }`,
  );

  const flightNumbers = segments.map(
    (x) => x?.airlineInfo?.operatingAirlineCode + ' ' + x.flightNo,
  );

  const allFlightTypes = Array.from(
    new Set(segments.map((segment) => segment?.flight_Type)),
  );

  const allAirlines = _(segments)
    .map((x) => x.airlineInfo)
    .uniqBy((x) => x.operatingAirlineCode)
    .value();

  const allAirlinesForHeader = _.uniqBy(
    allAirlines,
    'operatingAirlineCode',
  ).map((x) => ({
    name: x.operatingAirlineName,
    code: x.operatingAirlineCode,
  }));

  return (
    <Container>
      {isAbsoluteShown && (
        <AbsoluteBlockTippy
          onMouseEnter={() => setIsAbsoluteShown(true)}
          onMouseLeave={() => setIsAbsoluteShown(false)}
        >
          Данное бронирование включает в себя несколько разных билетов. Чаще
          всего это дешевле, но могут быть свои нюансы.{' '}
          <span
            style={{
              cursor: 'pointer',
            }}
          >
            <NavLink
              style={{ textDecoration: 'none', color: '#4872F2' }}
              to="/info/repeatRegistration"
            >
              Подробнее
            </NavLink>
          </span>{' '}
          <br /> <br />
          Вы можете исключить такие перелеты и{' '}
          <span
            style={{ color: '#4872F2', cursor: 'pointer' }}
            onClick={() => dispatch(setConnectionFilter(true))}
          >
            {' '}
            включить фильтр “Без повторной регистрации”{' '}
          </span>
          .
        </AbsoluteBlockTippy>
      )}
      {!props.active && (
        <AviaSegmentHeader
          fromCityName={props.from.city}
          toCityName={props.to.city}
          airlinesInfo={allAirlinesForHeader}
          flightIndex={props.flightIndex}
          flight_Type={allFlightTypes}
          price={prices[0]}
          features={features}
        />
      )}

      <HeadContainerMobile>
        <HeadTitle bold color="dark-gray">
          {props.flightIndex ? 'Обратно' : 'Туда'}: {props.from.city} —{' '}
          {props.to.city}
        </HeadTitle>
        <Flex>
          <FlightTypeIconList flightTypes={allFlightTypes} />
        </Flex>
        <FeaturesWrapper>
          {features.map((feature, index) => {
            return <span key={index}>{getFeatureIcon(feature)}</span>;
          })}
        </FeaturesWrapper>
      </HeadContainerMobile>
      {!props.active && (
        <AviaMainSegment
          airlines={allAirlines}
          duration={props.duration}
          flightNumbers={flightNumbers}
          from={segments[0].from}
          fromDate={fromDate}
          stops={stops}
          techLanding={segments[segments.length - 1].techLandings}
          to={segments[segments.length - 1].to}
          toDate={toDate}
          flightIndex={0}
        />
      )}
      {props.hasConnectingFlights && (
        <div
          style={{ marginTop: 10, cursor: 'pointer', maxWidth: 190 }}
          onMouseEnter={() => setIsAbsoluteShown(true)}
          onMouseLeave={() => setIsAbsoluteShown(false)}
        >
          <Chip
            icon={<AdditionalIcon src={infoIconBlack} />}
            bgColor={'blue'}
            label={'Повторная регистрация'}
            adaptiveTextColor
          />{' '}
        </div>
      )}

      {props.active && (
        <SegmentList
          price={prices[0]}
          features={features}
          stops={stops}
          segments={segments}
        />
      )}
    </Container>
  );
};

export default FlightItem;
