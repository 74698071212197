import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Text } from '@components/ui';
import Place from '@components/flightsSearch/TicketList/Place';
import { FlightSegmentPlace } from '@modules/main';

interface Props {
  fromDate: moment.Moment;
  toDate: moment.Moment;
  from: FlightSegmentPlace;
  to: FlightSegmentPlace;
  duration: string;
}

const PlaceDateWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    & > :last-child {
        text-align: right;
    }
`;

const PathTime = styled(Text)`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #737373;
    
  @media (max-width: 767px) {
    display: none;
  }
`;

const PlaceDate: React.FC<Props> = (props) => {
  const { fromDate, toDate, duration } = props;
  return (
    <PlaceDateWrapper>
      <Place {...props.from} date={fromDate} />
      <PathTime>{duration}</PathTime>
      <Place {...props.to} date={toDate} />
    </PlaceDateWrapper>
  );
};

export default PlaceDate;
