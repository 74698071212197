import React from 'react';
import styled from 'styled-components';
import { Text, Button } from '@components/ui';
import { HeadProps } from './types';
import moment from 'moment';
import { Helper } from '@utils';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AgentPayModal } from '@components/common/Modals';
import { issueTicketsRequest } from '@modules/orders';
import { ApplicationState } from '@modules/index';
import { getAgentOrderBillRequest } from '@modules/booking';
import IssueModal from '@components/common/Modals/AgentPayModal/IssueModal';
import AgentPayments from './AgentPayments';
import arrowSrc from '@assets/media/order-detail/arrow.png';
import { getAquiringCommission } from '@modules/agents/agent-finance/duck';
import clock from '@assets/media/order-detail/clock.svg';
import okIcon from '@assets/media/order-detail/okIcon.svg';
import { cancelOrderPopupToggle } from '@modules/trainOrders';

const Wrapper = styled.div<{ yellow: boolean }>`
  margin-top: 15px;
  background: #edf1fe;
  border: 1px solid #dcdcdc;
  border-color: #dcdcdc;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 5%);
  padding: 14px;
`;

const Header = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;

const Description = styled.div`
  margin-top: 12px;

  & > :last-child {
    margin-bottom: 0;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0px;

  & > span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #3c3c3c;
  }

  & > :last-child {
    font-weight: 600;
  }

  @media (max-width: 767px) {
    justify-content: flex-start;
  }
`;

const PaymentButton = styled(Button).attrs({
  type: 'primary',
})`
  font-size: 16px;
  line-height: 24px;
  padding: 8px 25px;
  font-style: normal;
  font-weight: 600;
  display: block;
  min-width: 183px;
  align-self: flex-start;
  margin-top: 10px;
`;

const ExtraPaymentBlock = styled.div`
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #dcdcdc;
`;

const DurationText = styled(Text)`
  display: block;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
    color: #4872f2;
  }
`;

const ShowAgentPaymentButton = styled.button`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #4872f2;
  display: block;

  padding: 0;
  outline: none;
  border: none;
  background: transparent;
  margin-bottom: 15px;
  cursor: pointer;

  position: relative;
`;

const PaymentNewBlock = styled.div`
  border-top: 1px solid #dbe0e5;
  margin-top: 15px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AgentNewButton = styled.div`
  border: 1px solid #4872f2;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #4872f2;
  padding: 5px 12px;
  width: 100%;
  margin-top: 10px;
`;

const IssueButton = styled.div<LinkProps>`
  background: ${({ isActive }) => (isActive ? '#FFE100' : '#dedede')};
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 18px;
  color: #3c3c3c;
  padding: 5px 10px;
  width: 100%;
`;

const Arrow = styled.img.attrs({ src: arrowSrc, width: 10, height: 5 })`
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
`;

const ClockImage = styled.img.attrs({ src: clock })`
  margin-right: 5px;
  width: 20px;
`;

const OkImage = styled.img.attrs({ src: okIcon })`
  margin-right: 5px;
  width: 20px;
`;

const CancelButtonWrapper = styled.div`
  margin: 10px 0;
  & > button {
    width: 100%;
  }
`;

interface LinkProps {
  isActive: boolean;
}

const Head: React.FC<HeadProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [localCancellationTime, setLocalCancellationTime] = React.useState(
    dateToLocal(props.expiredTime),
  );
  const duration = localCancellationTime.diff(moment(), 'minutes');
  const [agentModal, setAgentModal] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [agentPaymentsOpen, setAgentPaymentsOpen] = React.useState(false);

  const totalAmount = useSelector(
    (x: ApplicationState) =>
      x.orders.orderDetail.activeCalculations.totalAmount,
  );
  const extraPayment = (props.notifications || []).filter(
    (item) =>
      item.type === 'ApprovedCalculation' ||
      item.type === 'NotApprovedCalculation',
  );

  const agentDoPaymentProps = useSelector(
    (x: ApplicationState) =>
      x.orders.orderDetail.data.availableCreditPaymentMethods,
  );

  const order = useSelector((x: ApplicationState) => x.orders.orderDetail);

  const user = useSelector((x: ApplicationState) => x.user);

  const issueStatus = useSelector(
    (state: ApplicationState) => state.orders.orderDetail.issueStatus,
  );

  const isTrainOrder = order?.data?.railwayOrderPositions?.length > 0;
  const yellow =
    extraPayment.length > 0 ||
    (props.availableActions || []).includes('EXTRAPAYMENT');

  const [isLoading] = React.useState(false);

  const payOrder = () => {
    if (!user?.data?.agentId) {
      const scrollElement = document.getElementById('payment_block');
      scrollElement?.scrollIntoView({ behavior: 'smooth' });
    }
    if (agentDoPaymentProps) {
      dispatch(getAquiringCommission({ AgentId: user?.data?.agentId }));

      setAgentModal(true);
    } else {
      const scrollElement = document.getElementById('payment_block');
      scrollElement?.scrollIntoView({ behavior: 'smooth' });
      // history.push(`/redirect-payment?orderId=${props.orderNumber}`);
    }
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      setLocalCancellationTime(dateToLocal(props.expiredTime));
    }, 60000);

    return () => clearInterval(interval);
  }, [props.expiredTime]);

  React.useEffect(() => {
    if (issueStatus !== null) {
      setIsModalOpen(true);
    }
  }, [issueStatus]);

  return (
    <Wrapper yellow={yellow}>
      <Header>
        Заказ №<span data-cy={'orderNumber'}>{props.orderNumber}</span> от{' '}
        {moment(props.createdDate).format('DD.MM.YYYY')}
      </Header>
      <Description>
        <Row>
          <span>Статус заказа:&nbsp;</span>
          <span>{props.paymentStatus.description}</span>
        </Row>
        <Row>
          <span>Стоимость заказа:&nbsp;</span>
          <span data-cy={'orderPriceInOrderDetails'}>
            {Helper.formatPrice(props.amount)}
          </span>
        </Row>
        {order?.data?.salesChannelType === 'b2b' && order?.data?.customer && (
          <>
            <Row>
              <span> Заказ создал(а):&nbsp;</span>
              <span>
                {order?.data?.customer.firstName +
                  ' ' +
                  order?.data?.customer.lastName}
              </span>
            </Row>
            <Row>
              <span> Email:&nbsp;</span>
              <span>{order?.data?.customer.email}</span>
            </Row>
          </>
        )}
        {props.availableActions.includes('PAYMENT') && duration > 0 && (
          <Row>
            <DurationText>
              Необходимо оплатить заказ в течение:{' '}
              <strong>
                {duration ? Helper.formatDuration(duration) : '-'}
              </strong>
            </DurationText>
          </Row>
        )}
        {totalAmount > 0 && (
          <Row>
            <span>Итоговая стоимость заказа после доплаты: &nbsp;</span>
            <span>{Helper.formatPrice(props.amount + totalAmount)}</span>
          </Row>
        )}
        {(extraPayment.length ||
          (props.availableActions || []).includes('EXTRAPAYMENT')) && (
          <Row>
            <DurationText>
              Оплатите заказ в течении:{' '}
              <strong>
                {duration ? Helper.formatDuration(duration) : '-'}
              </strong>
            </DurationText>
          </Row>
        )}

        {!!order?.getOrderPayments &&
          order.getOrderPayments.payments !== null &&
          user?.data?.agentGroup !== 'Самотур (GDS тур)' && (
            <>
              <ShowAgentPaymentButton
                onClick={() => setAgentPaymentsOpen((x) => !x)}
              >
                {agentPaymentsOpen ? 'Свернуть' : 'Посмотреть'} список платежей
                <Arrow />
              </ShowAgentPaymentButton>
              <AgentPayments
                open={agentPaymentsOpen}
                items={order.getOrderPayments.payments}
              />
            </>
          )}

        {/* {(props.availableActions.includes('PAYMENT') ||
          props.availableActions.includes('EXTRAPAYMENT')) &&
          duration > 0 && (
            <Row>
              <Text color="#737373">Время для оплаты: &nbsp;</Text>
              <Text color="blue" fontWeight="600">
                {Helper.formatDuration(duration)}
              </Text>
            </Row>
          )} */}
      </Description>

      {(extraPayment.length ||
        (props.availableActions || []).includes('EXTRAPAYMENT')) && (
        <div style={{ marginTop: 10 }}>
          {/* <OnlyRusCardNotyBlue /> */}
          <PaymentButton
            color="yellow"
            isLoading={isLoading}
            onClick={payOrder}
            style={{ width: '100%' }}
          >
            Доплатить{' '}
            {extraPayment.reduce((acc, x) => acc + x.price, 0) > 0
              ? Helper.formatPrice(
                  extraPayment.reduce((acc, x) => acc + x.price, 0),
                )
              : ''}
          </PaymentButton>
        </div>
      )}
      {(props.availableActions || []).includes('PAYMENT') &&
        order?.data?.orderStatus.code !== 'NotActive' &&
        order?.data?.salesChannel !== 'Собственная розница' &&
        !props?.availableShipmentAllowed?.availableIssue && (
          <div style={{ marginTop: 10 }}>
            {/* <OnlyRusCardNotyBlue /> */}
            <PaymentButton
              color="yellow"
              isLoading={isLoading}
              onClick={payOrder}
              style={{ width: '100%' }}
            >
              Оплатить заказ
            </PaymentButton>
          </div>
        )}
      {(props.availableActions || []).includes('PAYMENT') &&
        order?.data?.salesChannel !== 'Собственная розница' &&
        props?.availableShipmentAllowed?.availableIssue &&
        user?.data?.agentId && (
          <PaymentNewBlock>
            <IssueButton
              onClick={() =>
                props?.availableShipmentAllowed?.ticketIssue === true
                  ? dispatch(
                      issueTicketsRequest({
                        orderId: props.orderId,
                        typePaid: 'credit',
                        userId: user?.data?.agentId,
                      }),
                    )
                  : null
              }
              isActive={props?.availableShipmentAllowed?.ticketIssue === true}
            >
              {props?.availableShipmentAllowed?.ticketIssue === false ? (
                <ClockImage />
              ) : (
                <OkImage />
              )}{' '}
              Выписать заказ
            </IssueButton>
            <AgentNewButton onClick={payOrder}>
              Выбрать другой способ оплаты
            </AgentNewButton>
          </PaymentNewBlock>
        )}
      {isTrainOrder &&
        props.availableActions.includes('RAILWAY_ORDER_CANCEL') && (
          <CancelButtonWrapper>
            <Button
              type="outlined"
              onClick={() => dispatch(cancelOrderPopupToggle(true))}
            >
              Отменить заказ
            </Button>
          </CancelButtonWrapper>
        )}
      {isModalOpen && (
        <IssueModal
          open={isModalOpen}
          issueStatus={issueStatus}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      {!!agentDoPaymentProps && (
        <AgentPayModal
          open={agentModal}
          {...agentDoPaymentProps}
          onClose={() => setAgentModal(false)}
          setAgentModal={setAgentModal}
          onPay={() => {
            setAgentModal(false);
            history.push(`/redirect-payment?orderId=${props.orderNumber}`);
          }}
          onExtract={(byCredit) => {
            dispatch(
              issueTicketsRequest({
                orderId: props.orderId,
                typePaid: byCredit ? 'credit' : 'bill',
                userId: user?.data?.agentId,
              }),
            );
            setAgentModal(false);
          }}
          onCreateInvoice={(type) => {
            setAgentModal(false);
            dispatch(
              getAgentOrderBillRequest({ type: type, orderId: props.orderId }),
            );
          }}
        />
      )}
    </Wrapper>
  );
};

function dateToLocal(date: string) {
  const stillUtc = moment.utc(date).toDate();
  return moment(stillUtc).local();
}

export default Head;
