import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { Button } from '@components/ui';
import { ReactComponent as ConnectingImage } from '@assets/media/shared/connectingImage.svg';
import smallNofification from '@assets/media/shared/notification.svg';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { stickyFooterHide } from '@modules/orders';
import { setConnectionFilter } from '@modules/main';

const StyledPopup = styled(Popup)`
  &-overlay {
    @media (max-width: 767px) {
      z-index: 1000 !important;
      overflow: scroll;
    }
  }

  &-content {
    width: 610px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 30px;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;
    @media (max-width: 767px) {
      width: 100%;
      height: 100%;
      padding: 20px 10px 20px 20px;
      box-sizing: border-box;
      border-radius: 0;
    }
  }
`;

const PopupHead = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const TextContainer = styled.p`
  margin-bottom: 25px;
`;

const Cross = styled.a`
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 26px;
  outline: none;

  @media (max-width: 767px) {
    right: 25px;
    top: 25px;
  }

  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
    }
  }

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 17px;
    width: 2px;
    right: 3.5px;
    bottom: 0.5px;
    background-color: ${({ theme: { colors } }) => colors.main};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const SmallNotification = styled.img.attrs({ src: smallNofification })`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
`;

const Text = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #3c3c3c;
`;

const BlockText = styled(Text)`
  display: block;
`;

const TitleText = styled(BlockText)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-left: 8px;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 18px;
  }
`;

const StyledButton = styled(Button)`
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

interface Props {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  isModalOpen: boolean;
}

const HasConnectionsFlightsModal: React.FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const text = [
    'Повторная регистрация на рейс при пересадке',
    'Повторное получение и сдача багажа',
    'Пересадка равноценна въезду в страну, на паспортном контроле потребуют визу, если она нужна',
    'Если рейс задержали или отменили, то авиакомпания не будет нести ответственности и возмещать расходы за последующие билеты',
    'Условия обмена и возврата по каждому билету могу различаться',
  ];
  return (
    <StyledPopup
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
        dispatch(stickyFooterHide(false));
      }}
    >
      <Cross
        onClick={() => {
          setIsModalOpen(false);
          dispatch(stickyFooterHide(false));
        }}
      />
      <PopupHead>
        <Title>
          <ConnectingImage />
          <TitleText>Повторная регистрация</TitleText>
        </Title>
      </PopupHead>
      {/* <MobileHead>
        <MobileTitle>Условия применения тарифа</MobileTitle>{' '}
      </MobileHead> */}
      <TextContainer>
        <Text>
          Данное бронирование включает в себя не один билет, а несколько разных.
          У такого перелета есть свои особенности:
        </Text>
      </TextContainer>
      {text.map((x, index) => (
        <Flex key={index}>
          <SmallNotification /> <Text>{x}</Text>
        </Flex>
      ))}
      {/* <Text>
        Если вы хотите исключить такие рейсы, то включите фильтр{' '}
        <span style={{ fontWeight: 600 }}>“Единое бронирование”</span> и начните
        новый поиск.
      </Text> */}
      {/* <Flex style={{ marginTop: 20 }}>
        <StyledButton
          type="primary"
          style={{ height: 40 }}
          onClick={() => {
            setIsModalOpen(false);
            dispatch(stickyFooterHide(false));
          }}
        >
          Меня устраивают условия
        </StyledButton>
        <StyledButton
          type="outlined"
          style={{ marginLeft: 20, height: 41 }}
          onClick={() => {
            history.goBack();
            dispatch(setConnectionFilter(true));
            dispatch(stickyFooterHide(false));
          }}
        >
          Включить фильтр
        </StyledButton>
      </Flex> */}
    </StyledPopup>
  );
};

export default HasConnectionsFlightsModal;
