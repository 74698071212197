//@ts-nocheck

import { ERRORS_DESCRIPTIONS, STATUS } from './constants';
import * as Sentry from '@sentry/react';

/** Check for error statuses */
export function statuses(response: Response) {
  if (response.status === STATUS.CONNECTION) {
    Sentry.captureException(
      new HttpError(response.status, ERRORS_DESCRIPTIONS.CONNECTION),
    );

    throw new HttpError(response.status, ERRORS_DESCRIPTIONS.CONNECTION);
  }
  if (response.status === STATUS.NO_CONTENT) {
    Sentry.captureException(
      new HttpError(response.status, ERRORS_DESCRIPTIONS.NO_CONTENT),
    );

    throw new HttpError(response.status, ERRORS_DESCRIPTIONS.NO_CONTENT);
  }
  if (!response.ok && (response.status < 400 || response.status >= 500)) {
    Sentry.captureException(
      new HttpError(response.status, ERRORS_DESCRIPTIONS.BAD_REQUEST),
    );

    throw new HttpError(response.status, ERRORS_DESCRIPTIONS.BAD_REQUEST);
  }
  return response;
}

export class ServerError extends Error {
  constructor({ message, status, localeMessage, code }) {
    super(message);

    this.localeMessage = localeMessage;
    this.message = message;
    this.code = code;
    this.status = status;
    this.text = message;
  }
}

export const handleStatuses = () => (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  return response.json().then((data) => {
    const Error = new ServerError({
      message: data,
      ...data,
      status: response.status,
    });

    Sentry.captureException(Error);
    throw Error;
  });
};

/** Parse response body to object and check for errors */
export async function json<T = any>(response: Response) {
  const data = await response.json();

  if (response.status >= 400 && response.status < 499) {
    if (typeof data === 'string') {
      Sentry.captureException(new HttpError(response.status, data));

      throw new HttpError(response.status, data);
    } else if (typeof data === 'object' && !Array.isArray(data)) {
      Sentry.captureException(
        new HttpError(response.status, data.message || data.descrition),
      );

      throw new HttpError(response.status, data.message || data.descrition);
    } else if (Array.isArray(data)) {
      const errorMessage =
        data[0].Message ||
        data[0].descrition?.message ||
        data[0].descrition?.descrition;
      Sentry.captureException(new HttpError(response.status, errorMessage));

      throw new HttpError(response.status, errorMessage);
    }
  }
  return data as T;
}

/** Parse response body to object and check for booking errors */
export async function jsonBooking<T = any>(response: Response) {
  const data = await response.json();

  if (response.status >= 400 && response.status < 499) {
    if (typeof data === 'string') {
      Sentry.captureException(new HttpError(response.status, data));

      throw new HttpError(response.status, data, data);
    } else if (typeof data === 'object' && !Array.isArray(data)) {
      Sentry.captureException(
        new HttpError(response.status, data.message || data.descrition),
      );

      throw new HttpError(
        response.status,
        data.message || data.descrition,
        data,
      );
    } else if (Array.isArray(data)) {
      Sentry.captureException(
        new HttpError(
          response.status,
          data[0]?.descrition?.message ||
            data[0]?.descrition?.descrition ||
            data[0]?.Message,
        ),
      );

      throw new HttpError(
        response.status,
        data[0]?.descrition?.message ||
          data[0]?.descrition?.descrition ||
          data[0]?.Message,
        data,
      );
    }
  }
  return data as T;
}

/** Transform response body to text and check for errors */
export async function text(response: Response) {
  const data = await response.text();
  if (response.status >= 400 && response.status < 499) {
    Sentry.captureException(new HttpError(response.status, data));

    throw new HttpError(response.status, data);
  }
  return data;
}

export class HttpError {
  private _message: string;
  private _name: number;
  private _data: any;

  get message() {
    return this._message;
  }

  get name() {
    return this._name;
  }

  get data() {
    return this._data;
  }

  constructor(name: number, message: string, data?: any) {
    const error = Error(message);
    this._message = message;
    this._name = name;
    this._data = data;
    Object.defineProperty(error, 'name', {
      get() {
        return name;
      },
    });
    Object.defineProperty(error, 'data', {
      get() {
        return data;
      },
    });

    // capture where error occured
    // Error.captureStackTrace(error, HttpError);
    return error as unknown as HttpError;
  }
}
