import React, { useMemo } from 'react';
import { InfoBlock, RadioButtonCategoryGroup } from '@components/common';
import { Header, Text } from '@components/ui/Typography';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  getBookingFaresState,
  getBookingTicketState,
  SegmentPlaceEntity,
  selectFare,
} from '@modules/booking';
import { Helper } from '@utils';
import voucherAlert from '@assets/media/tariff-features/voucherAlert.svg';

import baggageIncluded from '@assets/media/tariff-features/baggageIncluded.svg';
import baggageAtCharge from '@assets/media/tariff-features/baggageAtCharge.svg';
import baggageNotOffered from '@assets/media/tariff-features/baggageNotOffered.svg';

import carryOnIncluded from '@assets/media/tariff-features/carryOnIncluded.svg';
import carryOnAtCharge from '@assets/media/tariff-features/carryOnAtCharge.svg';
import carryOnNotOffered from '@assets/media/tariff-features/carryOnNotOffered.svg';

import exchangeIncluded from '@assets/media/tariff-features/exchangeIncluded.svg';
import exchangeAtCharge from '@assets/media/tariff-features/exchangeAtCharge.svg';
import exchangeNotOffered from '@assets/media/tariff-features/exchangeNotOffered.svg';

import refundIncluded from '@assets/media/tariff-features/refundIncluded.svg';
import refundAtCharge from '@assets/media/tariff-features/refundAtCharge.svg';
import refundNotOffered from '@assets/media/tariff-features/refundNotOffered.svg';

import airplaneLogoSrc from '@assets/media/shared/plane.svg';

import { ReactComponent as InfoIcon } from '@assets/media/info-icon.svg';
import { TariffsInfoModal } from '@components/common/Modals';
import CharterTariffsModal from '@components/common/Modals/CharterTariffsModal';
import { ApplicationState } from '@modules/index';
import { useMediaQuery } from 'react-responsive';
import VoucherModal from '@components/common/Modals/VoucherModal';
import { changeCodeToCityInRoutes } from '@modules/booking/utils';
import { FLIGHT_TYPES } from '@components/booking/FlightDetail/types';
import { postSessionLogHandler } from '@modules/logs/duck';
import { UserActionTypes } from '@modules/logs/types';
import { LOGGING_ACTION_IDS } from '@modules/logs/constants';

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  @media (max-width: 767px) {
    padding: 0 14px;
  }
`;

const Body = styled.div`
  margin-top: 15px;
  @media (max-width: 1023px) {
    margin-top: 10px;
  }
`;

const FeatureWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 5px;
`;

const Wrapper = styled(InfoBlock)`
  padding: 24px 0;

  @media (max-width: 767px) {
    padding: 20px 0;
    width: 100%;
    margin-top: 30px;
  }
  @media (min-width: 767px) {
    margin-top: 35px;
  }
`;

const Title = styled(Header)``;

const Description = styled.div`
  padding: 0 24px;

  ${Text} {
    color: #3c3c3c;
  }

  @media (max-width: 767px) {
    padding: 0 14px;
  }
`;

const NotificationWrapper = styled.div`
  background: #edf1fe;
  border: 1px solid #4872f2;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 14px 19px;
  margin: 0 24px;
  margin-top: 20px;
  @media (max-width: 767px) {
    margin: 14px 14px 0 14px;
  }
`;

function getIcon(x: 'Included' | 'NotOffered' | 'AtCharge', type: string) {
  switch (x) {
    case 'Included':
      if (type === 'Baggage') {
        return <img src={baggageIncluded} alt="" height={18} width={18} />;
      }
      if (type === 'Refund') {
        return <img src={refundIncluded} alt="" height={18} width={18} />;
      }
      if (type === 'CarryOn') {
        return <img src={carryOnIncluded} alt="" height={18} width={18} />;
      }
      if (type === 'Exchange') {
        return <img src={exchangeIncluded} alt="" height={18} width={18} />;
      }
      break;
    case 'NotOffered':
      if (type === 'Baggage') {
        return <img src={baggageNotOffered} alt="" height={18} width={18} />;
      }
      if (type === 'Refund') {
        return <img src={refundNotOffered} alt="" height={18} width={18} />;
      }
      if (type === 'CarryOn') {
        return <img src={carryOnNotOffered} alt="" height={18} width={18} />;
      }
      if (type === 'Exchange') {
        return <img src={exchangeNotOffered} alt="" height={18} width={18} />;
      }
      break;
    case 'AtCharge':
      if (type === 'Baggage') {
        return <img src={baggageAtCharge} alt="" height={18} width={18} />;
      }
      if (type === 'Refund') {
        return <img src={refundAtCharge} alt="" height={18} width={18} />;
      }
      if (type === 'CarryOn') {
        return <img src={carryOnAtCharge} alt="" height={18} width={18} />;
      }
      if (type === 'Exchange') {
        return <img src={exchangeAtCharge} alt="" height={18} width={18} />;
      }
      break;
  }
}

const InfoButton = styled.button.attrs({ type: 'button' })`
  appearance: none;
  outline: none;
  border: none;
  background: transparent;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4872f2;
  display: flex;
  align-items: center;
  cursor: pointer;

  & > svg {
    margin-right: 6.5px;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const TariffName = styled(Text)<{ fixHeight?: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: block;
  margin-left: 1px;
  height: ${({ fixHeight }) => (fixHeight ? '54px' : 'auto')};
`;
const TariffPrice = styled(TariffName)`
  color: #4872f2;
  margin-top: 5px;
`;

const InfoButtonMobile = styled(InfoButton)`
  padding: 0 14px;
  margin-top: 15px;
  @media (max-width: 767px) {
    display: flex;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

const VoucherContainer = styled.div`
  background: #ffe2e3;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 20px 0 20px;
  @media (max-width: 768px) {
    padding: 14px;
  }
`;

const VoucherAlert = styled.img.attrs({ src: voucherAlert })`
  margin-right: 7px;
  width: 25px;
  height: 25px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const VoucherHeading = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #f25f61;
`;

const VoucherText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 5px;
  padding-left: 32px;

  @media (max-width: 768px) {
    padding-left: 0;
    padding-top: 10px;
  }
`;

const Separator = styled.hr`
  height: 1px;
  color: #dcdcdc;
  width: 100%;
  margin: 12px 0;
  border: 0;
  background-color: #dcdcdc;
`;

const RouteTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  gap: 6px;
`;

const RouteTitleIcon = styled.div``;

const RouteTitleContent = styled.div``;

const Tariffs: React.FC = () => {
  const dispatch = useDispatch();
  const fares = useSelector(getBookingFaresState);
  const ticket = useSelector(getBookingTicketState);
  const [open, setOpen] = React.useState(false);
  const [isVoucherOpen, setIsVoucherOpen] = React.useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const upsellIndex = urlParams?.get('upsellIndex');

  const selectedFare = useSelector(
    (state: ApplicationState) => state.booking.fares?.selected,
  );
  const isMobile = useMediaQuery({ maxWidth: 767 });

  React.useLayoutEffect(() => {
    if (upsellIndex !== null) {
      dispatch(selectFare(Number(upsellIndex)));
    }
  }, [upsellIndex]);

  // если первый рейс чартерный, то считаем, что всё чартерное
  const isCharter = ticket?.flights[0]?.flight_Type === FLIGHT_TYPES.CHARTER;

  React.useLayoutEffect(() => {
    if (upsellIndex !== null) {
      dispatch(selectFare(Number(upsellIndex)));
    }
  }, [upsellIndex]);

  const formattedItems = fares.items.map((item, index) => {
    const isMultipleFareFamilies = item.isMultipleFareFamilies;
    let name = item.fareFamilies[0].name ? item.fareFamilies[0].name : '';
    let allFeatures = [item.fareFamilies[0].features] || [[]];
    let routes = [item.fareFamilies[0].routes] || [[]];
    const groups = item.groups;
    const allCities: SegmentPlaceEntity[] = [];
    if (isMultipleFareFamilies) {
      // name equal is string 'item.fareFamilies[0].name + item.fareFamilies[1].name' and etc
      name = item.fareFamilies.reduce(
        (acc, x, index) =>
          acc + x.name + `${index < item.fareFamilies.length - 1 ? ' + ' : ''}`,
        '',
      );
      allFeatures = item.fareFamilies.map((x) => x.features);
    }
    routes = item.fareFamilies.map((x) => x.routes);
    if (groups) {
      groups.forEach((group) => {
        group.flights.forEach((flight) => {
          flight.segments.forEach((segment) => {
            allCities.push(segment.from);
            allCities.push(segment.to);
          });
        });
      });
    }
    routes = routes?.map((route) => changeCodeToCityInRoutes(route, allCities));
    return {
      data: {
        price: item.extraPrice,
        name: name,
        upsaleIndex: item.upsellIndex,
        prices: item.prices,
        extraCharge: item.extraCharge,
        key: index,
        total: item.prices.reduce((acc, x) => acc + x.total * x.count, 0),
      },
      isMultipleFareFamilies: isMultipleFareFamilies,
      label: createLabel(item.fareFamilies[0].name, item.extraPrice),
      features: allFeatures,
      routes: routes,
      total: item.prices.reduce((acc, x) => acc + x.total * x.count, 0),
    };
  });

  const atLeastOneFareFamilyHasMultipleFareFamilies = useMemo(() => {
    return formattedItems.some((x) => x.isMultipleFareFamilies);
  }, [formattedItems]);

  const selectedFareTotal = selectedFare?.prices?.reduce(
    (acc, x) => acc + x.total * x.count,
    0,
  );

  const isShowVoucher = ticket.frequentFlyerAirlines.some(
    (x) => x.nameEng === 'Air Arabia',
  );

  return (
    <Wrapper>
      <Head>
        <Title size="h4">Тариф перелета</Title>
        <InfoButton onClick={() => setOpen(true)}>
          {' '}
          <InfoIcon /> <span>Подробнее о тарифах</span>
        </InfoButton>
      </Head>
      <Body>
        <Description>
          <Text>Выберите тариф авиаперелета, единый для всех пассажиров.</Text>
        </Description>
        <RadioButtonCategoryGroup
          type="button"
          items={formattedItems}
          onChange={(data) => {
            dispatch(selectFare(data.upsaleIndex));
            dispatch(
              postSessionLogHandler({
                eventType: UserActionTypes['Click'],
                actionId:
                  LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.SELECT_TARIFF,
                screen: LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.NAME,
                value: {
                  upsaleIndex: data.upsaleIndex,
                },
              }),
            );
          }}
          upsellIndex={upsellIndex !== null ? Number(upsellIndex) : null}
          renderRouteTitle={(routes, key) => {
            const isMultipleFare = fares.items[key].isMultipleFareFamilies;
            if (!isMultipleFare && !atLeastOneFareFamilyHasMultipleFareFamilies)
              return '';
            return (
              <RouteTitle>
                <RouteTitleIcon>
                  <img
                    width={18}
                    height={18}
                    src={airplaneLogoSrc}
                    alt="иконка самолёта"
                  />
                </RouteTitleIcon>
                <RouteTitleContent>
                  {routes?.map((route, index) => {
                    const isLast = index === routes.length - 1;
                    return (
                      <span key={index}>
                        {route.from} — {route.to}
                        {!isLast ? ', ' : ''}
                      </span>
                    );
                  })}
                </RouteTitleContent>
              </RouteTitle>
            );
          }}
          renderLabel={(x) => {
            return (
              <>
                <TariffName
                  fixHeight={atLeastOneFareFamilyHasMultipleFareFamilies}
                >
                  {x.name} {!isMobile && <br />}
                  <span style={{ color: '#4872F2' }}>
                    {(x.upsaleIndex &&
                      selectedFare &&
                      selectedFare.upsellIndex !== null &&
                      selectedFare?.upsellIndex < x?.upsaleIndex) ||
                    (selectedFare?.upsellIndex === null &&
                      x?.upsaleIndex !== null)
                      ? `  + ${Helper.formatPrice(
                          //@ts-ignore
                          formattedItems[x.key]?.total - selectedFareTotal,
                        )}`
                      : Helper.formatPrice(x.total)}
                  </span>
                </TariffName>
              </>
            );
          }}
          renderFeature={(features, key, index) => {
            const isLast = key === formattedItems[index].features.length - 1;

            return (
              <>
                {features.map((feature, index) => {
                  return (
                    <FeatureWrapper key={`${index}-${feature.code}`}>
                      {getIcon(feature.applicability, feature.type)}
                      {getDescription(
                        feature.type,
                        feature.applicability,
                        feature.shortDescriptionRus || feature.descriptionRus,
                      )}
                    </FeatureWrapper>
                  );
                })}
                {!isLast && <Separator />}
              </>
            );
          }}
        />
        <InfoButtonMobile onClick={() => setOpen(true)}>
          {' '}
          <InfoIcon /> <span>Подробнее о тарифах</span>
        </InfoButtonMobile>
        {fares.notificationVisibility && (
          <NotificationWrapper>
            <Text size="small">
              Обращаем внимание, в данном тарифе выбранные страховка и
              дополнительные услуги могут быть недоступны. Пожалуйста, добавьте
              заново необходимые Вам услуги.
            </Text>
          </NotificationWrapper>
        )}
        {isShowVoucher && (
          <VoucherContainer>
            <Row>
              <VoucherAlert />{' '}
              <VoucherHeading>Возврат только в виде ваучера</VoucherHeading>
            </Row>
            <VoucherText>
              Обращаем Ваше внимание, что по правилам авиакомпании возврат
              билетов осуществляется только в виде ваучера.
            </VoucherText>
            <InfoButton
              style={{ paddingTop: 15, paddingLeft: 29 }}
              onClick={() => setIsVoucherOpen(true)}
            >
              <InfoIcon /> <span>Что такое ваучер?</span>
            </InfoButton>

            <InfoButtonMobile
              onClick={() => setIsVoucherOpen(true)}
              style={{ paddingLeft: 0 }}
            >
              {' '}
              <InfoIcon /> <span>Что такое ваучер?</span>
            </InfoButtonMobile>
          </VoucherContainer>
        )}

        {/*для чартерных рейсов показываем статичный текст для тарифов*/}
        {isCharter ? (
          <CharterTariffsModal open={open} onClose={() => setOpen(false)} />
        ) : (
          <TariffsInfoModal
            fares={fares.items}
            open={open}
            onClose={() => setOpen(false)}
          />
        )}
        {isVoucherOpen && (
          <VoucherModal
            isVoucherOpen={isVoucherOpen}
            setIsVoucherOpen={setIsVoucherOpen}
          />
        )}
      </Body>
    </Wrapper>
  );
};

function createLabel(name: string, extraPrice: number) {
  const formattedPrice =
    extraPrice > 0 ? `+${Helper.formatPrice(extraPrice)}` : '';
  return name ? `${name} ${formattedPrice}` : formattedPrice;
}

function getDescription(
  type: string,
  applicability: 'Included' | 'AtCharge' | 'NotOffered',
  shortDescription: string,
) {
  let descArr: [string, string, string] = ['', '', ''];
  switch (type) {
    case 'Baggage':
      descArr = [`Багаж: ${shortDescription}`, 'Багаж платный', 'Без багажа'];
      break;
    case 'CarryOn':
      descArr = [
        `Ручная кладь: ${shortDescription}`,
        'Ручная кладь платно',
        'Без ручной клади',
      ];
      break;
    case 'Exchange':
      descArr = ['Обмен билета', 'Обмен билета платный', 'Без обмена'];
      break;
    case 'Refund':
      descArr = ['Возврат билета', 'Возврат билета платный', 'Без возврата'];
      break;
  }
  switch (applicability) {
    case 'Included':
      return descArr[0];
    case 'AtCharge':
      return descArr[1];
    case 'NotOffered':
      return descArr[2];
  }
}

export default Tariffs;
