import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '@modules/index';
import PassengerPrice from '@components/booking/PassengerPrice/PassengerPrice';
import { PAYMENT_METHODS } from '@components/common/PaymentContainer/constants';

const TrainPassengerPriceContainer: React.FC = () => {
  const pricing = useSelector(
    (state: ApplicationState) => state.trainBooking.pricing
  );
  const trainForward = useSelector(
    (state: ApplicationState) => state.trainTickets.trainForward.train
  );
  const trainBackward = useSelector(
    (state: ApplicationState) => state.trainTickets.trainBack.train
  );
  let title = `${trainForward.fromStation.city.name_ru} — ${trainForward.toStation.city.name_ru}`;
  if (trainBackward) {
    title = `${title} — ${trainBackward.toStation.city.name_ru}`;
  }
  const subagentTotal = 0;

  return (
    <PassengerPrice
      priceType={'train'}
      noTimes
      title={title}
      total={pricing.total}
      subagentTotal={subagentTotal}
      detailList={pricing.priceItems}
      isShowCommission={false}
      chosenPaymentMethod={PAYMENT_METHODS.RF_CARD}
    />
  );
};

export default TrainPassengerPriceContainer;
