import { FlightType } from '@components/booking/FlightDetail/types';
import { TechLanding } from '@components/common';

export interface IFlightSearchIntervals {
  list: {
    items: {
      [key: string]: {
        from: string;
        to: string;
        price: number;
      };
    };
    length: number;
  };
  selected: string | null;
}

export enum FlightClassTypes {
  Econom = 'economy',
  Bussines = 'business',
  Comfort = 'comfort',
  FirstClass = 'first',
}

export interface SearchWithReturnParams {
  origin: string;
  destination: string;
  forwardDate: string;
  backwardDate: string;
  passengers: {
    adults: string;
    children: string;
    infants: string;
  };
  category: string;
  agentSamoCode?: number;
  saleChannel?: string;
  pluginToken: string;
  cache?: boolean;
}

/** Actions */

/** flight search State */
export enum FlightSearchRequestStatus {
  loading = 'loading',
  success = 'success',
  failure = 'failure',
}
export interface EnhancedFlightSegment extends FlightSegment {
  airlineInfo: Airline;
}

export type FlightListItemFlights = {
  index: number;
  from: FlightSegmentPlace;
  to: FlightSegmentPlace;
  segments: EnhancedFlightSegment[];
  duration: number;
  stops: Stop[];
}[];
export type FlightListItem = {
  groupIndex: number;
  flights: FlightListItemFlights;
  baggage: NormalizeItemBaggage | null;
  id: string;
  airlinesInfo: Airline[];
  prices: number[];
  complexPrices: ComplexPrice[];
  flight_Type: FlightType;
  airGds: string;
  isActive?: boolean;
  searchId: string;
  isExchangeable: boolean | null;
  isRefundable: boolean | null;
  _isLowcoster?: boolean | null;
  hasConnectingFlights: boolean | null;
  features: {
    content: string;
    style: string;
    type: string;
    order: number;
    value: number | null;
    applicability: string;
  }[];
};

export type FlightListItems = FlightListItem[];

export interface FilterFlightDurationItem {
  from: FlightSegmentPlace;
  to: FlightSegmentPlace;
  max: number;
  min: number;
  tickets: number[];
}

export interface Transfers {
  list: { val: number; checked: boolean }[];
  tickets: number[];
}

export interface TransferDuration {
  min: number;
  max: number;
  tickets: number[];
}

export type Airport = {
  name: string;
  code: string;
  checked: boolean;
};

export type CityAirports = {
  label: string;
  airports: Airport[];
  tickets: string[];
};

export interface AviaDefaultFilter {
  airport: boolean;
  prices: boolean;
  transferDuration: boolean;
  transfers: boolean;
  flightDuration: boolean;
  airlines: boolean;
  time: boolean;
  baggage: boolean;
  flightTypes: boolean;
  airGds: boolean;
  connections: boolean;
}

// type all keys from AviaDefaultFilter
export type AviaDefaultFilterKeys = keyof AviaDefaultFilter;

export interface Filter {
  isFiltered: boolean;
  flightsDurationsList: FilterFlightDurationItem[];
  transfers: Transfers;
  transferDuration: TransferDuration;
  prices: { min: number; max: number; tickets: number[] };
  airlines: Airline[];
  airGds: string[];
  airlinesTickets: string[][][];
  airports: CityAirports[];
  baggage: { checked: boolean; label: string }[];
  baggageFilter: (string | null)[];
  flightTypes: string[];
  connections: boolean[];
  time: {
    from: {
      city: string;
      label: string;
      min: number;
      max: number;
      values: number[];
      tickets: number[];
    };
    to: {
      city: string;
      label: string;
      min: number;
      max: number;
      values: number[];
      tickets: number[];
    };
  }[];
  values: {
    flightsDuration: number[][];
    prices: number[];
    transferDuration: number[];
    transfer: number[];
    airlines: { [key: string]: boolean };
    airports: string[][];
    flightTypes: string[];
    airGdsValues: { [key: string]: boolean };
    // airGdsTemp: string[];
  };
  isDefault: AviaDefaultFilter;
}

export interface IFlightSearchStateBase {
  flightsList: {
    items: FlightListItems;
    notFilteredItems: FlightListItems;
  };
  references: FlightSearchReference;
  responseId: string | null;
  errors: ResponseError[] | null;
  data: Item[];
}

export interface IFlightSearchState extends IFlightSearchStateBase {
  status: FlightSearchRequestStatus | null;
  filter: Filter;
}

/** Search DTO */

export enum FlightsSearchEndpoint {
  method = 'GET',
  url = 'http://avia.tvm-tte-k8s.fstravel.com/api/FlightSearch',
}
export interface FlightSegmentPlace {
  airport: string;
  code: string;
  city: string;
  country: string;
  terminal: string;
  date?: string;
  techLandings?: TechLanding[];
}

export interface FlightSegment {
  index: number;
  fareFamilyFeature: null;
  techLandings: TechLanding[];
  duration: number;
  isLowcoster: boolean;
  flightNo: string;
  airplane: string;
  airline: string;
  operatingAirlineCode: string;
  airlineCode: string;
  from: FlightSegmentPlace;
  to: FlightSegmentPlace;
  fromDate: string;
  fromTime: string;
  toDate: string;
  toTime: string;
  time: string;
  flightClass: string;
  fareBasis: string;
  availSeats: number;
  classCode: string;
  fareFamily: null;
  flight_Type: FlightType;
  luggage: {
    unit: string;
    value: number;
  };
}

export interface GroupFlights {
  index: number;
  time: string;
  minAvailSeats: number;
  segments: FlightSegment[];
  stops: Stop[];
  duration: number;
  transitVisaInfo: null;
}

export interface Stop {
  city: FlightSegmentPlace;
  duration: number;
  time: string;
}

export interface ItemFeature {
  content: string;
  order: number;
  style: string;
  type: string;
  value: number;
  applicability: string;
}

export interface ItemGroup {
  index: number;
  stopsCount: number;
  flights: GroupFlights[];
}

export type ComplexPrice = {
  currency: number;
  priceSteps: {
    code: string;
    currency: number;
    description: string;
    priceParts: {
      description: string;
      type: string;
      value: number;
    }[];
    totalPrice: number;
  }[];
  totalPrice: number;
} | null;

export interface Item {
  index: number;
  airGds: string;
  complexPrices: ComplexPrice[];
  airlines: string;
  airlinesCode: string;
  serviceClass: string;
  prices: number[];
  flight_Type: FlightType;
  groups: ItemGroup[];
  features: ItemFeature[];
  isExchangeable: boolean | null;
  isRefundable: boolean | null;
  hasConnectingFlights: boolean | null;
}

export interface NormalizeItemBaggage {
  value: number;
  content: string;
}

export interface NormalizeItem {
  airGds: string;
  baggage: NormalizeItemBaggage | null;
  complexPrices: ComplexPrice[];
  features: ItemFeature[];
  flight_Type: FlightType;
  flights: GroupFlights[];
  groupIndex: number;
  hasConnectingFlights: boolean | null;
  isExchangeable: boolean | null;
  isRefundable: boolean | null;
  prices: number[];
}

export interface Airline {
  operatingAirlineCode: string;
  operatingAirlineName: string;
  code: string;
  name: string;
  logoUrl: string | null;
}

export type TimeTableDayValues = Array<{
  price: number;
  operationalDay: string;
}>;
export type TimeTableItem = {
  operationalDay: string;
  values: TimeTableDayValues;
};
export type TimeTable = Array<TimeTableItem> | null;

export interface FlightSearchReference {
  Airlines: Airline[];
  Timetable: TimeTable;
  TopAirlines: null;
}

export interface FlightSearchDTO {
  request: any;
  data: Item[];
  references: FlightSearchReference;
  responseId: string | null;
  errors: ResponseError[] | null;
}

export interface ResponseError {
  code: string | null;
  description: string | null;
}

export interface RailwayResponseError {
  code: number | null;
  message: string;
}

export interface CustomRailwayError extends Error {
  response?: {
    data?: {
      message?: string;
    };
  };
}

export type CheckAvailabilityPayload = {
  group: number;
  flights: number[];
  id: string;
};

export interface ListState {
  items: FlightListItems;
  visibleItems: FlightListItems;
  page: number;
  pageCount: number;
}

export interface PromotionsDto {
  data: {
    date: string;
    price: number;
    url: string;
    fromTo: string;
    code: string;
  }[];
}

export interface EntityTypes {
  RAILWAY: 'RailwayOrder';
  AVIA: 'AviationOrder';
  BUS: 'BusOrder';
}

export type TicketType = EntityTypes[keyof EntityTypes];
