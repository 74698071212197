export const LOGGING_ACTION_IDS = {
  SEARCH_PANEL: {
    NAME: 'SEARCH_PANEL',

    SEARCH_BTN: 'SEARCH_BTN',
  },
  AVIA_FLIGHTS_LIST: {
    NAME: 'AVIA_FLIGHTS_LIST',
    TICKET_BUY_BTN: 'TICKET_BUY_BTN',
  },
  AVIA_BOOKING: {
    NAME: 'AVIA_BOOKING',
  },
  AVIA_BOOKING_FIRST_STEP: {
    NAME: 'AVIA_BOOKING_FIRST_STEP',

    ROUTE_DETAILS_EXPAND_TOP_BTN: 'ROUTE_DETAILS_EXPAND_TOP_BTN',
    ROUTE_DETAILS_EXPAND_BOTTOM_BTN: 'ROUTE_DETAILS_EXPAND_BOTTOM_BTN',

    ADD_FROM_PASSENGERS_BTN: 'ADD_FROM_PASSENGERS_BTN',
    SELECT_TARIFF: 'SELECT_TARIFF',

    AVIA_SERVICE_FROM_FORM_BTN: 'AVIA_SERVICE_FROM_FORM_BTN',
    AVIA_SERVICE_FROM_FORM_BTN_CLOSE: 'AVIA_SERVICE_FROM_FORM_BTN_CLOSE',
    AVIA_SERVICE_FROM_OFFER_BTN: 'AVIA_SERVICE_FROM_OFFER_BTN',
    AVIA_SERVICE_FROM_OFFER_BTN_CLOSE: 'AVIA_SERVICE_FROM_OFFER_BTN_CLOSE',
    AVIA_SERVICE_CHANGE_PASSENGER_IN_MODAL:
      'AVIA_SERVICE_CHANGE_PASSENGER_IN_MODAL', // finish
    AVIA_SERVICE_CHECK_ITEM: 'AVIA_SERVICE_CHECK_ITEM',
    AVIA_SERVICE_NEXT_STEP_BTN: 'AVIA_SERVICE_NEXT_STEP_BTN',

    PASSENGER_SURNAME_INPUT: 'PASSENGER_SURNAME_INPUT',
    PASSENGER_NAME_INPUT: 'PASSENGER_NAME_INPUT',
    PASSENGER_SECOND_NAME_INPUT: 'PASSENGER_SECOND_NAME_INPUT',
    PASSENGER_BIRTHDAY_INPUT: 'PASSENGER_BIRTHDAY_INPUT',
    PASSENGER_NO_SECOND_NAME_CHECKBOX: 'PASSENGER_NO_SECOND_NAME_CHECKBOX',
    PASSENGER_GENDER_CHECKBOX: 'PASSENGER_GENDER_CHECKBOX',
    PASSENGER_CITIZENSHIP_SELECT: 'PASSENGER_CITIZENSHIP_SELECT',
    PASSENGER_DOCUMENT_SELECT: 'PASSENGER_DOCUMENT_SELECT',
    PASSENGER_DOCUMENT_NUMBER_INPUT: 'PASSENGER_DOCUMENT_NUMBER_INPUT',
    PASSENGER_BONUS_CARD_ADD_BTN: 'PASSENGER_BONUS_CARD_ADD_BTN',
    PASSENGER_LOYALTY_CARD_COMPANY_SELECT:
      'PASSENGER_LOYALTY_CARD_COMPANY_SELECT',
    PASSENGER_LOYALTY_CARD_NUMBER_INPUT: 'PASSENGER_LOYALTY_CARD_NUMBER_INPUT',

    APPROVE_TERMS_CHECKBOX: 'APPROVE_TERMS_CHECKBOX',
    APPROVE_EMAIL_NOTIFICATIONS_CHECKBOX:
      'APPROVE_EMAIL_NOTIFICATIONS_CHECKBOX',

    SUBMIT_FORM_BUTTON: 'SUBMIT_FORM_BUTTON',
    SUBMIT_SAGA_FORM: 'SUBMIT_SAGA_FORM',

    RECEIVE_ORDER_DATA: 'RECEIVE_ORDER_DATA',
  },
  AVIA_BOOKING_SECOND_STEP: {
    NAME: 'AVIA_BOOKING_SECOND_STEP',

    ADD_ADDITIONAL_SERVICE_FROM_FORM_BTN:
      'ADD_ADDITIONAL_SERVICE_FROM_FORM_BTN',
    ADD_ADDITIONAL_SERVICE_FROM_OFFER: 'ADD_ADDITIONAL_SERVICE_FROM_OFFER',
    REMOVE_ADDITIONAL_SERVICE_FROM_FORM_BTN:
      'REMOVE_ADDITIONAL_SERVICE_FROM_FORM_BTN',

    ADD_INSURANCE_BTN: 'ADD_INSURANCE_BTN',
    REMOVE_INSURANCE_BTN: 'REMOVE_INSURANCE_BTN',
    CHOOSE_PAYMENT_OPTION: 'CHOOSE_PAYMENT_OPTION',
    PROMOCODE_INPUT: 'PROMOCODE_INPUT',
    PROMOCODE_APPLY_BTN: 'PROMOCODE_APPLY_BTN',
  },
  TRAIN_FLOW: {
    NAME: 'TRAIN_FLOW',
  },
  TRAIN_SEARCH_FORWARD: {
    NAME: 'TRAIN_SEARCH_FORWARD',
  },
  TRAIN_SEARCH_BACKWARD: {
    NAME: 'TRAIN_SEARCH_BACKWARD',
  },
  TRAIN_BOOKING: {
    NAME: 'TRAIN_BOOKING',

    PASSENGER_SURNAME_INPUT: 'PASSENGER_SURNAME_INPUT',
    PASSENGER_NAME_INPUT: 'PASSENGER_NAME_INPUT',
    PASSENGER_SECOND_NAME_INPUT: 'PASSENGER_SECOND_NAME_INPUT',
    PASSENGER_BIRTHDAY_INPUT: 'PASSENGER_BIRTHDAY_INPUT',
    PASSENGER_NO_SECOND_NAME_CHECKBOX: 'PASSENGER_NO_SECOND_NAME_CHECKBOX',
    PASSENGER_NO_NAME_CHECKBOX: 'PASSENGER_NO_NAME_CHECKBOX',
    PASSENGER_NO_SURNAME_CHECKBOX: 'PASSENGER_NO_SURNAME_CHECKBOX',
    PASSENGER_GENDER_CHECKBOX: 'PASSENGER_GENDER_CHECKBOX',
    PASSENGER_CITIZENSHIP_SELECT: 'PASSENGER_CITIZENSHIP_SELECT',
    PASSENGER_DOCUMENT_SELECT: 'PASSENGER_DOCUMENT_SELECT',
    PASSENGER_DOCUMENT_NUMBER_INPUT: 'PASSENGER_DOCUMENT_NUMBER_INPUT',
  },
  BUS_BOOKING_FIRST_STEP: {
    NAME: 'BUS_BOOKING_FIRST_STEP',
  },
  ORDER_DETAILS: {
    NAME: 'ORDER_DETAILS',
  },
  ORDERS_LIST: {
    NAME: 'ORDERS_LIST',
  },
  CONTACTS: {
    CONTACTS_NAME_INPUT: 'CONTACTS_NAME_INPUT',
    CONTACTS_PHONE_INPUT: 'CONTACTS_PHONE_INPUT',
    CONTACTS_EMAIL_INPUT: 'CONTACTS_EMAIL_INPUT',
  },
};
