import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import {
  FormikPassengerItemEntity,
  getBookingFaresState,
  setPassengerName,
  setPassengerSecondName,
  setPassengerSurname,
  UpdatePassengerPayload,
} from '@modules/booking';
import { PassengerItemProps } from './types';
import { Select, DatePicker, RadioGroup, Checkbox } from '@components/ui/form';
import { Helper, Hooks } from '@utils';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '@modules/index';
import { PassangerAutoSelectModal } from '../AutoSelectModal';
import { getCountrySearchList, getPasList } from '@app/utils/helpers';
import _ from 'lodash';
import moment from 'moment';
import { getPassanagersRequest, getUserState } from '@modules/user';
import NumberTooltip from '@components/aviaPassengers/Form/NumberTooltip';
import InputMask from 'react-input-mask';
import { CONVERTED_DOCS } from '../AutoSelectModal/utils';
import { SELECTED_DOC } from '../AutoSelectModal/PassangerAutoSelectModal';
import CountriesSelect from '@components/booking/CountriesSelect';
import { LOGGING_ACTION_IDS } from '@modules/logs/constants';
import { postSessionLogHandler } from '@modules/logs/duck';
import { UserActionTypes } from '@modules/logs/types';
import { FLIGHT_TYPES } from '@components/booking/FlightDetail/types';
import TransportTypeDescription from '@components/booking/PassengersBlock/AviaPassengerItem/TransportTypeDescription';
import PassengerHeader from '@components/booking/PassengersBlock/AviaPassengerItem/PassengerHeader';

import {
  Wrapper,
  RelativeContainer,
  SubLabel,
  SubLabelMarginTop,
  Label,
  FormBlockNew,
  AddPassengers,
  LoyaltyBlock,
  StyledField,
  FioBLock,
  AbsolutePassengersList,
  AbsoluteItem,
  AbsoluteSubText,
  CrossRow,
  CrossBlock,
  HandleErrorText,
  Cross,
  MarginBottomBlock,
  ColumnFlexBlock,
  MarginTopBlock,
  DescriptionTextItalic,
  BlueText,
  DescriptionText,
  LabelSmallFontSize,
} from './AviaPassengerItem/AviaPassengerItem.styles';

interface FormikPassengerError {
  birthDate: string;
  name: string;
  nameEng: string;
  surname: string;
  surnameEng: string;
  secondName: string;
  secondNameEng: string;
  number: string;
  documentInfo: string;
  documentSeries: string;
  dateOfIssue: string;
  sex: string;
  documentType: string;
  citizenship: string;
  type: {
    id: string;
    description: string;
  };
  sameFioWarning: string;
}

const PassengerItem: React.FC<PassengerItemProps> = ({
  index,
  onChange,
  frequentFlyerAirlines,
  countriesList,
  setIsShowError,
  ...props
}) => {
  const dispatch = useDispatch();
  const { isMobile } = Hooks.useMediaSizes();

  const searchPanelType = useSelector(
    (state: ApplicationState) => state.ui.searchPanelType,
  );
  const fares = useSelector(getBookingFaresState);
  const userState = useSelector(getUserState);
  const booking = useSelector((state: ApplicationState) => state.booking);
  const passengers = useSelector(
    (state: ApplicationState) => state.user.aviaPassengers,
  );
  const isAuthorized = useSelector(
    (state: ApplicationState) => state.user.status.isAuthorized,
  );

  const [passengersList, setPassengersList] = React.useState([]);
  const [citizenAbsolute, setCitizenAbsolute] = React.useState({
    value: '',
    label: '',
  });
  const [isNumberFocus, setIsNumberFocus] = React.useState(false);
  const [isLoyalVisibile, setLoyalVisibility] = React.useState(false);

  const [isSecondNameRequired, setIsSecondNameRequired] = React.useState(
    props.secondNameRequired,
  );

  const ticket = booking.ticket;
  const flights = ticket.flights;
  const isInternationalFlight = ticket.isInternationalFlight;
  const isDomesticPassportFlight = ticket.isDomesticPassportFlight;

  let airlines: any[] = [];
  let notSamo = true;
  const isAvia = searchPanelType === 'avia';

  const oneOfFlightsIsCharter = flights
    ?.map((flight: any) =>
      flight?.segments?.find(
        (segment: any) => segment?.flight_Type === FLIGHT_TYPES.CHARTER,
      ),
    )
    .filter(Boolean);

  const formik = useFormikContext<{
    passengers: FormikPassengerItemEntity[];
  }>();
  if (isAvia) {
    notSamo =
      fares?.items?.some((x) => x.gds !== 'SAMO') &&
      userState?.data?.agentGroup !== 'Самотур (GDS тур)';

    airlines = React.useMemo(
      () =>
        fares.items
          .map((x) =>
            x.groups
              ?.map((y) =>
                y.flights
                  .map((z) =>
                    z.segments.map((h) => ({
                      value: h.airlineCode,
                      label: h.airline,
                    })),
                  )
                  .flat(),
              )
              .flat(),
          )
          .flat(),

      [frequentFlyerAirlines?.length],
    )
      .flat()
      .filter(Boolean);
  }

  const airlinesCombined = airlines.reduce((op, inp) => {
    op[inp.value] = inp;
    return op;
  }, {});

  const handleLogging = (
    eventType: UserActionTypes,
    actionId: string,
    value?: unknown,
  ) => {
    dispatch(
      postSessionLogHandler({
        eventType: eventType,
        screen: LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.NAME,
        actionId: actionId,
        value: value,
      }),
    );
  };

  const handleTicketClassChange = (value: string) => {
    formik.setFieldValue(`passengers.${index}.sex`, value);
    handleLogging(
      UserActionTypes['Change'],
      LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.PASSENGER_GENDER_CHECKBOX,
      value,
    );
  };

  const errors =
    (formik.errors.passengers as undefined | FormikPassengerError[]) || [];
  const error = errors[index] ? errors[index] : ({} as FormikPassengerError);
  const { touched, setFieldTouched } = formik;

  const isTouched =
    touched.passengers && touched.passengers[index]
      ? touched.passengers[index]
      : ({} as FormikPassengerItemEntity);

  const DocumentType = React.useMemo(() => {
    switch (props.ageCategory) {
      case 'ADULT': {
        let arr = [
          { value: 'PASSPORT', label: 'Паспорт РФ' },
          { value: 'INTERNATIONAL_PASSPORT1', label: 'Заграничный паспорт' },
          { value: 'BIRTH_CERTIFICATE', label: 'Свидетельство о рождении' },
          { value: 'NATIONALPASSPORT', label: 'Нац. паспорт' },
          { value: 'INTERNATIONAL_PASSPORT', label: 'Другой документ' },
        ];

        if (isInternationalFlight && !isDomesticPassportFlight) {
          arr = arr.filter((val) => val.value !== 'PASSPORT');
        }
        if (isInternationalFlight) {
          arr = arr.filter((val) => val.value !== 'BIRTH_CERTIFICATE');
        }
        if (props.citizenship === 'RU') {
          arr = arr.filter((val) => val.value !== 'NATIONALPASSPORT');
        }
        if (props.citizenship !== 'RU') {
          arr = arr.filter((val) => val.value !== 'INTERNATIONAL_PASSPORT1');
          arr = arr.filter((val) => val.value !== 'PASSPORT');
        }
        return arr;
      }
      default: {
        const arr = [
          { value: 'BIRTH_CERTIFICATE', label: 'Свидетельство о рождении' },
          { value: 'INTERNATIONAL_PASSPORT1', label: 'Заграничный паспорт' },
        ];
        if (props.citizenship !== 'RU' || isInternationalFlight) {
          arr.push({ value: 'NATIONALPASSPORT', label: 'Нац. паспорт' });
          arr.push({
            value: 'INTERNATIONAL_PASSPORT',
            label: 'Другой документ',
          });
          arr.splice(0, 1);
        }
        return arr;
      }
    }
  }, [props.ageCategory, props.birthDate, props.citizenship]);

  const [{ documentType, citizenship }, setSelectVal] = useState({
    documentType: DocumentType[0],
    citizenship: { value: 'RU', label: 'Россия' },
  });

  useEffect(() => {
    if (citizenship.value === 'RU') {
      formik.setFieldValue(
        `passengers.${index}.documentType`,
        documentType?.value,
      );
      if (documentType?.value === 'NATIONALPASSPORT') {
        formik.setFieldValue(
          `passengers.${index}.documentType`,
          'INTERNATIONAL_PASSPORT1',
        );
        setSelectVal(() => ({ citizenship, documentType: DocumentType[0] }));
      }
    } else {
      formik.setFieldValue(
        `passengers.${index}.documentType`,
        'INTERNATIONAL_PASSPORT1',
      );
    }

    formik.setFieldValue(`passengers.${index}.citizenship`, citizenship?.value);
    if (citizenship?.value !== 'RU') {
      setSelectVal(() => ({ citizenship, documentType: DocumentType[0] }));
    }
  }, [DocumentType, props.citizenship]);

  const handleBirthDateChange = (val: Date | null | any) => {
    if (val.getMonth) {
      formik.setFieldValue(`passengers.${index}.birthDate`, val);
    } else {
      formik.setFieldValue(`passengers.${index}.birthDate`, val);

      const date = val;
      const [day, month, year] = date.split('.');
      const newDate = new Date(+year, +month - 1, +day);
      if (moment(newDate).isValid()) {
        formik.setFieldValue(`passengers.${index}.birthDate`, newDate);
      }
    }
    handleLogging(
      UserActionTypes['Change'],
      LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.PASSENGER_BIRTHDAY_INPUT,
      val,
    );
  };
  const setPassengerNameDebounced = React.useMemo(
    () =>
      _.debounce((args: UpdatePassengerPayload) => {
        formik.setFieldValue(
          `passengers.${args.passengerIndex}.nameEng`,
          notSamo
            ? Helper.translateToLatin(args.value).toLocaleUpperCase()
            : Helper.translateToLatinSamo(args.value).toLocaleUpperCase(),
        );
        dispatch(setPassengerName(args));
        handleLogging(
          UserActionTypes['Change'],
          LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.PASSENGER_NAME_INPUT,
          args.value,
        );
      }, 400),
    [],
  );

  const setPassengerSurnameDebounced = React.useMemo(
    () =>
      _.debounce((args: UpdatePassengerPayload) => {
        formik.setFieldValue(
          `passengers.${args.passengerIndex}.surnameEng`,
          notSamo
            ? Helper.translateToLatin(args.value).toLocaleUpperCase()
            : Helper.translateToLatinSamo(args.value).toLocaleUpperCase(),
        );
        dispatch(setPassengerSurname(args));
        handleLogging(
          UserActionTypes['Change'],
          LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.PASSENGER_SURNAME_INPUT,
          args.value,
        );
      }, 400),
    [],
  );

  const setPassengerSecondNameDebounced = React.useMemo(
    () =>
      _.debounce((args: UpdatePassengerPayload) => {
        formik.setFieldValue(
          `passengers.${args.passengerIndex}.secondNameEng`,
          notSamo
            ? Helper.translateToLatin(args.value).toLocaleUpperCase()
            : Helper.translateToLatinSamo(args.value).toLocaleUpperCase(),
        );
        dispatch(setPassengerSecondName(args));
        handleLogging(
          UserActionTypes['Change'],
          LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP
            .PASSENGER_SECOND_NAME_INPUT,
          args.value,
        );
      }, 400),
    [],
  );

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const trimValue = !isInternationalFlight
      ? e.target.value.trim()
      : e.target.value.trim().replace(/[^A-Za-z]/gi, '');
    setPassengerNameDebounced({ passengerIndex: index, value: trimValue });
    if (oneOfFlightsIsCharter.length !== 0 && !isInternationalFlight) {
      formik.setFieldValue(`passengers.${index}.name`, trimValue);
    } else {
      formik.setFieldValue(
        `passengers.${index}.name`,
        Helper.translateToLatin(trimValue).toLocaleUpperCase(),
      );
    }
  };

  const pasList = async (str: string) => {
    const response = await getPasList(str);
    setPassengersList(
      response
        ? response.map((pas: any) => ({
            value: pas,
            label: pas.lastName + ' ' + pas.firstName + ' ' + pas.patronymic,
          }))
        : [],
    );
  };

  const handleSurnameChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const trimValue = !isInternationalFlight
      ? e.target.value.trim()
      : e.target.value.trim().replace(/[^A-Za-z]/gi, '');
    if (trimValue !== '') {
      pasList(trimValue);
    }
    if (trimValue === '') {
      setPassengersList([]);
    }
    setPassengerSurnameDebounced({
      passengerIndex: index,
      value: trimValue,
    });
    if (oneOfFlightsIsCharter.length !== 0 && !isInternationalFlight) {
      formik.setFieldValue(`passengers.${index}.surname`, trimValue);
    } else {
      formik.setFieldValue(
        `passengers.${index}.surname`,
        Helper.translateToLatin(trimValue).toLocaleUpperCase(),
      );
    }
  };

  const handleSecondNameChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const trimValue = !isInternationalFlight
      ? e.target.value.trim()
      : e.target.value.trim().replace(/[^A-Za-z]/gi, '');
    setPassengerSecondNameDebounced({
      passengerIndex: index,
      value: trimValue,
    });

    if (oneOfFlightsIsCharter.length !== 0 && !isInternationalFlight) {
      formik.setFieldValue(`passengers.${index}.secondName`, trimValue);
    } else {
      formik.setFieldValue(
        `passengers.${index}.secondName`,
        Helper.translateToLatin(trimValue).toLocaleUpperCase(),
      );
    }
  };

  const handledateOfIssue = (val: Date | null | any) => {
    if (val.getMonth) {
      formik.setFieldValue(`passengers.${index}.dateOfIssue`, val);
    } else {
      formik.setFieldValue(`passengers.${index}.dateOfIssue`, val);

      const date = val;
      const [day, month, year] = date.split('.');
      const newDate = new Date(+year, +month - 1, +day);
      if (moment(newDate).isValid()) {
        formik.setFieldValue(`passengers.${index}.dateOfIssue`, newDate);
      }
    }
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    const target = e.target;
    setFieldTouched(`passengers.${index}.${target.name}`, true, true);
  };

  const handleBlurDocumentInfo = () => {
    formik.setFieldTouched(`passengers.${index}.documentInfo`, true);
    formik.validateField(`passengers.${index}.documentInfo`);
  };

  const handleBlurDocumentSeries = () => {
    formik.setFieldTouched(`passengers.${index}.documentSeries`, true);
    formik.validateField(`passengers.${index}.documentSeries`);
  };

  const handleDocumentTypeChange = (val: any) => {
    setSelectVal((select) => ({ ...select, documentType: val }));
    formik.setFieldValue(`passengers.${index}.documentType`, val.value);
    if (!notSamo) {
      formik.setFieldValue(
        `passengers.${index}.documentSeries`,
        val.value === 'PASSPORT'
          ? modalData?.number?.replace(/[^A-Za-z0-9]/g, '').substring(0, 4)
          : val.value === 'INTERNATIONAL_PASSPORT1' &&
              modalData?.citizen?.value === 'RU'
            ? modalData?.number?.replace(/[^A-Za-z0-9]/g, '').substring(0, 2)
            : val.value === 'BIRTH_CERTIFICATE' &&
                modalData?.citizen?.value === 'RU'
              ? modalData?.number
                  .replace(/[^A-Za-z0-9]/g, '')
                  .substring(0, props?.number?.length - 6)
              : '',
      );

      formik.setFieldValue(
        `passengers.${index}.documentInfo`,
        val.value === 'PASSPORT'
          ? modalData?.number?.replace(/[^A-Za-z0-9]/g, '').substring(4)
          : val.value === 'INTERNATIONAL_PASSPORT1' &&
              modalData?.citizen?.value === 'RU'
            ? modalData?.number?.replace(/[^A-Za-z0-9]/g, '').substring(2)
            : val.value === 'BIRTH_CERTIFICATE' &&
                modalData?.citizen?.value === 'RU'
              ? modalData?.number?.replace(/[^A-Za-z0-9]/g, '').slice(-6)
              : modalData?.number,
      );
    }

    handleLogging(
      UserActionTypes['Change'],
      LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.PASSENGER_DOCUMENT_SELECT,
      val,
    );
    return;
  };

  const handleChangeDocumentNumber = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    formik.setFieldValue(`passengers.${index}.number`, event.target.value);
    handleLogging(
      UserActionTypes['Change'],
      LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP
        .PASSENGER_DOCUMENT_NUMBER_INPUT,
      event.target.value,
    );
  };

  const handleAviacompanyChange = (val: any, i: number) => {
    formik.setFieldValue(
      `passengers.${index}.loyality.${i}.code`,
      val.value.toString(),
    );
    handleLogging(
      UserActionTypes['Change'],
      LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP
        .PASSENGER_LOYALTY_CARD_COMPANY_SELECT,
      val.value.toString(),
    );
    return;
  };

  const handleCitizenshipChange = (val: any) => {
    setSelectVal((select) => ({ ...select, citizenship: val }));
    formik.setFieldValue(`passengers.${index}.citizenship`, val.value);
    if (val.value !== 'RU') {
      formik.setFieldValue(
        `passengers.${index}.documentType`,
        'INTERNATIONAL_PASSPORT1',
      );
    }
    handleLogging(
      UserActionTypes['Change'],
      LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.PASSENGER_CITIZENSHIP_SELECT,
      val.label,
    );
    return;
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      !isInternationalFlight ||
      (isInternationalFlight && citizenship.value !== 'RU')
    ) {
      formik.setFieldValue(
        `passengers.${index}.secondNameRequired`,
        !e.target.checked,
      );
    }
    if (e.target.checked) {
      formik.setFieldValue(`passengers.${index}.secondName`, '');
      formik.setFieldValue(`passengers.${index}.secondNameEng`, '');
    }
    handleLogging(
      UserActionTypes['Change'],
      LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP
        .PASSENGER_NO_SECOND_NAME_CHECKBOX,
      e.target.checked,
    );
  };

  const handleChangeLoyalInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
  ) => {
    const value = e.target.value;

    handleLogging(
      UserActionTypes['Change'],
      LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP
        .PASSENGER_LOYALTY_CARD_NUMBER_INPUT,
      value,
    );

    formik.setFieldValue(
      `passengers.${index}.loyality.${i}.value`,
      value.toString(),
    );
  };

  const documentMask = React.useMemo(() => {
    switch (documentType?.value) {
      case 'PASSPORT':
        return props.citizenship === 'RU' ? '9999-999999' : '';

      case 'INTERNATIONAL_PASSPORT1':
        return props.citizenship === 'RU' ? '99 9999999' : '';

      default:
        return '';
    }
  }, [documentType?.value, props.citizenship]);

  const documentMaskCharterSeries = React.useMemo(() => {
    switch (documentType?.value) {
      case 'PASSPORT':
        return props.citizenship === 'RU' ? '9999' : '';

      case 'INTERNATIONAL_PASSPORT1':
        return props.citizenship === 'RU' ? '99' : '';

      default:
        return '';
    }
  }, [documentType?.value, props.citizenship]);

  const documentMaskCharterNumber = React.useMemo(() => {
    switch (documentType?.value) {
      case 'PASSPORT':
        return props.citizenship === 'RU' ? '999999' : '';

      case 'INTERNATIONAL_PASSPORT1':
        return props.citizenship === 'RU' ? '9999999' : '';

      default:
        return '';
    }
  }, [documentType?.value, props.citizenship]);

  const numberPlaceholder = React.useMemo(() => {
    if (
      documentType?.value === 'BIRTH_CERTIFICATE' &&
      citizenship?.value === 'RU'
    ) {
      return 'XII-AA 000000';
    } else if (documentType?.value === 'PASSPORT') {
      return '9999-999999';
    } else if (documentType?.value === 'INTERNATIONAL_PASSPORT1') {
      return '99 9999999';
    } else {
      return isMobile ? undefined : 'Номер';
    }
  }, [isMobile, documentType?.value, citizenship]);

  const [isModalOpen, setisModalOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState<any>(null);

  const openAutoSelect = () => {
    handleLogging(
      UserActionTypes['Click'],
      LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.ADD_FROM_PASSENGERS_BTN,
    );
    setisModalOpen(true);
    setModalData(null);
  };
  const closeAutoSelect = () => {
    setisModalOpen(false);
  };
  const [autoSelectInProgress, setProgress] = React.useState(false);
  const openAutoSelectCallback = useCallback(
    () => openAutoSelect(),
    [openAutoSelect],
  );

  const canAddFromPassengers = useMemo(() => {
    return isAuthorized && !autoSelectInProgress;
  }, [isAuthorized, autoSelectInProgress]);

  React.useEffect(() => {
    if (isAuthorized) {
      dispatch(getPassanagersRequest({ SearchText: '' }));
    }
  }, [isAuthorized]);

  const filteredPassengers = React.useMemo(() => {
    if (passengers.length) {
      const createDif = (date: string | Date) => {
        const given = moment(date);
        const current = moment().startOf('day');
        return moment.duration(current.diff(given)).asYears();
      };

      if (props.ageCategory === 'ADULT') {
        return passengers.filter((x) => {
          const diff = createDif(x.birthDate);

          return diff >= 12 ? true : false;
        });
      } else if (props.ageCategory === 'CHILD') {
        return passengers.filter((x) => {
          const diff = createDif(x.birthDate);

          return diff < 12 && diff > 2 ? true : false;
        });
      } else {
        return passengers.filter((x) => {
          const diff = createDif(x.birthDate);

          return diff < 2 ? true : false;
        });
      }
    }

    return [];
  }, [passengers, props.ageCategory]);

  React.useEffect(() => {
    if (modalData) {
      const {
        documentType,
        lastName,
        name,
        secondName,
        number,
        dateOfIssue,
        // dateOfIssueRequired,
        birhDate,
        sex,
        secondNameRequired,
        customerPassengerId,
      } = modalData;

      if (
        isInternationalFlight &&
        (documentType?.value === 'PASSPORT' ||
          documentType?.value === 'BIRTH_CERTIFICATE')
      ) {
        setSelectVal((select) => ({
          ...select,
          citizenship: citizenAbsolute,
          documentType: {
            value: 'INTERNATIONAL_PASSPORT1',
            label: 'Заграничный паспорт',
          },
        }));
      } else {
        setSelectVal((select) => ({
          ...select,
          citizenship: citizenAbsolute,
          documentType,
        }));
      }

      if (
        isInternationalFlight &&
        (documentType?.value === 'PASSPORT' ||
          documentType?.value === 'BIRTH_CERTIFICATE')
      ) {
        formik.setFieldValue(
          `passengers.${index}.documentType`,
          'INTERNATIONAL_PASSPORT1',
        );
      } else {
        formik.setFieldValue(
          `passengers.${index}.documentType`,
          documentType?.value,
        );
      }

      formik.setFieldValue(
        `passengers.${index}.citizenship`,
        citizenAbsolute ? citizenAbsolute?.value : 'RU',
      );
      formik.setFieldValue(`passengers.${index}.birthDate`, birhDate);
      formik.setFieldValue(`passengers.${index}.surname`, lastName);
      if (
        isInternationalFlight &&
        (documentType?.value === 'PASSPORT' ||
          documentType?.value === 'BIRTH_CERTIFICATE')
      ) {
        formik.setFieldValue(`passengers.${index}.number`, '');
      } else {
        formik.setFieldValue(`passengers.${index}.number`, number);
      }

      if (oneOfFlightsIsCharter?.length !== 0 && isInternationalFlight) {
        formik.setFieldValue(`passengers.${index}.name`, name);
        formik.setFieldValue(`passengers.${index}.nameEng`, name);
      } else if (
        oneOfFlightsIsCharter?.length === 0 ||
        (oneOfFlightsIsCharter?.length !== 0 && !isInternationalFlight)
      ) {
        formik.setFieldValue(`passengers.${index}.name`, name);
        if (isSecondNameRequired && !isInternationalFlight) {
          formik.setFieldValue(`passengers.${index}.secondName`, secondName);
          formik.setFieldValue(`passengers.${index}.secondNameEng`, secondName);
        }
        formik.setFieldValue(`passengers.${index}.nameEng`, name);
      }
      formik.setFieldValue(`passengers.${index}.surnameEng`, lastName);
      formik.setFieldValue(`passengers.${index}.dateOfIssue`, dateOfIssue);
      formik.setFieldValue(`passengers.${index}.sex`, sex);
      formik.setFieldValue(
        `passengers.${index}.customerPassengerId`,
        customerPassengerId,
      );
      if (!notSamo) {
        formik.setFieldValue(
          `passengers.${index}.documentSeries`,
          documentType?.value === 'PASSPORT'
            ? number.replace(/[^A-Za-z0-9]/g, '').substring(0, 4)
            : documentType?.value === 'INTERNATIONAL_PASSPORT1' &&
                citizenAbsolute?.value === 'RU'
              ? number.replace(/[^A-Za-z0-9]/g, '').substring(0, 2)
              : documentType?.value === 'BIRTH_CERTIFICATE' &&
                  citizenAbsolute?.value === 'RU'
                ? number
                    .replace(/[^A-Za-z0-9]/g, '')
                    .substring(0, props.number.length - 6)
                : '',
        );

        formik.setFieldValue(
          `passengers.${index}.documentInfo`,
          documentType?.value === 'PASSPORT'
            ? number.replace(/[^A-Za-z0-9]/g, '').substring(4)
            : documentType?.value === 'INTERNATIONAL_PASSPORT1' &&
                citizenAbsolute?.value === 'RU'
              ? number.replace(/[^A-Za-z0-9]/g, '').substring(2)
              : documentType?.value === 'BIRTH_CERTIFICATE' &&
                  citizenAbsolute?.value === 'RU'
                ? number.replace(/[^A-Za-z0-9]/g, '').slice(-6)
                : number,
        );
      }

      if (oneOfFlightsIsCharter?.length === 0) {
        formik.setFieldValue(
          `passengers.${index}.secondNameRequired`,
          secondNameRequired,
        );
      }

      closeAutoSelect();
      setProgress(false);
    }
  }, [modalData, citizenAbsolute]);

  const handleBlurName = () => {
    formik.setFieldTouched(`passengers.${index}.name`, false);
  };

  const handleBlurSurname = () => {
    formik.setFieldTouched(`passengers.${index}.surname`, false);
  };

  const handleBlurSecondName = () => {
    formik.setFieldTouched(`passengers.${index}.secondName`, false);
  };

  const documentPassengersList = passengersList
    .map((x: any) =>
      x.value.documents.map((doc: any) => ({ value: x.value, doc: doc })),
    )
    .flat();

  const transformData = (passengerId?: string, docType?: string) => {
    setProgress(true);
    const passenger: any = passengers.find(
      (x: any) => x.customerPassengerId === passengerId,
    );
    const document = passenger?.documents.find((x: any) => x?.id === docType);

    const getCountry = async () => {
      const countries = await getCountrySearchList(
        document?.citizenship || 'Россия',
      );
      if (countries.length) {
        const [a] = countries;
        setCitizenAbsolute(a);
      }
    };

    getCountry();

    const documentType = CONVERTED_DOCS[document?.type];

    const lastName = passenger.lastName;
    const name = passenger.firstName;
    const secondName = passenger.patronymic;
    const number = document?.number;
    const dateOfIssue = document?.issueDate
      ? new Date(document?.issueDate)
      : null;
    const birhDate = new Date(passenger.birthDate);
    const sex = passenger.gender === 'MALE' ? 'm' : 'f';
    const secondNameRequired =
      passenger?.patronymic && !isInternationalFlight ? true : false;
    const customerPassengerId = passenger?.customerPassengerId;

    setModalData({
      documentType,
      lastName,
      name,
      secondName,
      number,
      dateOfIssue,
      birhDate,
      sex,
      secondNameRequired,
      citizenAbsolute,
      customerPassengerId,
    });
  };

  const onClickOutsideListener = () => {
    setPassengersList([]);
    document.removeEventListener('click', onClickOutsideListener);
  };

  React.useEffect(() => {
    if (oneOfFlightsIsCharter?.length !== 0 || isInternationalFlight) {
      setIsSecondNameRequired(false);
    }
  }, []);

  React.useEffect(() => {
    if (isInternationalFlight && citizenship.value === 'RU') {
      setIsSecondNameRequired(false);
      formik.setFieldValue(`passengers.${index}.secondNameRequired`, false);
    }
  }, [citizenship]);

  React.useEffect(() => {
    if (
      setIsShowError &&
      formik.values.passengers
        .filter((_, i) => i !== 0)
        .some(
          ({ number }) =>
            number?.replace(/\D/g, '') ===
              formik.values.passengers[0].number?.replace(/\D/g, '') &&
            number !== '',
        )
    ) {
      setIsShowError(true);
    } else {
      setIsShowError && setIsShowError(false);
    }
  }, [formik, isTouched]);

  useEffect(() => {
    return () => {
      document.removeEventListener('click', onClickOutsideListener);
    };
  }, []);

  return (
    <Wrapper id={`passenger.${index}`} key={props.uid}>
      <TransportTypeDescription isAvia={isAvia} />
      {canAddFromPassengers && (
        <AddPassengers onClick={openAutoSelectCallback}>
          + Добавить из моих пассажиров
        </AddPassengers>
      )}
      <PassengerHeader
        isAuthorized={isAuthorized || false}
        ageCategory={props.ageCategory}
        ageCategoryDescription={props.ageCategoryDescription}
        index={index}
      />
      <FormBlockNew isSamo={!notSamo}>
        <FioBLock className="lastName">
          <StyledField
            data-cy="lastNameRu"
            name={`passengers.${index}.surname`}
            wrapperClassName="custom-input"
            error={
              error.sameFioWarning && isTouched.sameFioWarning
                ? error.sameFioWarning
                : error.surname && isTouched.surname
                  ? error.surname
                  : undefined
            }
            onFocus={() =>
              formik.setFieldTouched(`passengers.${index}.surname`, true)
            }
            onChange={handleSurnameChange}
            onBlur={handleBlurSurname}
            label={isInternationalFlight ? 'Фамилия (на латинице)' : 'Фамилия'}
            autoComplete={`random-string-1-${index}`}
            value={props.surname}
            onMouseLeave={() => {
              document.addEventListener('click', onClickOutsideListener);
            }}
          />
          <input
            type="text"
            name={`passengers.${index}.surname`}
            style={{ display: 'none' }}
          />
          {isMobile &&
            !error.surname &&
            isTouched.surname &&
            !isInternationalFlight &&
            oneOfFlightsIsCharter?.length === 0 && (
              <SubLabel>Мы переведем ФИО на латиницу</SubLabel>
            )}

          {!isMobile &&
            !isInternationalFlight &&
            oneOfFlightsIsCharter?.length === 0 && (
              <RelativeContainer className="field-69">
                <SubLabelMarginTop>
                  Мы переведем ФИО на латиницу
                </SubLabelMarginTop>
              </RelativeContainer>
            )}

          {documentPassengersList && (
            <AbsolutePassengersList>
              {documentPassengersList.map((item, index) => (
                <AbsoluteItem
                  key={index}
                  onClick={() => {
                    transformData(item.value.customerPassengerId, item.doc.id);
                    setPassengersList([]);
                  }}
                >
                  <MarginBottomBlock>
                    {item.value.firstName +
                      ' ' +
                      item.value.lastName +
                      ' ' +
                      item.value.patronymic}
                  </MarginBottomBlock>
                  <AbsoluteSubText>
                    {SELECTED_DOC[item.doc.type]?.label +
                      ' ' +
                      item?.doc?.number}
                  </AbsoluteSubText>
                </AbsoluteItem>
              ))}
            </AbsolutePassengersList>
          )}
        </FioBLock>
        <FioBLock className="field-2">
          <StyledField
            data-cy="nameRu"
            name={`passengers.${index}.name`}
            wrapperClassName="custom-input"
            error={error.name && isTouched.name ? error.name : undefined}
            onChange={handleNameChange}
            onBlur={handleBlurName}
            onFocus={() =>
              formik.setFieldTouched(`passengers.${index}.name`, true)
            }
            label={isInternationalFlight ? 'Имя (на латинице)' : 'Имя'}
            autoComplete={`random-string-2-${index}`}
            value={props.name}
          />
          {isMobile &&
            !error.name &&
            isTouched.name &&
            !isInternationalFlight &&
            oneOfFlightsIsCharter?.length === 0 && (
              <SubLabel>Мы переведем ФИО на латиницу</SubLabel>
            )}
        </FioBLock>
        {!(
          (oneOfFlightsIsCharter?.length !== 0 && isInternationalFlight) ||
          (isInternationalFlight &&
            citizenship.value === 'RU' &&
            documentType?.value === 'INTERNATIONAL_PASSPORT1')
        ) && (
          <FioBLock className="field-3">
            <StyledField
              data-cy="patronymicNameRu"
              name={`passengers.${index}.secondName`}
              wrapperClassName="custom-input"
              error={
                error.secondName &&
                isTouched.secondName &&
                props.secondNameRequired
                  ? error.secondName
                  : undefined
              }
              disabled={
                !props.secondNameRequired ||
                (oneOfFlightsIsCharter?.length !== 0 && isInternationalFlight)
              }
              onFocus={() =>
                formik.setFieldTouched(`passengers.${index}.secondName`, true)
              }
              onChange={handleSecondNameChange}
              onBlur={handleBlurSecondName}
              label={
                isInternationalFlight ? 'Отчество (на латинице)' : 'Отчество'
              }
              autoComplete={`random-string-3-${index}`}
              value={
                oneOfFlightsIsCharter?.length !== 0 && isInternationalFlight
                  ? ''
                  : props.secondName
              }
            />
            {isMobile &&
              !error.secondName &&
              isTouched.secondName &&
              !isInternationalFlight &&
              oneOfFlightsIsCharter?.length === 0 && (
                <SubLabel>Мы переведем ФИО на латиницу </SubLabel>
              )}
          </FioBLock>
        )}

        <RelativeContainer className="field-4">
          <Checkbox
            onChange={handleCheckboxChange}
            type="squar"
            label="Без отчества"
            checked={
              !props.secondNameRequired ||
              (oneOfFlightsIsCharter?.length !== 0 && isInternationalFlight)
            }
          />
        </RelativeContainer>
        {isMobile && (
          <InputMask
            mask="99.99.9999"
            onChange={(e) => handleBirthDateChange(e.target.value)}
            value={
              moment(props.birthDate).isValid()
                ? moment(props.birthDate).format('DD.MM.YYYY')
                : props.birthDate?.toString()
            }
            alwaysShowMask={false}
          >
            <StyledField
              className="field-5"
              label="Дата рождения"
              name="partnerId"
              type="tel"
              error={
                error.birthDate &&
                isTouched.birthDate &&
                !error.birthDate.includes('must be a `date`')
                  ? error.birthDate
                  : error.birthDate &&
                      isTouched.birthDate &&
                      error.birthDate.includes('must be a `date`')
                    ? 'Неправильный формат'
                    : undefined
              }
              inputMode="numeric"
              placeholder="дд.мм.гггг"
            />
          </InputMask>
        )}
        {!isMobile && (
          <DatePicker
            dataCy="birthDateInput"
            className="field-5"
            value={props.birthDate}
            onChange={handleBirthDateChange}
            label="Дата рождения"
            error={
              error.birthDate && isTouched.birthDate
                ? error.birthDate
                : undefined
            }
            isBirthDate={true}
            selectClose={true}
          />
        )}

        <ColumnFlexBlock className="field-6">
          <Label>Пол</Label>
          <div>
            <RadioGroup
              name={`passenger-${index}`}
              flex
              defaultValue={'m'}
              onChange={handleTicketClassChange}
              items={[
                { value: 'm', label: 'Мужской' },
                { value: 'f', label: 'Женский' },
              ]}
              withAutoSelect={!!modalData}
              autoSelectData={modalData ? modalData.sex : ''}
            />
          </div>
        </ColumnFlexBlock>

        <MarginTopBlock className="field-7" data-cy={'citizenshipSelect'}>
          <Label>Гражданство </Label>
          <CountriesSelect
            searchType={'avia'}
            error={error.citizenship}
            citizenship={citizenship}
            handleCitizenshipChange={(val) => handleCitizenshipChange(val)}
            countriesList={countriesList}
          />
        </MarginTopBlock>

        <MarginTopBlock className="field-8" data-cy={'documentSelect'}>
          <Select
            label="Выберите документ"
            onChange={handleDocumentTypeChange}
            isSearchable={false}
            value={documentType}
            defaultValue={DocumentType[0]}
            options={DocumentType}
          />
        </MarginTopBlock>
        {notSamo ? (
          <div className="field-9">
            <NumberTooltip
              handleChange={handleChangeDocumentNumber}
              handleBlur={handleBlur}
              mask={documentMask}
              value={props.number}
              isNumberFocus={(x: boolean) => setIsNumberFocus(x)}
            >
              <StyledField
                data-cy="passportNumber"
                wrapperClassName="custom-input"
                error={
                  error.number && isTouched.number ? error.number : undefined
                }
                label="Серия и номер"
                placeholder={numberPlaceholder}
                name="number"
              />
            </NumberTooltip>
            {isNumberFocus && !isTouched.number && error.number && (
              <HandleErrorText>Невалидный номер документа</HandleErrorText>
            )}

            {documentType?.value === 'BIRTH_CERTIFICATE' && (
              <>
                <DescriptionText>
                  Латинские буквы (Х, V, I), 2 буквы кириллицей и 6 цифр <br />
                </DescriptionText>
                <DescriptionTextItalic>
                  <BlueText>Пример:</BlueText> VI-МБ 563456
                </DescriptionTextItalic>
              </>
            )}
          </div>
        ) : (
          <>
            <div className="field-9">
              <NumberTooltip
                handleChange={(event) => {
                  formik.setFieldValue(
                    `passengers.${index}.documentSeries`,
                    event.target.value,
                  );
                }}
                handleBlur={handleBlurDocumentSeries}
                value={props.documentSeries}
                mask={documentMaskCharterSeries}
              >
                <StyledField
                  wrapperClassName="custom-input"
                  label="Серия документа"
                  name="documentSeries"
                  error={
                    error.documentSeries && isTouched.documentSeries
                      ? error.documentSeries
                      : undefined
                  }
                />
              </NumberTooltip>
              {documentType?.value === 'BIRTH_CERTIFICATE' && (
                <LabelSmallFontSize>
                  Латинские буквы (Х, V, I) и 2 буквы кириллицей
                </LabelSmallFontSize>
              )}
            </div>
            <div className="field-10">
              <NumberTooltip
                handleChange={(event) => {
                  formik.setFieldValue(
                    `passengers.${index}.documentInfo`,
                    event.target.value,
                  );
                }}
                handleBlur={handleBlurDocumentInfo}
                value={props.documentInfo}
                mask={documentMaskCharterNumber}
              >
                <StyledField
                  wrapperClassName="custom-input"
                  label="Номер документа"
                  name="documentInfo"
                  error={
                    error.documentInfo && isTouched.documentInfo
                      ? error.documentInfo
                      : undefined
                  }
                />
              </NumberTooltip>
              {documentType?.value === 'BIRTH_CERTIFICATE' && (
                <LabelSmallFontSize>6 цифр </LabelSmallFontSize>
              )}
            </div>
          </>
        )}

        {(documentType?.value === 'INTERNATIONAL_PASSPORT1' ||
          documentType?.value === 'INTERNATIONAL_PASSPORT' ||
          documentType?.value === 'NATIONALPASSPORT') && (
          <div className="field-12">
            <InputMask
              mask="99.99.9999"
              onChange={(e) => handledateOfIssue(e.target.value)}
              value={
                moment(props.dateOfIssue).isValid()
                  ? moment(props.dateOfIssue).format('DD.MM.YYYY')
                  : props.dateOfIssue?.toString()
              }
              maskChar={null}
              alwaysShowMask={false}
            >
              <StyledField
                placeholder="дд.мм.гггг"
                data-cy="expireDateOfDocument"
                label="Срок действия"
                name="partnerId"
                type="tel"
                error={
                  error.dateOfIssue &&
                  isTouched.dateOfIssue &&
                  !error.dateOfIssue.includes('must be a `date`')
                    ? error.dateOfIssue
                    : error.dateOfIssue &&
                        isTouched.dateOfIssue &&
                        error.dateOfIssue.includes('must be a `date`')
                      ? 'Неправильный формат'
                      : undefined
                }
                inputMode="numeric"
              />
            </InputMask>
          </div>
        )}
      </FormBlockNew>
      {Object.values(airlinesCombined).length >
        formik.values.passengers[index].loyality.length && (
        <>
          <AddPassengers
            onClick={() => {
              handleLogging(
                UserActionTypes['Click'],
                LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP
                  .PASSENGER_BONUS_CARD_ADD_BTN,
              );
              formik.setFieldValue(`passengers.${index}.loyality`, [
                ...formik.values.passengers[index].loyality,
                {
                  code: '',
                  value: '',
                },
              ]);
            }}
          >
            + Добавить бонусную карту
          </AddPassengers>
        </>
      )}

      {isAvia && formik.values.passengers[index].loyality.length !== 0 && (
        <>
          {formik.values.passengers[index].loyality.map((x, i) => (
            <LoyaltyBlock key={index} checked={isLoyalVisibile}>
              <>
                <div className="field-13">
                  <Select
                    label="Авиакомпания"
                    onChange={(e) => handleAviacompanyChange(e, i)}
                    isSearchable={false}
                    placeholder=""
                    //@ts-ignore
                    options={Object.values(airlinesCombined)}
                  />
                </div>
                <CrossRow className="field-14">
                  <StyledField
                    wrapperClassName="custom-input"
                    label="№ бонусной карты"
                    name={`loyalityValue + ${i}`}
                    onChange={(e) => handleChangeLoyalInput(e, i)}
                    value={props.loyality[i]?.value}
                    type="number"
                  />

                  <CrossBlock
                    onClick={() => {
                      formik.setFieldValue(`passengers.${index}.loyality`, []);
                    }}
                  >
                    <Cross />
                  </CrossBlock>
                </CrossRow>
              </>
            </LoyaltyBlock>
          ))}
        </>
      )}
      {isAuthorized && filteredPassengers.length > 0 && (
        <PassangerAutoSelectModal
          passangers={filteredPassengers}
          isOpen={isModalOpen}
          selectPassanger={setModalData}
          onClose={closeAutoSelect}
          setProgress={setProgress}
          autoSelectInProgress={autoSelectInProgress}
          oneOfFlightsIsCharter={oneOfFlightsIsCharter}
          setCitizenAbsolute={setCitizenAbsolute}
        />
      )}
    </Wrapper>
  );
};

export default PassengerItem;
