import { extractFirstNumber } from '@modules/main/utilities';
import { isNumber } from 'lodash';
import { FareFamily } from '@modules/orders/types';
import { FareFamilyEntity } from '@modules/booking';

export function setPage(page?: number) {
  return page ? page : 1;
}

export function setPageSize(pageSize?: number) {
  return pageSize ? pageSize : 0;
}

export const getMinBaggageDescription = (
  fareFamilies: FareFamilyEntity[] | FareFamily[],
): string[] => {
  let minBaggageValue: number | null = null;
  const minBaggageDescription: string[] | null = [];
  fareFamilies.map((x) =>
    x.features.map((feature) => {
      if (feature.type === 'Baggage') {
        const description = feature.descriptionRus;
        if (
          description?.toLowerCase()?.includes('платный') ||
          description === 'Не включен'
        ) {
          minBaggageDescription?.push(description);
        } else {
          const value = extractFirstNumber(description);
          if (isNumber(value)) {
            if (
              minBaggageValue === null ||
              (value !== null && value < minBaggageValue)
            ) {
              minBaggageValue = value;
              minBaggageDescription?.push(description);
            }
          }
        }
      }
    }),
  );

  return minBaggageDescription;
};
