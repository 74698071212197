import React from 'react';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';
import { Text } from '@components/ui';
import {
  FLIGHT_TYPES,
  FlightType,
} from '@components/booking/FlightDetail/types';
import { getFlightTypeTitle } from '@modules/main/utilities';

const IconContainer = styled.div<{ background: string }>`
  background: ${({ background }) => background};
  border-radius: 8px;
  padding: 4px 8px;
  cursor: pointer;
  color: #000000;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  & > svg {
    margin-right: 3px;
  }

  @media (max-width: 767px) {
    padding: 5px 6px;
    width: 95px;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
`;

const DescriptionText = styled(Text)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

const DescriptionBlock = styled.div`
  & > * {
    margin-bottom: 7px;
    display: block;
  }

  & > :first-child {
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

interface FlightTypeIconProps {
  flightType: FlightType;
  className?: string;
}

const generateDescriptionBlock = (flightType: FlightType | undefined) => {
  if (flightType === FLIGHT_TYPES.CHARTER) {
    return (
      <DescriptionBlock>
        <DescriptionText>
          Нерегулярные рейсы обычных авиакомпаний, организованные туроператорами
          или другими заказчиками.
        </DescriptionText>
        <DescriptionText>
          - время вылета/прилета может поменяться;
        </DescriptionText>
        <DescriptionText>
          - выписка билета может быть дольше обычного;
        </DescriptionText>
        <DescriptionText>- вылет может задержаться;</DescriptionText>
        <DescriptionText>- билеты нельзя вернуть.</DescriptionText>
      </DescriptionBlock>
    );
  }
  if (flightType === FLIGHT_TYPES.LOWCOSTER) {
    return (
      <DescriptionText>
        Перевозку совершает авиакомпания-лоукостер. Выписка билетов может занять
        до 6 часов.
      </DescriptionText>
    );
  }
  if (flightType === FLIGHT_TYPES.REGULAR) {
    return (
      <DescriptionBlock>
        <DescriptionText>
          Постоянное расписание, строгие обязательства, бонусные программы и
          доп. услуги.
        </DescriptionText>
        <DescriptionText>
          - выполняются крупными авиакомпаниями строго по расписанию;
        </DescriptionText>
        <DescriptionText>
          - билеты можно вернуть, если поменялись планы;
        </DescriptionText>
        <DescriptionText>
          - могут действовать скидки и бонусные программы.
        </DescriptionText>
      </DescriptionBlock>
    );
  }
};

const FlightTypeIcon: React.FC<FlightTypeIconProps> = (props) => {
  const { flightType, className } = props;
  const title = flightType ? getFlightTypeTitle(flightType, true) : '';
  const descriptionBlock = generateDescriptionBlock(flightType);

  const flightTypeBackground = (flightType: FlightType) => {
    switch (flightType) {
      case FLIGHT_TYPES.REGULAR:
        return '#E3EAFF';
      case FLIGHT_TYPES.LOWCOSTER:
        return '#EDFAF9';
      case FLIGHT_TYPES.CHARTER:
        return '#FFF3CD';
      default:
        return '#E3EAFF';
    }
  };

  if (!title) return null;

  return (
    <Tippy maxWidth={250} theme="light" content={descriptionBlock}>
      <IconContainer
        className={className}
        background={flightTypeBackground(flightType)}
      >
        <span>{title}</span>
      </IconContainer>
    </Tippy>
  );
};

export default FlightTypeIcon;
