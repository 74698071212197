import * as Yup from 'yup';
import { FormikValues } from 'formik';
import { parse, isValid } from 'date-fns';
import { getPassengerKeys } from './controller';

const dateValidation = () =>
  Yup.string()
    .required('Дата рождения обязательна')
    .test('is-not-empty', 'Дата рождения обязательна', (value) => {
      return value !== '__.__.____' && value !== ''; // Проверяем, что значение не имеет формат __.__.____
    })
    .test('is-not-future-date', 'Некорректная дата', (value) => {
      if (value === '__.__.____') return true;
      if (!value) return true; // Если значение пустое или имеет формат __.__.____, пропускаем проверку
      const parsedDate = parse(value, 'dd.MM.yyyy', new Date());
      return isValid(parsedDate) && parsedDate <= new Date(); // Проверяем, что дата не в будущем
    })
    .test('is-valid-date', 'Некорректная дата', (value) => {
      if (value === '__.__.____') return true;
      if (!value) return false; // Если значение пустое или имеет формат __.__.____, возвращаем false
      const parsedDate = parse(value, 'dd.MM.yyyy', new Date());
      return isValid(parsedDate);
    });

const issueDateValidation = (doctype: string) => {
  switch (doctype) {
    case 'BirthCertificate':
    case 'RussianPassport':
    case '':
      return Yup.string(); // Поле необязательно и не валидируется
    default:
      return Yup.string()
        .required('Дата обязательна')
        .test('is-not-empty', 'Дата обязательна', (value) => {
          return value !== '__.__.____' && value !== ''; // Проверяем, что значение не имеет формат __.__.____
        })
        .test('is-not-future-date', 'Некорректная дата', (value) => {
          if (value === '__.__.____') return true;
          if (!value) return true; // Если значение пустое или имеет формат __.__.____, пропускаем проверку
          const parsedDate = parse(value, 'dd.MM.yyyy', new Date());
          return isValid(parsedDate) && parsedDate <= new Date(); // Проверяем, что дата не в будущем
        })
        .test('is-valid-date', 'Некорректная дата', (value) => {
          if (value === '__.__.____') return true;
          if (!value) return false; // Если значение пустое или имеет формат __.__.____, возвращаем false
          const parsedDate = parse(value, 'dd.MM.yyyy', new Date());
          return isValid(parsedDate);
        });
  }
};

const nameValidation = (doctype: string, field: string) => {
  const latinRegex = /^[A-Za-z]+$/;
  const cyrillicRegex = /^[А-Яа-я]+$/;

  switch (doctype) {
    case 'RussianForeignPassport':
    case 'ForeignPassport':
    case 'DiplomaticPassport':
    case 'ServicePassport':
      return Yup.string()
        .matches(latinRegex, `${field} должно быть написано латиницей.`)
        .required(`${field} обязательно`);
    default:
      return Yup.string()
        .matches(cyrillicRegex, `${field} должно быть написано кириллицей.`)
        .required(`${field} обязательно`);
  }
};

const passportNumberValidation = (doctype: string) => {
  switch (doctype) {
    case 'ForeignPassport':
      return Yup.string()
        .matches(
          /^[A-Za-zА-Яа-я0-9-]{1,16}$/,
          'Неверный формат паспорта. Пример: 12-34-ZV',
        )
        .required('Номер документа обязателен');
    case 'ReturnToCisCertificate':
      return Yup.string()
        .matches(
          /^[0-9-]{1,10}$/,
          'Неверный формат свидетельства. Пример: 123-123',
        )
        .required('Номер документа обязателен');
    case 'DiplomaticPassport':
    case 'ServicePassport':
      return Yup.string()
        .matches(
          /^[A-Za-zА-Яа-я0-9-]{1,16}$/,
          'Неверный формат паспорта. Пример: BQ-123',
        )
        .required('Номер документа обязателен');
    case 'StatelessPersonIdentityCard':
    case 'ResidencePermit':
    case 'CertificateOfTemporaryAsylum':
    case 'RefugeeIdentity':
    case 'RefugeeCertificate':
    case 'AcknowledgmentOfIdentityOfAForeignCitizenOrStatelessPerson':
    case 'CertificateIssuedForAForeignCitizenGoingToDiplomaticOffice':
    case 'AnotherDocumentOfAForeignCitizenRecognizedInTheRussianFederation':
    case 'ConclusionOfIdentityEstablishment':
      return Yup.string()
        .matches(
          /^[A-Za-zА-Яа-я0-9-]{1,16}$/,
          'Неверный формат удостоверения. Пример: АБ-123',
        )
        .required('Номер документа обязателен');
    case 'RussianPassport':
      return Yup.string()
        .matches(/^\d{10}$/, 'Неверный формат паспорта РФ. Пример: 4501123456')
        .required('Номер документа обязателен');
    case 'BirthCertificate':
      return Yup.string()
        .matches(
          /^[IVXLCDM]+[А-Я]{2}\d{6}$/,
          'Неверный формат свидетельства о рождении. Пример: VIМА123456',
        )
        .required('Номер документа обязателен');
    case 'MilitaryID':
      return Yup.string()
        .matches(
          /^[А-Я]{2}\d{5,7}$/,
          'Неверный формат военного билета. Пример: АБ123456',
        )
        .required('Номер документа обязателен');
    case 'SeafarerIdentityDocument':
      return Yup.string()
        .matches(
          /^\d{7}$/,
          'Неверный формат удостоверения личности моряка. Пример: 1234567',
        )
        .required('Номер документа обязателен');
    case 'SailorPassport':
      return Yup.string()
        .matches(
          /^[A-Za-zА-Яа-я0-9-]{1,16}$/,
          'Неверный формат удостоверения личности военнослужащего. Пример: АБ-123',
        )
        .required('Номер документа обязателен');
    case 'USSRPassport':
      return Yup.string()
        .matches(
          /^[IVXLCDM]+[А-Я]{2}\d{6}$/,
          'Неверный формат паспорта СССР. Пример: VIМА123456',
        )
        .required('Номер документа обязателен');
    case 'MedicalBirthCertificate':
      return Yup.string()
        .matches(
          /^\d{6,12}$/,
          'Неверный формат медицинского свидетельства о рождении. Пример: 1234567',
        )
        .required('Номер документа обязателен');
    case 'PrisonReleaseCertificate':
    case 'ConvictIdentityDocument':
      return Yup.string()
        .matches(
          /^[A-Za-zА-Яа-я0-9-]{1,16}$/,
          'Неверный формат справки. Пример: АБ-123',
        )
        .required('Номер документа обязателен');
    default:
      return Yup.string().required('Номер документа обязателен');
  }
};

const getPassengerSchema = (doctype: string) => {
  return Yup.object().shape({
    surname: nameValidation(doctype, 'Фамилия'),
    name: nameValidation(doctype, 'Имя'),
    middleName: Yup.string(),
    dob: dateValidation(),
    gender: Yup.string().oneOf(['man', 'woman']).required('Пол обязателен'),
    citizenship: Yup.string().required('Гражданство обязательно'),
    doctype: Yup.string().required('Тип документа обязателен'),
    passportNumber: passportNumberValidation(doctype),
    issueDate: issueDateValidation(doctype),
  });
};

export const getValidationSchema = (values: FormikValues): any => {
  const keys = getPassengerKeys(values);
  const passengerSchemas: { [key: string]: Yup.ObjectSchema<any> } = {};

  keys.forEach((key) => {
    passengerSchemas[key] = getPassengerSchema(values[key].doctype);
  });

  return Yup.object().shape({
    ...passengerSchemas,
    contactPhone: Yup.string()
      .matches(/^\d+$/, 'Телефон должен содержать только цифры')
      .required('Телефон обязателен'),
    contactEmail: Yup.string()
      .email('Некорректный email')
      .required('Email обязателен'),
    contactName: Yup.string().required('Имя обязательно'),
    covid: Yup.array().min(1).required(),
    offerta: Yup.array().min(1).required(),
  });
};
