import {
  ITrainSearchState,
  TrainSearchRequestStatus,
} from '@modules/trainTickets/types';

export const TRAIN_SEARCH_STEPS = {
  BOOKING: 'BOOKING',
  TICKET_BACKWARD: 'TICKET_BACKWARD',
  TICKET_FORWARD: 'TICKET_FORWARD',
};

export const TRAINS_SEARCH_SORT_BY = [
  {
    label: 'По времени отправления',
    value: 'TIME_DEPARTURE',
  },
  {
    label: 'По времени прибытия',
    value: 'TIME_ARRIVAL',
  },
  {
    label: 'По времени в пути',
    value: 'TIME',
  },
  {
    label: 'Сначала дешёвые',
    value: 'PRICE',
  },
];

export const TrainSearchState: ITrainSearchState = {
  carSchemeStatus: null,
  carsStatus: null,
  currentSearchStep: 'TICKET_FORWARD',
  filterIsOpen: false,
  filter: [],
  sortBy: 'TIME_DEPARTURE',
  status: TrainSearchRequestStatus.initial,
  passengers: [1, 0, 0],
};

/** Справочник типов вагонов */
export const CAR_TYPES = {
  Baggage: 'Baggage', // Багажный
  Compartment: 'Compartment', // Купе
  Luxury: 'Luxury', // СВ!
  ReservedSeat: 'ReservedSeat', // Плацкарт
  Sedentary: 'Sedentary', // Сидячий
  Shared: 'Shared', // Общий
  Soft: 'Soft', // Люкс!
  Unknown: 'Unknown', // Не определён
};

/** Справочник названий типов вагонов */
export const CAR_TYPES_TITLES = {
  Baggage: 'Багажный',
  Compartment: 'Купе',
  Luxury: 'СВ',
  ReservedSeat: 'Плацкарт',
  Sedentary: 'Сидячий',
  Shared: 'Общий',
  Soft: 'Люкс',
  Unknown: 'Не определён',
};

export const CAR_SEAT_TYPES = {
  NoValue: 'Тип места не определён', // Такое может быть, например, в случае вагонов с выкупом купе целиком
  SeparateCompartment: 'Отдельный отсек', // сидячие в поездах Ласточка и Стриж
  SideLowerNearRestroom: 'Боковое нижнее у туалета', // плацкарт,
  SideUpperNearRestroom: 'Боковое верхнее у туалета', // плацкарт
  ThirdShelf: 'Третья полка',
  Foldable: 'Откидное или неудобное',
  Usual: 'Обычное не у стола (сидячие)',
  LastKupeLower: 'Последнее купе, нижние с повышенным уровнем шума',
  LastKupeUpper: 'Последнее купе, верхние с повышенным уровнем шума',
  Middle: 'Среднее', // в спальных
  WithBicycle: 'С велосипедом (сидячие)',
  NearPlayground: 'Рядом с детской площадкой', // сидячие в поезде Аллегро
  NearTablePlayground: 'У стола рядом с детской площадкой', // сидячие в поезде Аллегро
  NearTable: 'У стола (сидячие)',
  WithPets: 'C животными', // сидячие в поезде Сапсан
  MotherAndBaby: 'Место матери и ребенка до 1 года', // сидячие в поезде Сапсан
  WithChild: 'Место для пассажиров с детьми до 10 лет', // сидячие в поезде Сапсан
  NearPassengersWithPets: 'Рядом с местами для пассажиров с животными', // Сидячее в поезде Аллегро
  Invalids: 'Для инвалидов',
  InvalidsLower: 'Для инвалидов (нижнее)',
  InvalidsUpper: 'Для инвалидов (верхнее)',
  Negotiations: 'Переговорная', // (сидячие в поездах Сапсан и Аллегро)
  NearTableForward: 'У стола, по ходу', // (сидячие в поездах Сапсан, Ласточка)
  NearTableBackward: 'У стола, против хода', // (сидячие в поездах Сапсан, Ласточка)
  NoTableForward: 'Не у стола, по ходу', // (сидячие в поездах Сапсан, Ласточка)
  NoTableBackward: 'Не у стола, против хода', // (сидячие в поездах Сапсан, Ласточка)
  NoWindowForward: 'Без окна, по ходу', // (сидячие в поездах Сапсан, Ласточка)
  NoWindowBackward: 'Без окна, против хода', // (сидячие в поездах Сапсан, Ласточка)
  SingleForward: 'Одиночное, по ходу', // (сидячие в поезде Сапсан)
  NearRestroom: 'У туалета', // (сидячие в поезде Ласточка)
  FoldableNearRestroom: 'Откидное у туалета', // (сидячие в поезде Ласточка)
  NearRestroomAndBackward: 'У туалета против хода', // (сидячие в поезде Ласточка)
  NearRestroomAndForward: 'У туалета по ходу', // (сидячие в поезде Ласточка)
  NoTableAndNoWindow: 'Не у стола, без окна', // (сидячие в поезде Ласточка)
  Side: 'Боковое', // (сидячие в поезде Ласточка)
  WithoutPlace: 'Без места',
  WithHigherLevelOfNoise: 'С повышенным уровнем шума',
  FoldableOrWithHigherLevelOfNoise: 'Откидное или с повышенным уровнем шума',
  SideLowerWithHigherLevelOfNoise: 'Нижнее боковое с повышенным уровнем шума',
  SideUpperWithHigherLevelOfNoise: 'Верхнее боковое с повышенным уровнем шума',
  AisleSeat: 'Место у прохода',
  AisleSeatWithHigherLevelOfNoise: 'Место у прохода с повышенным уровнем шума',
  UpperInTwoPlaceCompartment: 'Верхнее в 2-х местном купе',
  LowerInTwoPlaceCompartment: 'Нижнее в 2-х местном купе',
  LastCompartmentLowerWithHigherLevelOfNoise:
    'Последнее купе, нижнее с повышенным уровнем шума',
  LastCompartmentUpperWithHigherLevelOfNoise:
    'Последнее купе, верхнее с повышенным уровнем шума',
  Lower: 'Нижнее',
  SideLower: 'Нижнее боковое',
  SideUpper: 'Верхнее боковое',
  Upper: 'Верхнее',
};

/** Справочник услуг вагона
 * Услуги, начинающиеся на F_, высчитываются из других данных поезда  */
export const RAILWAY_CAR_SERVICES = {
  AirConditioning: 'Кондиционер',
  Bedclothes: 'Постельное бельё',
  BioToilet: 'Биотуалет',
  ExcursionService: 'Экскурсионное обслуживание',
  HighComfortWaitingRoomServices: 'Услуги залов повышенной комфортности',
  HotDrink: 'Горячие напитки',
  HygienicKit: 'Гигиенический набор',
  HygienicShower: 'Гигиенический душ', // нет в фигме
  ImprovedServices: 'Вагон с улучшенными характеристиками', // нет в фигме
  InfotainmentService: 'Мультимедийный портал «Попутчик»',
  Iron: 'Утюг / гладильная доска', // в api разные услуги Iron / IroningBoard
  LuggageCompartment: 'Купе для багажа', // это просто багаж?
  Meal: 'Набор питания',
  MediaPlayer: 'Медиапроигрыватель', // нет в фигме
  PanoramicSalon: 'Панорамный салон', // нет в фигме
  PetsCarriage: 'Перевозка домашний питомцев',
  PlacesForPassengerWithBaby: 'Для пассажиров с детьми',
  Plaid: 'Плед', // нет в фигме
  Press: 'Пресса',
  RestaurantCarOrBuffet: 'Вагон-ресторан',
  Safe: 'Сейф',
  ShowerRoomInCompartment: 'Душевая',
  ShowerRoomInTrain: 'Душевая в поезде', // нет в фигме, но можно использовать обычную иконку душа?
  Slippers: 'Тапочки', // нет в фигме
  Socket220V: 'Розетка',
  Transfer: 'Трансфер', // нет в фигме
  Tv: 'Телевизор',
  UsbSocket: 'USB розетка',
  WashbasinInCompartment: 'Умывальник',
  Wifi: 'WiFi',
  baggage: 'Багаж', // такого нет в api
  bar: 'Напитки и бар', // такого нет в api
  conductor: 'Проводник', // такого нет в api
  conferenceRoom: 'Купе-переговорная', // такого нет в api
  exit: 'Выход', // такого нет в api
  forDisabledPerson: 'Места для людей с физическими ограничениями', // такого нет в api
  F_Gender: 'Возможность выбора пола в купе', // такого нет в api
  F_RefundTicket: 'Есть места по невозвратному тарифу', // такого нет в api
  motherRoom: 'Комната матери и ребенка', // такого нет в api
  nextToPlayground: 'Рядом с детской площадкой', // такого нет в api
  noPets: 'Без животных', // такого нет в api
  noRefund: 'Невозвратный билет', // такого нет в api
  petsSpecialSpace: 'Перевозка животных (спец. места)', // нет в api
  returnTicket: 'Возвратный билет', // такого нет в api
  serviceSpace: 'Сервисное помещение', // такого нет в api
  wardrobe: 'Гардероб', // такого нет в api
};

export const CAR_PLACE_GENDER_TYPES = {
  canChose: 'Можно задать тип купе',
  female: 'Женское',
  male: 'Мужское',
  mixed: 'Смешанное',
};

export const CAR_PLACE_GENDER_TYPES_SHORT = {
  canChose: 'В',
  female: 'Ж',
  male: 'М',
  mixed: 'С',
};

export const RAILWAY_RF_ID = 177;

export enum CarFloor {
  LOWER = 0,
  UPPER = 1,
}