import { createAction, createReducer } from 'redux-act';
import { combineReducers } from 'redux';
import {
  FlightSearchRequestStatus,
  ResponseError,
  FlightSearchDTO,
  TimeTable,
  ListState,
  PromotionsDto,
} from './types';
import { FlightSearchState, FlightSearchIntervalsState } from './constants';
import { sortIntervals } from './utilities';

/** Flight Search **/

/** Actions **/
export const setFlightSearchRequestState = createAction<{
  status: FlightSearchRequestStatus;
  errors?: ResponseError[] | null;
}>('@@flightSearch/flightSearchRequestStatus');

export const setFlightSearchData = createAction<FlightSearchDTO>(
  '@@flightSearch/setFlightSearchData',
);

export const purifySearchState = createAction('@@flightSearch/purify');

// Filter actions
export const setTimeOneWayFilter = createAction<{
  index: number;
  values: number[];
}>('@@filter/setTimeOneWayFilter');

export const setTransferDurationFilter = createAction<number[]>(
  '@@filter/setTransferDurationFilter',
);

export const setTransferFilter = createAction<{ [value: string]: boolean }>(
  '@filter/setTransferFilter',
);
export const setPricesFilter = createAction<number[]>(
  '@@filter/setTransferPricesFilter',
);

export const setFlightTypeFilter = createAction<string[]>(
  '@@filter/setFlightTypeFilter',
);
export const setAirportFilter = createAction<{ [value: string]: boolean }[]>(
  '@filter/setAirportFilter',
);

export const setConnectionFilter = createAction<boolean>(
  '@filter/setConnectionFilter',
);
export const setAirlineFilter = createAction<{ [value: string]: boolean }>(
  '@filter/setAirlineFilter',
);
export const setAirGdsFilter = createAction<{ [value: string]: boolean }>(
  '@filter/setAirGdsFilter',
);
export const setTimeFilter = createAction<{
  index: number;
  type: 'from' | 'to';
  values: number[];
}>('@filter/setTimeFilter');

export const setBaggageFilter = createAction<{ [value: string]: boolean }>(
  '@filter/setBaggageFilter',
);

export const resetFilter = createAction<null | string>('@@filter/reset');

export const runFilter = createAction('@@filter/run');

export const getNext = createAction('@@flightSearch/getNext');

export const setPluginToken = createAction<string>('@@plugin/setPluginToken');

export const setList = createAction<ListState>('@@flightSearch/setList');
export const purifyList = createAction('@@flightSearch/purifyList');

// promotions
export const promotionsRequest = createAction('@main/promotionsRequest');
export const promotionsSuccess = createAction<PromotionsDto>(
  '@main/promotionsRequest',
);
export const promotionsFailure = createAction('@main/promotionsRequest');
const promotions = createReducer<PromotionsDto>({}, { data: [] });
promotions.on(promotionsSuccess, (s, p) => p);
promotions.on(promotionsFailure, () => ({ data: [] }));

export const flightSearch = createReducer({}, FlightSearchState);

const list = createReducer<ListState>(
  {},
  { items: [], visibleItems: [], page: 1, pageCount: 1 },
);
list.on(setList, (_, payload) => payload);

flightSearch.on(purifySearchState, () => FlightSearchState);
flightSearch.on(setFlightSearchRequestState, (state, payload) => {
  return { ...state, ...payload };
});
flightSearch.on(setFlightSearchData, (state, payload) => {
  return { ...state, ...payload, status: FlightSearchRequestStatus.success };
});
flightSearch.on(setBaggageFilter, (state, payload) => {
  const isDefault = Object.values(payload).some((x) => x === true);

  Object.entries(payload).forEach(([key, val]) => {
    state.filter.baggage[parseInt(key)].checked = val;
  });
  state.filter.isDefault.baggage = !isDefault;
  return { ...state };
});
flightSearch.on(setFlightTypeFilter, (state, payload) => {
  const isDefault = payload.length === 0 ? true : false;
  state.filter.values.flightTypes = [...payload];
  state.filter.isDefault.flightTypes = isDefault;

  return { ...state };
});
flightSearch.on(setTimeFilter, (state, payload) => {
  state.filter.time[payload.index][payload.type].values = payload.values;

  const isDefault = state.filter.time.reduce((prev, next) => {
    return (
      prev &&
      next.from.min === next.from.values[0] &&
      next.from.max === next.from.values[1] &&
      next.to.min === next.to.values[0] &&
      next.to.max === next.to.values[1]
    );
  }, true);

  return {
    ...state,
    filter: {
      ...state.filter,
      isDefault: {
        ...state.filter.isDefault,
        time: isDefault,
      },
    },
  };
});

flightSearch.on(setAirlineFilter, (state, payload) => {
  const isFiltered = Object.values(payload).some((x) => x === true);

  return {
    ...state,
    filter: {
      ...state.filter,
      isDefault: {
        ...state.filter.isDefault,
        airlines: !isFiltered,
      },
      values: {
        ...state.filter.values,
        airlines: payload,
      },
    },
  };
});

flightSearch.on(setAirGdsFilter, (state, payload) => {
  const isFiltered = Object.values(payload).some((x) => x === true);

  return {
    ...state,
    filter: {
      ...state.filter,
      isDefault: {
        ...state.filter.isDefault,
        airGds: !isFiltered,
      },
      values: {
        ...state.filter.values,
        airGdsValues: payload,
      },
    },
  };
});

flightSearch.on(setTimeOneWayFilter, (state, payload) => {
  const vals = state.filter.flightsDurationsList;
  state.filter.values.flightsDuration[payload.index] = payload.values;

  const isDefault = state.filter.values.flightsDuration.reduce(
    (prev, current, index) =>
      prev && current[0] === vals[index].min && current[1] === vals[index].max,
    true,
  );

  return {
    ...state,
    filter: {
      ...state.filter,
      values: {
        ...state.filter.values,
        flightsDuration: [...state.filter.values.flightsDuration],
      },
      isDefault: { ...state.filter.isDefault, flightDuration: isDefault },
    },
  };
});

flightSearch.on(setTransferDurationFilter, (state, payload) => {
  const isDefault =
    payload[0] === state.filter.transferDuration.min &&
    payload[1] === state.filter.transferDuration.max;

  return {
    ...state,
    filter: {
      ...state.filter,
      isDefault: { ...state.filter.isDefault, transferDuration: isDefault },
      values: {
        ...state.filter.values,
        transferDuration: [...payload],
      },
    },
  };
});

flightSearch.on(setTransferFilter, (state, payload) => {
  const isDefault =
    Object.entries(payload).find(([_, val]) => val === true) === undefined;

  return {
    ...state,
    filter: {
      ...state.filter,
      transfers: {
        ...state.filter.transfers,
        list: state.filter.transfers.list.map((el) => {
          return { ...el, checked: payload[el.val] };
        }),
      },
      values: {
        ...state.filter.values,
        transfer: Object.entries(payload)
          .filter(([_, val]) => val)
          .map(([key]) => parseInt(key)),
      },
      isDefault: {
        ...state.filter.isDefault,
        transfers: isDefault,
      },
    },
  };
});

flightSearch.on(setPricesFilter, (state, payload) => {
  const isDefault =
    state.filter.prices.max === payload[1] &&
    state.filter.prices.min === payload[0];
  return {
    ...state,
    filter: {
      ...state.filter,
      isDefault: {
        ...state.filter.isDefault,
        prices: isDefault,
      },
      values: {
        ...state.filter.values,
        prices: [...payload],
      },
    },
  };
});

flightSearch.on(setAirportFilter, (state, payload) => {
  const isDefault = payload
    .map((x) => Object.values(x).some((y) => y === true))
    .some((x) => x === true);

  const airports = state.filter.airports.map((el, key) => {
    return {
      ...el,
      airports: el.airports.map((airport) => {
        return { ...airport, checked: payload[key][airport.code] };
      }),
    };
  });

  return {
    ...state,
    filter: {
      ...state.filter,
      airports: airports,
      isDefault: { ...state.filter.isDefault, airport: !isDefault },
    },
  };
});

flightSearch.on(setConnectionFilter, (state, payload) => {
  const isDefault = true;

  if (payload === true) {
    state.filter.isDefault.connections = !isDefault;
  } else {
    state.filter.isDefault.connections = isDefault;
  }
  return { ...state };
});

flightSearch.on(resetFilter, (state, payload) => {
  if (payload === 'airport') {
    state.filter.isDefault.airport = true;
    const isFiltered =
      Object.entries(state.filter.isDefault).find(([_, val]) => val) !==
      undefined;

    return {
      ...state,
      filter: {
        ...state.filter,
        isFiltered,
        isDefault: { ...state.filter.isDefault },

        airports: state.filter.airports.map((el) => {
          return {
            ...el,
            airports: el.airports.map((airport) => {
              return { ...airport, checked: false };
            }),
          };
        }),
      },
    };
  } else if (payload === 'airlines') {
    state.filter.isDefault.airlines = true;

    const isFiltered =
      Object.entries(state.filter.isDefault).find(([_, val]) => val) !==
      undefined;
    Object.entries(state.filter.values.airlines).forEach(([key, val]) => {
      state.filter.values.airlines[key] = false;
    });

    return {
      ...state,
      filter: {
        ...state.filter,
        isFiltered,
        values: { ...state.filter.values },
        isDefault: { ...state.filter.isDefault },
      },
    };
  } else if (payload === 'transfers') {
    state.filter.isDefault.transfers = true;

    state.filter.values.transfer = [];

    state.filter.transfers = {
      ...state.filter.transfers,

      list: state.filter.transfers.list.map((el) => {
        return { ...el, checked: false };
      }),
    };

    return { ...state };
  } else if (payload === 'prices') {
    state.filter.isDefault.prices = true;
    state.filter.values.prices = [
      state.filter.prices.min,
      state.filter.prices.max,
    ];

    return { ...state };
  } else if (payload === 'flightDuration') {
    state.filter.isDefault.flightDuration = true;
    state.filter.values.flightsDuration = state.filter.flightsDurationsList.map(
      (el) => [el.min, el.max],
    );

    return { ...state };
  } else if (payload === 'time') {
    state.filter.isDefault.time = true;
    state.filter.time = state.filter.time.map((group) => {
      return {
        ...group,
        from: { ...group.from, values: [group.from.min, group.from.max] },
        to: { ...group.to, values: [group.to.min, group.to.max] },
      };
    });

    return { ...state };
  } else if (payload === 'transferDuration') {
    state.filter.isDefault.transferDuration = true;
    state.filter.values.transferDuration = [
      state.filter.transferDuration.min,
      state.filter.transferDuration.max,
    ];

    return { ...state };
  } else if (payload === 'flightTypes') {
    state.filter.isDefault.flightTypes = true;
    state.filter.values.flightTypes = [];

    return { ...state };
  } else if (payload === 'baggage') {
    state.filter.isDefault.baggage = true;
    state.filter.baggage = state.filter.baggage.map((f) => {
      return { ...f, checked: false };
    });

    return { ...state };
  } else if (payload === 'airGds') {
    state.filter.isDefault.airGds = true;
    state.filter.values.airGdsValues = Object.entries(
      state.filter.values.airGdsValues,
    ).reduce((acc: Record<string, boolean>, [key, val]) => {
      acc[key] = false;
      return acc;
    }, {});

    return { ...state };
  } else {
    Object.entries(state.filter.values.airlines).forEach(([key, val]) => {
      state.filter.values.airlines[key] = false;
    });
    Object.entries(state.filter.values.airGdsValues).forEach(([key, val]) => {
      state.filter.values.airGdsValues[key] = false;
    });

    state.filter.values = {
      ...state.filter.values,
      prices: [state.filter.prices.min, state.filter.prices.max],
      flightsDuration: state.filter.flightsDurationsList.map((el) => [
        el.min,
        el.max,
      ]),
      flightTypes: [],
      transferDuration: [
        state.filter.transferDuration.min,
        state.filter.transferDuration.max,
      ],
      transfer: [],
    };

    Object.keys(state.filter.isDefault).forEach((key) => {
      state.filter.isDefault[key as 'airport'] = true;
    });

    return {
      ...state,
      filter: {
        ...state.filter,
        isFiltered: false,
        isDefault: { ...state.filter.isDefault },
        transfers: {
          ...state.filter.transfers,
          list: state.filter.transfers.list.map((el) => {
            return { ...el, checked: false };
          }),
        },

        baggage: state.filter.baggage.map((f) => {
          return { ...f, checked: false };
        }),

        time: state.filter.time.map((group) => {
          return {
            ...group,
            from: { ...group.from, values: [group.from.min, group.from.max] },
            to: { ...group.to, values: [group.to.min, group.to.max] },
          };
        }),

        airports: state.filter.airports.map((el) => {
          return {
            ...el,
            airports: el.airports.map((airport) => {
              return { ...airport, checked: false };
            }),
          };
        }),
      },
    };
  }
});

flightSearch.on(runFilter, (state) => {
  const {
    airports,
    airGds,
    airlinesTickets,
    transfers,
    time,
    connections,
    values: {
      prices,
      transferDuration,
      transfer,
      airlines,
      flightTypes,
      airGdsValues,
    },
    ...arrs
  } = state.filter;

  const connectionDefault = state.filter.isDefault.connections;

  const isFiltered =
    Object.entries(state.filter.isDefault).find(([_, val]) => val) !==
    undefined;

  const isBaggageDefault = state.filter.baggage.reduce(
    (acc, x) => acc && x.checked === false,
    true,
  );

  const checkedBaggageType = state.filter.baggage.find(
    (item) => item.checked,
  ) || { checked: true, label: '' };

  const itemBaggageFiltration = (key: number): boolean => {
    const item = state.filter.baggageFilter[key];

    // if (isBaggageDefault) {
    //   return true;
    // }
    if (item === null) {
      return true;
    } else {
      if (isBaggageDefault) {
        return true;
      }
      if (checkedBaggageType.label === 'C багажом' && item === 'yes') {
        return true;
      } else if (checkedBaggageType.label === 'Без багажа' && item === 'no') {
        return true;
      } else if (
        checkedBaggageType.label === 'Смешанный багаж' &&
        item === 'mix'
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const connectionsFiltration = (
    key: number,
    connectionDefault: boolean,
  ): boolean => {
    const item = state.filter.connections[key];

    // if (isBaggageDefault) {
    //   return true;
    // }
    if (item === null) {
      return true;
    } else {
      if (item === true && !connectionDefault) {
        return false;
      } else if (item === true && connectionDefault) {
        return true;
      } else if (item === false) {
        return true;
      } else {
        return true;
      }
    }
  };

  let keys = state.filter.flightsDurationsList[0].tickets
    .map((_, key) => key)
    .filter((key) => {
      // flightType
      const flightTypeFilter =
        flightTypes.length > 0
          ? flightTypes.includes(arrs.flightTypes[key])
          : true;

      //prices
      const pricesFilter =
        arrs.prices.tickets[key] >= prices[0] &&
        arrs.prices.tickets[key] <= prices[1];

      //transferDuration
      const transferDurationFilter =
        arrs.transferDuration.tickets[key] >= transferDuration[0] &&
        arrs.transferDuration.tickets[key] <= transferDuration[1];

      //airlines
      const airlinesFilter = Object.values(airlines).find((x) => x)
        ? airlinesTickets[key].some((val) => val.some((v) => airlines[v]))
        : true;

      // airGds
      let airGdsFilter = false;
      if (arrs.isDefault.airGds) {
        airGdsFilter = true;
      } else {
        airGdsFilter = airGdsValues[airGds[key]];
      }

      //transfer
      const transferFilter =
        transfer.length > 0
          ? transfer.find((val) => transfers.tickets[key] === val) !== undefined
          : true;

      const firstExpression =
        pricesFilter &&
        transferDurationFilter &&
        flightTypeFilter &&
        itemBaggageFiltration(key) &&
        connectionsFiltration(key, connectionDefault) &&
        airlinesFilter &&
        transferFilter &&
        airGdsFilter;

      if (firstExpression) {
        let secondExpression = true;

        time.forEach((group) => {
          const { from, to } = group;
          secondExpression =
            secondExpression &&
            from.values[0] <= from.tickets[key] &&
            from.values[1] >= from.tickets[key] &&
            to.values[0] <= to.tickets[key] &&
            to.values[1] >= to.tickets[key];
        });

        return firstExpression && secondExpression;
      } else {
        return false;
      }
    });

  state.filter.flightsDurationsList.forEach((flight, key) => {
    const values = state.filter.values.flightsDuration[key];

    keys = flight.tickets
      .map((val, key) => {
        return { val, key };
      })
      .filter((item, key) => {
        return (
          keys.find((k) => k === key) !== undefined &&
          item.val >= values[0] &&
          item.val <= values[1]
        );
      })
      .map(({ key }) => key);
  });

  airports.forEach((el) => {
    if (Object.values(el.airports).find((x) => x.checked)) {
      keys = el.tickets
        .map((val, key) => {
          return { val, key };
        })
        .filter(({ val }, key) => {
          return (
            keys.find((k) => k === key) !== undefined &&
            el.airports.find(({ code, checked }) => code === val && checked)
          );
        })
        .map(({ key }) => key);
    }
  });

  // if (state.filter.isDefault.connections === false) {
  //   keys = state.filter.connections.find((x) => x === false)
  // }

  return {
    ...state,
    flightsList: {
      ...state.flightsList,
      items: state.flightsList.notFilteredItems.filter(
        (_, key) => keys.find((k) => k === key) !== undefined,
      ),
    },
    filter: {
      ...state.filter,
      isFiltered,
      isDefault: { ...state.filter.isDefault },
    },
  };
});

/** Flight search intervals */

export const setFlightSearchIntervals = createAction<{
  data: TimeTable;
  selected: string;
}>('@@timeIntervals/setFlightSearchIntervals');
export const addToFlightSearchIntervals = createAction<{ data: TimeTable }>(
  '@@timeIntervals/addToFlightSearchIntervals',
);
export const selectFlightSearchInterval = createAction<string>(
  '@@timeIntervals/selectFlightSearchIntervals',
);
export const loadFlightSearchDataByInterval = createAction(
  '@@timeIntervals/loadFlightSearchDataByInterval',
);

export const flightSearchIntervals = createReducer(
  {},
  FlightSearchIntervalsState,
);

flightSearchIntervals.on(setFlightSearchIntervals, (state, payload) => {
  state.list = {
    items: {},
    length: 0,
  };

  payload.data?.forEach((day, key) => {
    const index =
      day.values.length === 1
        ? 0
        : key > day.values.length - 1
          ? day.values.length - 1
          : key;

    state.list.items[day.operationalDay] = {
      from: day.operationalDay,
      to: day.values[index].operationalDay,
      price: day.values[index].price,
    };
    state.list.length++;
  });
  state.selected = payload.selected;
  return { ...state };
});

flightSearchIntervals.on(addToFlightSearchIntervals, (state, payload) => {
  payload.data?.forEach((day, key) => {
    if (state.list.items[day.operationalDay]) {
      state.list.length++;
    }
    const index =
      day.values.length === 1
        ? 0
        : key > day.values.length - 1
          ? day.values.length - 1
          : key;
    state.list.items[day.operationalDay] = {
      from: day.operationalDay,
      to: day.values[index].operationalDay,
      price: day.values[index].price,
    };
  });
  return {
    ...state,
    list: { ...state.list, items: sortIntervals(state.list.items) },
  };
});

flightSearchIntervals.on(selectFlightSearchInterval, (state, payload) => {
  return { ...state, selected: payload };
});

export const connectionFilter = createReducer<boolean>({}, false);

connectionFilter.on(setConnectionFilter, (_, payload) => payload);

export const plugintoken = createReducer<string>({}, '');

plugintoken.on(setPluginToken, (_, payload) => payload);

/** Combine Reducers */
export const mainReducer = combineReducers({
  flightSearch,
  flightSearchIntervals,
  ticketList: list,
  promotions,
  connectionFilter,
  plugintoken,
});
