import React from 'react';
import { FlightListProps } from '../types';
import styled from 'styled-components';
import FlightItem from '@components/flightsSearch/TicketList/Flight/FlightItem';

export const ListContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  & > div {
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 14px;
    margin-bottom: 14px;
  }
  & > div:last-of-type {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0;
  }
  @media (max-width: 767px) {
    padding: 0 14px;
    margin-bottom: 12px;
  }
`;

const List: React.FC<FlightListProps> = ({
  items,
  className,
  active,
  airlinesInfo,
  hasConnectingFlights,
}) => {
  const itemsComponents = items.map((item, key, arr) => {
    return (
      <FlightItem
        flightIndex={key}
        multy={arr.length > 1}
        active={active}
        key={key}
        airlinesInfo={airlinesInfo}
        hasConnectingFlights={hasConnectingFlights}
        {...item}
      />
    );
  });
  return <ListContainer className={className}>{itemsComponents}</ListContainer>;
};

export default List;
