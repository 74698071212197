export const TTE_TEST_LOGINS = [
  'ballista9000@gmail.com',
  'mixa.ribakov@gmail.com',
  'mikhail.rybakov@fstravel.com',
  'yuliya.burlakova@fstravel.com',
  'olga.rozova@fstravel.com',
];

export const TTE_CACHE_USER = 'cachetest@test.ru';
export const TTE_CACHE_USER_B2C = 'fs-test@inbox.ru';
export const TTE_CACHE_USER_B2C_TEST = 'jelientos@rambler.ru';
export const TTE_KZ_USER = 'gdskz@test.ru';
