import React from 'react';
import { SegmentProps } from './types';
import { cityIn } from 'lvovich';
import styled from 'styled-components';
import { FlightSegmentProps, Stop } from '@components/common/FlightSegment';
import { Helper, Hooks } from '@utils';
import { Text as BaseText } from '@components/ui';
import AviaSegmentHeader from '@components/common/FlightSegment/AviaSegmentHeader';
import AviaMainSegment from '@components/common/FlightSegment/AviaMainSegment';
import moment from 'moment/moment';
import AviaAdditionalInfo from '@components/common/FlightSegment/AviaAdditionalInfo';
import StopSeparator from '@components/common/FlightSegment/StopSeparator';
import { StopEntity } from '@modules/booking';
import Chip from '@components/ui/Chip/Chip';
import infoIconBlack from '@assets/media/flight-search/infoIconBlack.svg';
import HasConnectionsFlightsModal from '@app/components/common/Modals/HasConnectingFlightsModal';
import { useSelector } from 'react-redux';
import { ApplicationState } from '@app/modules';
import FlightTypeIconList from '@components/common/FlightTypeIconList';

const Container = styled.div`
  @media (max-width: 1024px) {
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`;
const Content = styled.div`
  & > div {
    margin-bottom: 16px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const StyledFlightTypeIconList = styled(FlightTypeIconList)`
  margin-bottom: 17px;
  width: min-content;
`;

const FromToText = styled(BaseText)`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #737373;
  display: block;
  margin-top: 15px;
  margin-bottom: 10px;
`;

const AdditionalIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const ChipContainer = styled.div`
  margin-top: 10px;
  cursor: pointer;
  max-width: 190px;
`;

const SegmentInBooking: React.FC<SegmentProps> = ({
  transfer,
  baggageDescr,
  hasConnectingFlights,
  allFlightTypes,
  flightIndex,
  ...props
}) => {
  const selectedFare = useSelector(
    (state: ApplicationState) => state.booking.fares?.selected,
  );
  const [isRepeatRegistrationModalOpen, setIsRepeatRegistrationModalOpen] =
    React.useState(false);
  const { isLaptopOrBigTablet } = Hooks.useMediaSizes();
  const detailProps: FlightSegmentProps = {
    from: {
      ...props.from,
      date: `${props.fromDate} ${props.fromTime}`,
      city: { name: props.from.city, code: '' },
      airport: { name: props.from.airport, code: props.from.code },
    },
    to: {
      ...props.to,
      date: `${props.toDate} ${props.toTime}`,
      city: { name: props.to.city, code: '' },
      airport: { name: props.to.airport, code: props.to.code },
    },
    duration: props.duration,
    airlines: props.active
      ? [
          {
            name: props.operatingAirline,
            code: props.operatingAirlineCode,
          },
        ]
      : props.allAirlines,
    active: props.active,
    flightNo: `${props.operatingAirlineCode} ${props.flightNo}`,
    stops: transfer
      ? [
          {
            duration: transfer.duration,
            time: transfer.time,
            city: {
              ...transfer.city,
              terminal: '',
            },
          },
        ]
      : [],
    company: props.operatingAirlineCode,
  };

  const terminals: string[] = [];
  if (props.from.terminal) {
    terminals.push(`вылет – ${props.from.terminal}`);
  }
  if (props.to.terminal) {
    terminals.push(`прилёт – ${props.to.terminal}`);
  }

  const fromDate = moment(`${props.fromDate} ${props.fromTime}`);
  const toDate = moment(`${props.toDate} ${props.toTime}`);

  const hasTechLanding = props.techLandings && props.techLandings.length > 0;
  const hasAdditionalInfo = hasTechLanding;
  const stopTitle =
    detailProps.stops && detailProps.stops[0] && detailProps.stops[0]
      ? `пересадка в ${cityIn(detailProps.stops[0]?.city?.city)} ${Helper.formatDuration(detailProps.stops[0]?.duration)}`
      : '';
  const techLandingsTitle = hasTechLanding
    ? `тех. остановка в ${props.techLandings[0]?.city?.airport} ${props.techLandings[0]?.city?.city} ${Helper.formatDuration(props.techLandings[0]?.duration)}`
    : '';
  return isLaptopOrBigTablet ? (
    <div>
      <Container>
        <AviaSegmentHeader
          fromCityName={props.from.city}
          toCityName={props.to.city}
          airlinesInfo={detailProps.airlines}
          flight_Type={props.flightType}
          price={123}
          features={[]}
        />
        {props.fromTo && (
          <StyledFlightTypeIconList
            flightTypes={allFlightTypes || [props.flightType]}
          />
        )}
        {props.fromTo && props.active && (
          <FromToText>{props.fromTo}</FromToText>
        )}
        <AviaMainSegment
          airlines={[
            {
              name: props.airline,
              code: props.airlineCode,
              operatingAirlineCode: props.operatingAirlineCode,
              operatingAirlineName: props.operatingAirline,
              logoUrl: null,
            },
          ]}
          duration={detailProps.duration}
          flightNumbers={[detailProps.flightNo]}
          from={props.from}
          fromDate={fromDate}
          stops={
            !props.active && transfer ? convertStopEntityToStop([transfer]) : []
          }
          techLanding={props.techLandings}
          to={props.to}
          toDate={toDate}
          baggageDescr={baggageDescr}
          flightIndex={flightIndex}
        />
        {(hasConnectingFlights ||
          (selectedFare && selectedFare.hasConnectingFlights)) && (
          <ChipContainer onClick={() => setIsRepeatRegistrationModalOpen(true)}>
            <Chip
              icon={<AdditionalIcon src={infoIconBlack} />}
              bgColor={'blue'}
              label={'Повторная регистрация'}
              adaptiveTextColor
            />{' '}
          </ChipContainer>
        )}
        <AviaAdditionalInfo
          hasTechLanding={hasTechLanding}
          techLandingsTitle={techLandingsTitle}
          fromTerminal={props.from.terminal}
          toTerminal={props.to.terminal}
          airlineName={props.operatingAirline}
          hasAdditionalInfo={hasAdditionalInfo}
        />
        {detailProps.stops?.length > 0 && (
          <StopSeparator type={'normal'} title={stopTitle} />
        )}
        {hasTechLanding && (
          <StopSeparator type={'technical'} title={techLandingsTitle} />
        )}
      </Container>
    </div>
  ) : (
    <Container>
      {isRepeatRegistrationModalOpen && (
        <HasConnectionsFlightsModal
          isModalOpen={isRepeatRegistrationModalOpen}
          setIsModalOpen={setIsRepeatRegistrationModalOpen}
        />
      )}
      <Content>
        <AviaSegmentHeader
          fromCityName={props.from.city}
          toCityName={props.to.city}
          airlinesInfo={detailProps.airlines}
          flight_Type={allFlightTypes || props.flightType}
          price={123}
          features={[]}
        />
        <Row>
          <AviaMainSegment
            airlines={[
              {
                name: props.airline,
                code: props.airlineCode,
                operatingAirlineCode: props.operatingAirlineCode,
                operatingAirlineName: props.operatingAirline,
                logoUrl: null,
              },
            ]}
            duration={detailProps.duration}
            flightNumbers={[detailProps.flightNo]}
            from={props.from}
            fromDate={fromDate}
            stops={
              !props.active && transfer
                ? convertStopEntityToStop([transfer])
                : []
            }
            techLanding={props.techLandings}
            to={props.to}
            toDate={toDate}
            baggageDescr={baggageDescr}
            flightIndex={flightIndex}
          />
        </Row>
        {(hasConnectingFlights ||
          (selectedFare && selectedFare.hasConnectingFlights)) && (
          <ChipContainer onClick={() => setIsRepeatRegistrationModalOpen(true)}>
            <Chip
              icon={<AdditionalIcon src={infoIconBlack} />}
              bgColor={'blue'}
              label={'Повторная регистрация'}
              adaptiveTextColor
            />{' '}
          </ChipContainer>
        )}

        <AviaAdditionalInfo
          hasTechLanding={hasTechLanding}
          techLandingsTitle={techLandingsTitle}
          fromTerminal={props.from.terminal}
          toTerminal={props.to.terminal}
          airlineName={props.operatingAirline}
          hasAdditionalInfo={hasAdditionalInfo}
        />
      </Content>
      {props.active && detailProps.stops?.length > 0 && (
        <StopSeparator type={'normal'} title={stopTitle} />
      )}
      {props.active && hasTechLanding && (
        <StopSeparator type={'technical'} title={techLandingsTitle} />
      )}
    </Container>
  );
};

const convertStopEntityToStop = (stopEntities: StopEntity[]): Stop[] => {
  return stopEntities.map(mapStopEntityToStop);
};

const mapStopEntityToStop = (stopEntity: StopEntity): Stop => {
  const { city, duration, time } = stopEntity;
  const mappedCity = {
    code: city.code,
    airport: city.airport,
    city: city.city,
    country: city.country,
    terminal: '', // Assuming some default value as it's not provided in StopEntity
  };

  return {
    city: mappedCity,
    duration,
    time,
  };
};

export default SegmentInBooking;
