import React from 'react';
import { ContextProps } from './types';
import {
  addAddictionalService,
  deleteAddictionalService,
  AddictionalServiceTypes,
} from '@modules/orders';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPriceDetailItem,
  getTotalPrice,
  removePriceDetailItem,
} from '@modules/booking';
import { ApplicationState } from '@modules/index';
import { postSessionLogHandler } from '@modules/logs/duck';
import { UserActionTypes } from '@modules/logs/types';
import { LOGGING_ACTION_IDS } from '@modules/logs/constants';

const ModalContext = React.createContext<ContextProps>(null as any);

export const useModalState = () => React.useContext(ModalContext);

export const Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [visibility, setVisibility] = React.useState(
    null as AddictionalServiceTypes | null,
  );
  // const booking = useSelector((state: ApplicationState) => state.booking);

  const [smsModalVisibility, setSmsVisibility] = React.useState(false);
  const hideSmsModal = () => setSmsVisibility(false);
  const showSmsModal = () => setSmsVisibility(true);
  // const total = booking.fares !== null ? useSelector(getTotalPrice) : 0;

  const hideModal = () => setVisibility(null);
  const showModal = (x: AddictionalServiceTypes) => setVisibility(x);
  const dispatch = useDispatch();

  const handleActionButtonClick: ContextProps['handleActionButtonClick'] = (
    x,
  ) => {
    if (x.isLoading) return;
    if (x.checked) {
      dispatch(deleteAddictionalService([x.type]));
      dispatch(
        postSessionLogHandler({
          eventType: UserActionTypes['Click'],
          actionId:
            LOGGING_ACTION_IDS.AVIA_BOOKING_SECOND_STEP
              .REMOVE_ADDITIONAL_SERVICE_FROM_FORM_BTN,
          screen: LOGGING_ACTION_IDS.AVIA_BOOKING_SECOND_STEP.NAME,
          value: {
            type: x.type,
          },
        }),
      );
      // dispatch(removePriceDetailItem({ total, id: x.id }));
    } else {
      dispatch(
        postSessionLogHandler({
          eventType: UserActionTypes['Click'],
          actionId:
            LOGGING_ACTION_IDS.AVIA_BOOKING_SECOND_STEP
              .ADD_ADDITIONAL_SERVICE_FROM_FORM_BTN,
          screen: LOGGING_ACTION_IDS.AVIA_BOOKING_SECOND_STEP.NAME,
          value: {
            type: x.type,
          },
        }),
      );
      dispatch(addAddictionalService({ services: [x.type] }));
      let description = '';
      switch (x.type) {
        case AddictionalServiceTypes.SMS_Service:
          description = 'SMS-информирование';
          break;
        case AddictionalServiceTypes.Return_Guarantee:
          description = 'Гарантированный возврат билета';
          break;
        case AddictionalServiceTypes.Payment_Receipt:
          description = 'Справка о стоимости заказа';
          break;
      }
      // dispatch(
      //   addPriceDetailItem({
      //     total,
      //     item: {
      //       description,
      //       cost: x.amount,
      //       type: x.type,
      //       quantity: 1,
      //       id: x.id,
      //     },
      //   })
      // );
    }
  };

  const addSmsService: ContextProps['addSmsService'] = (x) => {
    hideSmsModal();
    dispatch(
      postSessionLogHandler({
        eventType: UserActionTypes['Click'],
        actionId:
          LOGGING_ACTION_IDS.AVIA_BOOKING_SECOND_STEP
            .ADD_ADDITIONAL_SERVICE_FROM_FORM_BTN,
        screen: LOGGING_ACTION_IDS.AVIA_BOOKING_SECOND_STEP.NAME,
        value: {
          type: AddictionalServiceTypes.SMS_Service,
        },
      }),
    );
    dispatch(
      addAddictionalService({
        services: [AddictionalServiceTypes.SMS_Service],
        additionalContactNameInfo: x.name,
        additionalContactPhoneInfo: x.phone.replaceAll(/\+|\(|\)|-|\s/g, ''),
      }),
    );
  };

  return (
    <ModalContext.Provider
      value={{
        visibility,
        hideModal,
        showModal,
        handleActionButtonClick,
        smsModalVisibility,
        showSmsModal,
        hideSmsModal,
        addSmsService,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
