import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { useDispatch, useSelector } from 'react-redux';

import { PopupProps, PopupActions } from 'reactjs-popup/dist/types';
import { Text, Button } from '@components/ui';
import { ReactComponent as InfoIcon } from '@assets/media/addictional-services/info.svg';
import { ReactComponent as MealIcon } from '@assets/media/addictional-services/meal.svg';
import { ReactComponent as LuggageIcon } from '@assets/media/addictional-services/luggage.svg';
import {
  AncillaryServicesState,
  PassengerItemEntity,
  AncillaryServiceType,
  getBookingFaresState,
} from '@modules/booking';
import PassengerSelectButton from './PassengerSelectButton';
import { Checkbox } from '@components/ui/form';
import { Helper } from '@utils';
import _ from 'lodash';
import { Enums } from '../types';
import { useModalState } from '../context';
import blueInfoIcon from '@assets/media/agents/blueInfoIcon.svg';
import redInfoIcon from '@assets/media/shared/redInfoIcon.svg';

import { ApplicationState } from '@modules/index';
import { postSessionLogHandler } from '@modules/logs/duck';
import { UserActionTypes } from '@modules/logs/types';
import { LOGGING_ACTION_IDS } from '@modules/logs/constants';

const { formatPrice } = Helper;

interface BaseModalProps
  extends Omit<
    PopupProps & React.RefAttributes<PopupActions>,
    'children' | 'open'
  > {
  data?: AncillaryServicesState[number];
  passengers: PassengerItemEntity[];
  onItemClick: (data: {
    type: AncillaryServiceType;
    segmentId: string;
    passengerId: string;
    key: string;
    checked: boolean;
  }) => void;
}

const StyledPopup = styled(Popup)`
  position: relative;

  &-overlay {
    overflow: scroll;

    @media (max-width: 767px) {
      z-index: 1000 !important;
    }
  }
  &-content {
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 30px;
    border-radius: 8px;
    // height: calc(var(--vh, 1vh) * 95);
    width: 100%;
    max-width: 630px;
    box-sizing: border-box;
    overflow: hidden;
    height: fit-content;
    @media (min-height: 878px) {
      height: auto;
    }

    @media (max-width: 1023px) {
      display: block;
      overflow: scroll;
      height: calc(var(--vh, 1vh) * 100);
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      width: 100%;
    }

    @media (max-width: 767px) {
      padding: 0;
      padding-top: 44px;
      display: flex;
      flex-direction: column;
    }
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 10px;
  }
  ${Text} {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: 767px) {
    padding: 0 20px;

    ${Text} {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }

    & > svg {
      width: 30px;
      height: 30px;
    }
  }
`;

const Desctiption = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-top: 20px;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    margin-top: 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 0 20px;
    display: none;
  }
`;

const ButtonBlock = styled.div`
  & > button {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  margin-bottom: 25px;

  @media (max-width: 1023px) {
    margin-bottom: 5px;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
    display: none;
  }
`;

const Body = styled.div`
  flex-grow: 1;
`;

const PassengerBlockWrapper = styled.div`
  margin-bottom: 40px;

  @media (max-width: 1023px) {
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    margin-bottom: 25px;
    overflow: hidden;
  }
`;

const PassengersBlock = styled.div`
  & > button {
    margin-bottom: 15px;
  }
  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    & > button {
      margin-bottom: 0;
      margin-right: 8px;
    }
  }
`;

const CheckboxContainerDescription = styled(Text)`
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-bottom: 11px;

  @media (max-width: 767px) {
    padding: 0 20px;
  }
`;

const CheckboxContainer = styled.div`
  & > div {
    margin-bottom: 16px;
  }
  & > :last-child {
    margin-bottom: 0;
  }

  margin-bottom: 20px;

  @media (max-width: 767px) {
    padding: 0 20px;
    margin-bottom: 25px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TotalPrice = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: block;
  margin-bottom: 20px;
  & > strong {
    color: #4872f2;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`;

const PrimaryButton = styled(Button).attrs({ type: 'primary' })`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 20.5px;
  display: block;
  height: 40px;
  @media (max-width: 1023px) {
    display: none;
  }
`;

const OutlinedButton = styled(Button).attrs({ type: 'outlined' })`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 20.5px;
  height: 40px;
  margin-left: 20px;
  @media (max-width: 1023px) {
    display: none;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
  & > svg {
    flex-shrink: 0;
  }
  @media (max-width: 767px) {
    padding: 0 20px;
  }
`;

const AbsoluteBlock = styled.div`
  position: relative;
  width: 520px;
  height: 40px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
`;

const DesktopInfo = styled(InfoContainer)`
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileInfo = styled(InfoContainer)`
  @media (min-width: 768px) {
    display: none;
  }
`;

const InfoText = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  color: #4872f2;
  padding-left: 8px;

  @media (max-width: 767px) {
    font-weight: 400;
  }
`;

const Cross = styled.a`
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  outline: none;
  z-index: 100;

  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
    }
  }
  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 17px;
    width: 2px;
    right: 3.5px;
    bottom: 0.5px;
    background-color: ${({ theme: { colors } }) => colors.main};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }

  @media (max-width: 768px) {
    position: fixed;
  }
`;

const RouteName = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: block;
  margin-bottom: 20px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
    color: #4872f2;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    padding: 30px 20px 10px 20px;
    margin-bottom: 0;
  }
`;

const StickyFooter = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  height: 20px;
  width: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  height: 70px;
  justify-content: space-around;
  padding: 0 10px;
  z-index: 100000;
  box-shadow: 0px -4px 12px 0px #757a8830;

  @media (min-width: 767px) {
    display: none;
  }
`;

const ButtonNext = styled.div`
  width: 95%;
  height: 46px;
  border-radius: 8px;
  background-color: #4872f2;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-right: 20px;
`;

const PriceBlock = styled.div`
  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c3c3c;
  margin-bottom: 30px;
  margin-left: 20px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const Icon = styled.img.attrs({ src: blueInfoIcon })`
  margin-left: 7px;
  width: 18px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const IconRed = styled.img.attrs({ src: redInfoIcon })`
  margin-right: 7px;
  width: 25px;
  height: 25px;
`;

const RedHeading = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #f25f61;
`;

const RedContainer = styled.div`
  background-color: #ffe2e3;
  border-radius: 4px;
  font-family: Open Sans;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c3c3c;
  padding: 15px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
`;

export default function ({
  data,
  passengers,
  onItemClick,
  ...props
}: BaseModalProps) {
  const dispatch = useDispatch();
  const [segmentId, setSegment] = React.useState(data?.segments[0]?.id);
  const { visibility, showModal, hideModal } = useModalState();

  const ancillaryDetailsSegment = useSelector(
    (state: ApplicationState) => state.booking.ticket.ancillaryDetailsSegment,
  );
  const booking = useSelector((state: ApplicationState) => state.booking);
  const ticketFlights = booking.ticket.flights;

  React.useEffect(() => {
    if (ancillaryDetailsSegment) {
      setSegment(ancillaryDetailsSegment);
    }
  }, [ancillaryDetailsSegment]);

  const [availablePassengers, setAvailablePassengers] = React.useState(() => {
    const ids =
      segmentId &&
      data?.offers[segmentId] &&
      Object.keys(data?.offers[segmentId]);
    return passengers?.filter((x) => ids?.includes(x?.uid));
  });

  const [passengerId, setPassengerId] = React.useState(
    availablePassengers[0]?.uid,
  );

  const handleChangeSegment = (id: string) => {
    setSegment(id);
    const ids = data && Object.keys(data.offers[id]);
    setAvailablePassengers(() => {
      return passengers.filter((x) => ids && ids.includes(x.uid));
    });
    if (ids) {
      setPassengerId(ids[0]);
    }
    const scrollElement = document.getElementById('HeadModal');

    scrollElement?.scrollIntoView({ behavior: 'smooth' });
  };

  const sementsList = data?.segments?.map((x) => x.id);
  // .filter((value, index, array) => array.indexOf(value) === index);

  function findNextNotFilledSegment() {
    if (segmentId && sementsList) {
      const index = sementsList.indexOf(segmentId);

      return sementsList[index + 1];
    }
  }

  function findPreviousSegment() {
    if (segmentId && sementsList) {
      const index = sementsList.indexOf(segmentId);

      return sementsList[index - 1];
    }
  }

  const nextSegmentId = findNextNotFilledSegment();
  const previousSegment = findPreviousSegment();

  const infantExists = passengers.find((x) => x.ageCategory === 'INFANT');

  const totalPrice = React.useMemo(
    () =>
      _(data?.offers)?.reduce(
        (acc, x) =>
          acc +
          _(x)?.reduce(
            (acc, x) =>
              acc +
              _(x)
                ?.filter((x) => x.checked)
                ?.reduce((acc, x) => acc + x.cost, 0),
            0,
          ),
        0,
      ),
    [data],
  );

  const {
    TITLE,
    DESCRIPTION,
    CHECKBOX_CONTAINER_DESCRIPTION,
    TOTAL_PRICE,
    ICON,
    RUS_TYPE,
    NOT_SELECTED,
    SELECTED,
  } = React.useMemo(() => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    switch (data?.type) {
      case AncillaryServiceType.Luggage:
        return {
          TITLE: 'Дополнительный багаж',
          DESCRIPTION: `Оформите багаж заранее, в аэропорту эта услуга займет время и может стоить дороже, чем на сайте.`,
          CHECKBOX_CONTAINER_DESCRIPTION: 'Выберите багаж:',
          TOTAL_PRICE: 'Стоимость всего багажа:',
          RUS_TYPE: 'багаж',
          NOT_SELECTED: 'не выбран',
          SELECTED: `${width > 767 ? 'багаж ' : ''}выбран`,
          ICON: <LuggageIcon />,
        };
      case AncillaryServiceType.Meal:
        return {
          TITLE: 'Насладитесь вашим полетом',
          DESCRIPTION: `Выберите блюдо, подходящее Вашим вкусовым предпочтениям, и наслаждайтесь полетом в комфортных условиях.`,
          CHECKBOX_CONTAINER_DESCRIPTION: 'Выберите питание:',
          TOTAL_PRICE: 'Стоимость всех блюд:',
          RUS_TYPE: 'питание',
          NOT_SELECTED: 'не выбрано',
          SELECTED: `${width > 767 ? 'питание ' : ''}выбрано`,
          ICON: <MealIcon />,
        };
      default:
        return {
          TITLE: 'TITLE',
          DESCRIPTION: `DESCRIPTION`,
          CHECKBOX_CONTAINER_DESCRIPTION: 'CHECKBOX_CONTAINER_DESCRIPTION:',
          TOTAL_PRICE: 'TOTAL_PRICE:',
          RUS_TYPE: 'RUS_TYPE',
          NOT_SELECTED: 'NOT_SELECTED',
          SELECTED: 'SELECTED',
          ICON: null,
        };
    }
  }, [data?.type]);

  const chosenOffers =
    segmentId &&
    data &&
    Object.entries(data.offers[segmentId])
      .map((x) => x[1].filter((x) => x.checked))
      .flat();

  return data && segmentId ? (
    <StyledPopup {...props} open={visibility === Enums.Ancillary}>
      <Cross
        href="/sde"
        onClick={(e) => {
          e.preventDefault();
          typeof props.onClose === 'function' && props.onClose();
        }}
      />

      <Body>
        <Head id="HeadModal">
          {ICON}
          <Text>{TITLE}</Text>
        </Head>
        <Desctiption>{DESCRIPTION}</Desctiption>
        <RouteName>
          Маршрут:{' '}
          <strong>
            {segmentId && data?.groupedSegments[segmentId]?.route}
          </strong>
        </RouteName>

        <PassengerBlockWrapper>
          <PassengersBlock>
            {availablePassengers.map((x, key) => {
              const title =
                data &&
                _(data.offers[segmentId][x.uid])
                  .filter((x) => x.checked)
                  .map((x) => x.title)
                  .join(',');
              const cost = data.offers[segmentId][x.uid]?.reduce(
                (acc, x) => (x.checked === true ? acc + x.cost : acc + 0),
                0,
              );
              return (
                <PassengerSelectButton
                  key={`${key}-${title}`}
                  {...x}
                  number={key + 1}
                  cost={cost}
                  selected={x.uid === passengerId}
                  title={title !== '' ? SELECTED : NOT_SELECTED}
                  onClick={() => {
                    dispatch(
                      postSessionLogHandler({
                        eventType: UserActionTypes['Click'],
                        actionId:
                          LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP
                            .AVIA_SERVICE_CHANGE_PASSENGER_IN_MODAL,
                        screen: LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.NAME,
                        value: {
                          passenger: x.uid,
                        },
                      }),
                    );
                    setPassengerId(x.uid);
                  }}
                />
              );
            })}
          </PassengersBlock>
        </PassengerBlockWrapper>
        {infantExists && (
          <DesktopInfo>
            <InfoIcon />
            <InfoText>
              Обращаем Ваше внимание - {RUS_TYPE} для младенца необходимо
              добавить ко взрослому пассажиру.
            </InfoText>
          </DesktopInfo>
        )}
        {chosenOffers?.length !== 0 && (
          <PriceBlock>
            Стоимость за {chosenOffers?.length} доп услуги:&nbsp;
            <span style={{ color: '#4872F2' }}>
              {chosenOffers &&
                chosenOffers?.reduce(
                  (acc: number, x: any) => acc + x.cost,
                  0,
                )}{' '}
              ₽
            </span>{' '}
            <Icon onClick={() => showModal(Enums.AncillaryDetails)} />
          </PriceBlock>
        )}

        <CheckboxContainerDescription>
          {CHECKBOX_CONTAINER_DESCRIPTION}
        </CheckboxContainerDescription>
        <CheckboxContainer>
          {data?.offers[segmentId] &&
            data?.offers[segmentId][passengerId] &&
            data?.offers[segmentId][passengerId]?.map((item) => (
              <Row key={item.key}>
                <div>
                  <Checkbox
                    label={item.title}
                    checked={item.checked}
                    onChange={() => {
                      dispatch(
                        postSessionLogHandler({
                          eventType: UserActionTypes['Click'],
                          actionId:
                            LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP
                              .AVIA_SERVICE_CHECK_ITEM,
                          screen:
                            LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.NAME,
                          value: {
                            key: item.key,
                            title: item.title,
                            checked: !item.checked,
                          },
                        }),
                      );
                      onItemClick({
                        type: data.type,
                        segmentId,
                        passengerId,
                        key: item.key,
                        checked: item.checked,
                      });
                    }}
                    type="squar"
                  />
                  <Text size="big" color="blue">
                    {' '}
                    {formatPrice(item.cost)}
                  </Text>
                </div>
              </Row>
            ))}
        </CheckboxContainer>
        {infantExists && (
          <MobileInfo>
            <InfoIcon />
            <InfoText>
              Обращаем Ваше внимание - {RUS_TYPE} для младенца необходимо
              добавить ко взрослому пассажиру.
            </InfoText>
          </MobileInfo>
        )}
        {ticketFlights[0].gds === 'RADIXX' && (
          <RedContainer>
            <Flex>
              <IconRed />{' '}
              <RedHeading>
                Норма багажа - не более 40 кг на пассажира
              </RedHeading>
            </Flex>

            {booking.fares?.selected?.groups &&
              booking.fares?.selected?.groups[0]?.flights[0].segments[0]
                .luggageWeightLimit?.value && (
                <div style={{ marginLeft: 5 }}>
                  В вашем тарифе уже включен багаж{' '}
                  <span style={{ color: '#4872f2', fontWeight: 600 }}>
                    {booking.fares?.selected?.groups &&
                      booking.fares?.selected?.groups[0]?.flights[0].segments[0]
                        .luggageWeightLimit?.value}
                    кг{' '}
                  </span>{' '}
                  на пассажира. При выборе дополнительного багажа не превышайте
                  норму 40кг на пассажира.
                </div>
              )}
          </RedContainer>
        )}
        <TotalPrice>
          {TOTAL_PRICE} <strong>{Helper.formatPrice(totalPrice)}</strong>
        </TotalPrice>
      </Body>
      <AbsoluteBlock>
        {nextSegmentId && segmentId !== nextSegmentId ? (
          <PrimaryButton
            onClick={() =>
              nextSegmentId ? handleChangeSegment(nextSegmentId) : hideModal()
            }
          >
            Перейти к следующему маршруту
          </PrimaryButton>
        ) : null}
        {previousSegment ? (
          <PrimaryButton
            onClick={() =>
              previousSegment && handleChangeSegment(previousSegment)
            }
          >
            Вернуться назад
          </PrimaryButton>
        ) : null}

        <OutlinedButton onClick={props.onClose}>Закончить</OutlinedButton>
      </AbsoluteBlock>
      <StickyFooter>
        {data.segments.length > 1 && !previousSegment && (
          <ButtonNext
            onClick={() =>
              nextSegmentId ? handleChangeSegment(nextSegmentId) : hideModal()
            }
          >
            Перейти далее
          </ButtonNext>
        )}

        {data.segments.length === 1 && (
          <ButtonNext onClick={() => hideModal()}>Закончить</ButtonNext>
        )}
        {data.segments.length > 1 && previousSegment && (
          <>
            <ButtonNext
              style={{
                width: 147,
                backgroundColor: 'white',
                color: '#4872F2',
                border: '1px solid #4872F2',
              }}
              onClick={() =>
                previousSegment && handleChangeSegment(previousSegment)
              }
            >
              Вернуться назад
            </ButtonNext>
            <ButtonNext
              style={{ width: 147 }}
              onClick={() =>
                nextSegmentId
                  ? handleChangeSegment(nextSegmentId)
                  : typeof props.onClose === 'function' && props.onClose()
              }
            >
              Перейти далее
            </ButtonNext>
          </>
        )}
      </StickyFooter>

      {/* <MobileCloseButton onClick={props.onClose}>
        Добавить {RUS_TYPE} за: {Helper.formatPrice(totalPrice)}
      </MobileCloseButton> */}
    </StyledPopup>
  ) : null;
}
