import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '@modules/index';
import {
  createPaymentRequest,
  createPaymentTinkoffRequest,
} from '@modules/orders';
import russianCard from '@assets/media/order-detail/russianCard.svg';
import sbpPaymentImage from '@assets/media/order-detail/sbpPaymentImage.svg';

import internationalCard from '@assets/media/order-detail/internationalCard.svg';
import { getUserState } from '@modules/user';
import { useHistory } from 'react-router-dom';
import usePaymentResponse from './hooks/usePaymentResponse';
import { Hooks } from '@utils';
import { SOCKET_ORDER_UPDATE } from '@modules/constants';
import { PAYMENT_METHODS } from '@components/common/PaymentContainer/constants';
import PaymentDesktop from '@components/common/PaymentContainer/PaymentDesktop';
import PaymentMobile from '@components/common/PaymentContainer/PaymentMobile';
import QRCodePopup from '@components/common/PaymentContainer/QRCodePopup';
import { postSessionLogHandler } from '@modules/logs/duck';
import { UserActionTypes } from '@modules/logs/types';
import { LOGGING_ACTION_IDS } from '@modules/logs/constants';

export interface PaymentMethod {
  label: string;
  amount?: number;
  img: string;
  value?: any;
}

const PaymentContainer: React.FC<{
  chosenPaymentMethod: string;
  setChosenPaymentMethod: Dispatch<SetStateAction<string>>;
  promocodeStatus?: string | boolean | null;
  isPaymentLoader?: boolean;
  isAppeal?: boolean;
}> = ({
  chosenPaymentMethod,
  setChosenPaymentMethod,
  promocodeStatus,
  isPaymentLoader,
  isAppeal,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = Hooks.useMediaSizes();

  const orderDetailStore = useSelector(
    (state: ApplicationState) => state.orders.orderDetail,
  );
  const userState = useSelector(getUserState);

  const [sbpModalIsOpen, setSbpModalIsOpen] = React.useState(false);
  const [qrCodeIsLoading, setQrCodeIsLoading] = React.useState(false);
  const [chosenPaymentImage, setChosenPaymentImage] =
    React.useState(russianCard);

  const {
    availableActions,
    insurances,
    additionalServices,
    orderPositions,
    orderNumber,
    acquiringCommissions,
  } = orderDetailStore.data;

  const paymentUrl = orderDetailStore?.paymentUrl;
  const paymentTinkoffUrl = orderDetailStore?.paymentTinkoffUrl;
  const paymentQr = orderDetailStore?.paymentQr;
  const paymentTinkoffQrNumber = orderDetailStore?.paymentTinkoffQrNumber;
  const orderPositionIds = orderPositions.map((x) => x.id);

  const canPay =
    (availableActions.includes('PAYMENT') ||
      availableActions.includes('EXTRAPAYMENT')) &&
    !userState.data?.agentId;

  const { height, isNotifyLoader } = usePaymentResponse({
    orderNumber,
    orderPositionIds,
  });
  const socketProtocol = process.env.REACT_APP_MODE === 'prod' ? 'wss' : 'ws';

  const socket = new WebSocket(
    `${socketProtocol}://${SOCKET_ORDER_UPDATE}/paymentUpdate/ws?number=${paymentTinkoffQrNumber}`,
  );

  const receiveMessageSocket = (event: any) => {
    // console.log(JSON.parse(event.data), 'socket');

    if (JSON.parse(event.data).IsProcessing === true) {
      if (!window.location.pathname.includes('/orders') && orderNumber !== 0) {
        history.push(`/orders/${orderNumber}`);
        socket.removeEventListener('message', receiveMessageSocket);
      } else if (window.location.pathname.includes('/orders')) {
        window.location.reload();
        socket.removeEventListener('message', receiveMessageSocket);
      }
    }
  };

  socket.addEventListener('message', receiveMessageSocket);

  React.useEffect(() => {
    if (
      chosenPaymentMethod === PAYMENT_METHODS.RF_CARD ||
      chosenPaymentMethod === PAYMENT_METHODS.SBP
    ) {
      dispatch(
        createPaymentTinkoffRequest({
          correlationId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          orderNumber: orderNumber,
          orderPositionIds: orderPositionIds,
          tinkoffSettingType: 0,
        }),
      );
    } else
      dispatch(
        createPaymentRequest({
          correlationId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          orderNumber: orderNumber,
          orderPositionIds: orderPositionIds,
          gatelineSettingType: 1,
        }),
      );
  }, [additionalServices, insurances, promocodeStatus]);

  const paymentMethods: PaymentMethod[] = [
    {
      label: PAYMENT_METHODS.SBP,
      amount: acquiringCommissions?.find((x) => x.paymentType === 'Rus')
        ?.amount,
      img: sbpPaymentImage,
    },
    {
      label: PAYMENT_METHODS.RF_CARD,
      amount: acquiringCommissions?.find((x) => x.paymentType === 'Rus')
        ?.amount,
      img: russianCard,
      value: createPaymentTinkoffRequest({
        correlationId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        orderNumber: orderNumber,
        orderPositionIds: orderPositionIds,
        tinkoffSettingType: 0,
      }),
    },
    {
      label: PAYMENT_METHODS.FOREIGN_CARD,
      img: internationalCard,
      amount: acquiringCommissions?.find((x) => x.paymentType === 'Int')
        ?.amount,

      value: createPaymentRequest({
        correlationId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        orderNumber: orderNumber,
        orderPositionIds: orderPositionIds,
        gatelineSettingType: 1,
      }),
    },
    // {
    //   label: 'Оплата в кредит',
    //   img: creditCard,
    //   value: createPaymentRequest({
    //     correlationId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    //     orderNumber: orderNumber,
    //     orderPositionIds: orderPositionIds,
    //     gatelineSettingType: 0,
    //   }),
    // },
  ];

  useEffect(() => {
    if (paymentQr) {
      setQrCodeIsLoading(false);
    }
  }, [paymentQr]);

  const qrCode = paymentQr && paymentQr.svg;

  useEffect(() => {
    if (
      paymentUrl &&
      isMobile &&
      chosenPaymentMethod === PAYMENT_METHODS.RF_CARD &&
      !document.getElementById('TinkoffIframe')
    ) {
      const script = document.createElement('script');

      const contentContainer = document.getElementById('payment_block');

      script.src = 'https://kassa.cdn-tinkoff.ru/integration/integration.js';
      script.type = 'text/javascript';
      script.async = false;
      document.body.appendChild(script);
      script.onload = () => {
        const element = document.createElement('iframe');
        element.setAttribute(
          'src',
          `https://securepayments.tinkoff.ru${new URL(paymentUrl).pathname}`,
        );
        element.setAttribute('id', 'TinkoffIframe');
        element.style.width = '100%';
        element.style.height = '617px';

        element.style.padding = '15px 0';
        element.style.border = '0';

        if (contentContainer) {
          contentContainer.appendChild(element);
        }

        //@ts-ignore
        const paymentForm = new PaymentForm.Integration({
          iframe: { element },
        });
      };
      document.getElementsByTagName('body')[0].appendChild(script);
    }
    if (isMobile && chosenPaymentMethod === PAYMENT_METHODS.FOREIGN_CARD) {
      const element = document.getElementById('TinkoffIframe');
      if (element) {
        element.style.display = 'none';
      }
    }

    if (
      isMobile &&
      chosenPaymentMethod === PAYMENT_METHODS.RF_CARD &&
      document.getElementById('TinkoffIframe')
    ) {
      const element = document.getElementById('TinkoffIframe');
      if (element) {
        element.style.display = 'block';
      }
    }
  }, [paymentUrl]);

  useEffect(() => {
    dispatch(
      postSessionLogHandler({
        eventType: UserActionTypes['Click'],
        actionId:
          LOGGING_ACTION_IDS.AVIA_BOOKING_SECOND_STEP.CHOOSE_PAYMENT_OPTION,
        screen: LOGGING_ACTION_IDS.AVIA_BOOKING_SECOND_STEP.NAME,
        value: {
          paymentMethod: chosenPaymentMethod,
        },
      }),
    );
  }, [chosenPaymentMethod]);

  return (
    <>
      {isMobile ? (
        <PaymentMobile
          orderNumber={orderNumber}
          orderPositionIds={orderPositionIds}
          acquiringCommissions={acquiringCommissions}
          canPay={canPay}
          chosenPaymentMethod={chosenPaymentMethod}
          height={height}
          // isAppeal={isAppeal}
          isNotifyLoader={isNotifyLoader}
          isPaymentLoader={isPaymentLoader}
          paymentMethods={paymentMethods}
          paymentQr={paymentQr}
          paymentTinkoffQrNumber={paymentTinkoffQrNumber}
          paymentTinkoffUrl={paymentTinkoffUrl}
          paymentUrl={paymentUrl}
          qrCode={qrCode}
          setChosenPaymentImage={setChosenPaymentImage}
          setChosenPaymentMethod={setChosenPaymentMethod}
          setQrCodeIsLoading={setQrCodeIsLoading}
          setSbpModalIsOpen={setSbpModalIsOpen}
        />
      ) : (
        <PaymentDesktop
          orderNumber={orderNumber}
          orderPositionIds={orderPositionIds}
          acquiringCommissions={acquiringCommissions}
          canPay={canPay}
          chosenPaymentImage={chosenPaymentImage}
          chosenPaymentMethod={chosenPaymentMethod}
          height={height}
          isAppeal={isAppeal}
          isNotifyLoader={isNotifyLoader}
          isPaymentLoader={isPaymentLoader}
          paymentMethods={paymentMethods}
          paymentQr={paymentQr}
          paymentTinkoffQrNumber={paymentTinkoffQrNumber}
          paymentTinkoffUrl={paymentTinkoffUrl}
          paymentUrl={paymentUrl}
          qrCode={qrCode}
          setChosenPaymentImage={setChosenPaymentImage}
          setChosenPaymentMethod={setChosenPaymentMethod}
          setQrCodeIsLoading={setQrCodeIsLoading}
          setSbpModalIsOpen={setSbpModalIsOpen}
        />
      )}
      <QRCodePopup
        sbpModalIsOpen={sbpModalIsOpen}
        qrCodeIsLoading={qrCodeIsLoading}
        paymentMethod={paymentMethods[0]}
        qrCode={qrCode}
        setSbpModalIsOpen={setSbpModalIsOpen}
        setQrCodeIsLoading={setQrCodeIsLoading}
      />
    </>
  );
};

export default PaymentContainer;
