import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { FlightProps } from './types';
import Text from '@components/ui/Typography';
import SegmentInBooking from '@components/booking/FlightDetail/SegmentInBooking';
import { StopEntity } from '@modules/booking';
import { formatDirectionName } from '@app/utils/helpers';

const Body = styled.div`
  & > div {
    margin-bottom: 30px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  }

  & > :last-child {
    border-bottom: 0;
  }
`;
const Header = styled(Text)`
  display: block;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3c3c3c;

  & > strong {
    color: ${({ theme }) => theme.colors.main};
  }

  margin-bottom: 20px;
`;

const Flight: React.FC<FlightProps> = ({
  segments,
  className,
  hasConnectingFlights,
  ...props
}) => {
  const startSegment = segments[0];
  const endSegment = segments[segments.length - 1];
  const fromCityName = startSegment.departureCity.name;
  const toCityName = endSegment.arrivalCity.name;

  return (
    <>
      <Header fontWeight="600" size={18} color="dark-gray">
        Детали маршрута:{' '}
        <strong>
          {props.departureCity?.name} — {props.arrivalCity?.name}
        </strong>
      </Header>
      <Body>
        {segments.map((segment, key) => {
          const [fromDate, fromTime] = segment.departureDateTime.split('T');
          const [toDate, toTime] = segment.arrivalDateTime.split('T');
          const stops: StopEntity | undefined =
            segment.stopOvers?.length > 0
              ? {
                  duration: segment.stopOvers[0]?.duration,
                  city: {
                    code: segment.arrivalCity.code,
                    airport: segment.arrivalAirport.name,
                    city: segment.arrivalCity.name,
                    country: '',
                  },
                  time: moment(segment.stopOvers[0]?.arrivalDateTime).format(
                    'HH:mm:ss',
                  ),
                }
              : undefined;
          return (
            <SegmentInBooking
              fromTo={
                key === 0
                  ? `${formatDirectionName(
                      props.index,
                      1,
                    )} ${fromCityName}-${toCityName}`
                  : undefined
              }
              flightType={segment.flight_Type}
              key={key}
              active={true}
              // separator={key !== segments.length - 1}
              transfer={stops}
              from={{
                code: segment.departureCity.code,
                airport: segment.departureAirport.name,
                city: segment.departureCity.name,
                country: '',
                terminal: segment.departureAirport.terminal,
              }}
              to={{
                code: segment.arrivalCity.code,
                airport: segment.arrivalAirport.name,
                city: segment.arrivalCity.name,
                country: '',
                terminal: segment.arrivalAirport.terminal,
              }}
              fromDate={fromDate}
              fromTime={fromTime}
              toDate={toDate}
              toTime={toTime}
              isLowcoster={segment.isLowcoster}
              seatMap={null}
              flightClass={''}
              flight_Type={segment.flight_Type}
              fareFamilyFeature={null}
              fareFamily={null}
              fareBasis={''}
              techLandings={segment.techLandings}
              duration={segment.duration}
              airlineCode={segment.marketingAirline.code}
              id={segment.id}
              airline={segment.marketingAirline.name}
              operatingAirlineCode={segment.operatingAirline.code}
              operatingAirline={segment.operatingAirline.name}
              classCode={''}
              index={segment.index}
              flightNo={segment.flightNumber}
              availSeats={0}
              airplane={''}
              flightIndex={key}
              time={''}
              hasConnectingFlights={hasConnectingFlights}
            />
          );
        })}
      </Body>
    </>
  );
};

export default Flight;
