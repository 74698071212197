import React, { useMemo } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import _, { isBoolean } from 'lodash';
import AnimatedNumber from 'animated-number-react';
import { Button, Checkbox, Text as BaseText } from '@components/ui';
import RailwayCarService from './RailwayCarService';
import {
  bedClothsBackwardSelector,
  bedClothsForwardSelector,
  Car as CarType,
  CAR_SEAT_TYPES,
  CAR_TYPES,
  CAR_TYPES_TITLES,
  CarFloor,
  selectBackCar,
  selectCar,
  setBedClothesBackward,
  setBedClothesForward,
  setStep,
  TRAIN_SEARCH_STEPS,
  trainBackChosenCarPlacesSelector,
  trainCurrentSearchStepSelector,
  trainForwardChosenCarPlacesSelector,
  trainTicketsSearchSelector,
} from '@modules/trainTickets';
import { Helper } from '@utils';

import CarScheme from './CarScheme';
import { useDispatch, useSelector } from 'react-redux';
import { getTicketCost } from '@components/train/TrainSearch/utils';
import { searchFormDates } from '@modules/main';
import { useMediaQuery } from 'react-responsive';
import { ApplicationState } from '@modules/index';
import { Separator } from '@components/common';
import { getAllPlaceReservationTypesFromCar } from '@modules/trainTickets/utils';
import { CYPRESS_RAILWAY_ATTRS } from '../../../../cypress/railway';

const Wrapper = styled.div`
  border: 1px solid #dcdcdc;
  background: white;
  padding: 20px;
  box-shadow: 0 5px 10px #e3eaff;
  border-radius: 10px;

  @media (max-width: 767px) {
    padding: 14px;
  }
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 9px;

  @media (max-width: 767px) {
    margin-bottom: 6px;
  }
`;

const HeaderServices = styled.div`
  display: flex;
  align-items: center;

  & > img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  & > :last-child {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 15px;
    margin-top: 8px;
  }
`;

const PlacesDescription = styled.div`
  margin-bottom: 20px;

  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`;

const BigText = styled(BaseText)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;

  &.blue {
    color: #4872f2;
  }

  &.gray {
    color: #737373;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const PriceText = styled(BigText)`
  flex-shrink: 0;
`;

const FloorText = styled(BigText)`
  margin: 25px 0 15px 0;
  display: inline-block;

  &:first-of-type {
    margin-top: 0;
  }
`;

const ButtonContainer = styled(Button)`
  flex-shrink: 0;
`;

const Text = styled(BaseText)`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #3c3c3c;

  &.blue {
    color: #4872f2;
  }

  &.gray {
    color: #737373;
  }

  &.pointer {
    cursor: pointer;
  }

  &.bold {
    font-weight: 600;
  }

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const MutedText = styled.span`
  font-size: 12px;
  line-height: 18px;
  color: #737373;

  @media (max-width: 767px) {
    font-size: 10px;
    line-height: 14px;
  }
`;

const Bottom = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
`;

const PlaceWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`;

const OpenedBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }
`;

const ButtonWrapper = styled.div`
  @media (max-width: 767px) {
    width: 100%;

    & > button {
      width: 100%;
      margin-top: 14px;
    }
  }
`;

const TextBlock = styled.div`
  & > :first-child {
    display: block;
    //margin-bottom: 12px;
  }
`;

const PriceSquar = styled.div`
  width: 25px;
  height: 25px;
  background: #ca96db;
  border-radius: 6px;
`;

const PriceSquareDisabled = styled.div`
  width: 25px;
  height: 25px;
  background: #c4c4c4;
  border-radius: 6px;
`;

const PriceSquareNonRefunded = styled.div`
  width: 25px;
  height: 25px;
  border: 1px solid #e73c3e;
  border-radius: 6px;
`;

const PriceDesc = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 8px;
  }
`;

const PriceDescBlock = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  gap: 20px;

  @media (max-width: 767px) {
    margin-top: 15px;
    margin-bottom: 20px;
  }
`;

const CarSchemeContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const StyledButton = styled(Button)`
  &[disabled] {
    color: #3c3c3c;
  }
`;

const StyledSeparator = styled(Separator)`
  margin: 20px 0;
`;

const ServiceContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const Bedsheet = styled.div`
  & > label {
    margin-right: 8px;
  }
`;

const GenderLegend = styled.div`
  display: flex;
  flex-direction: column;
`;

const AttentionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const RedText = styled.span`
  color: #f25f61;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

export default function Car(
  props: CarType & {
    open: boolean;
    index: number;
    hasAnotherArrivalDateTime: boolean;
    notBrandedCar: boolean;
    isSapsan: boolean;
  },
) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const currentStep = useSelector(trainCurrentSearchStepSelector);
  const isBackward = currentStep === TRAIN_SEARCH_STEPS.TICKET_BACKWARD;
  const trainSearch = useSelector(trainTicketsSearchSelector);
  const carsStatus = useSelector(trainTicketsSearchSelector).carsStatus;
  const dates = useSelector(searchFormDates);
  const chosenCarPlaceFromState = useSelector(
    isBackward
      ? trainBackChosenCarPlacesSelector
      : trainForwardChosenCarPlacesSelector,
  );
  const bedClothes = useSelector(
    isBackward ? bedClothsBackwardSelector : bedClothsForwardSelector,
  );
  const passengers = useSelector(
    (state: ApplicationState) =>
      state.searchReducer.simpleSearchForm.passengers,
  );

  const totalPriceIsPriceForOneSeat = useMemo(() => {
    return (
      chosenCarPlaceFromState[0]?.placeReservationType === 'FourPlacesAtOnce' ||
      chosenCarPlaceFromState[0]?.placeReservationType === 'TwoPlacesAtOnce'
    );
  }, [chosenCarPlaceFromState]);
  let maxSeats = passengers.adults.count + passengers.children.count;
  const infantsAndChildrenCount =
    passengers.children.count + passengers.infants.count;
  // В Люксе (где 2 места) могут ехать 2 взрослых и 2 ребёнка до 10 лет
  if (
    props.carType === CAR_TYPES.Soft &&
    infantsAndChildrenCount <= 2 &&
    infantsAndChildrenCount <= passengers.adults.count
  ) {
    maxSeats = passengers.adults.count;
  }
  const luxWithChildren =
    props.carType === CAR_TYPES.Soft &&
    passengers.adults.count > 0 &&
    passengers.adults.count <= 2 &&
    infantsAndChildrenCount > 0 &&
    infantsAndChildrenCount <= 2;

  const onlyChildInTrip =
    passengers.adults.count === 0 &&
    passengers.infants.count === 0 &&
    passengers.children.count !== 0;
  const hasBackwardRoute = !!dates.to;

  // есть места, которые можно выкупить ТОЛЬКО по невозвратному тарифу
  const inFreePlacesHasOnlyOnoRefundablePlace = useMemo(() => {
    return props.freePlacesByCompartments.some((compartment) => {
      return compartment.places.some((place) => {
        return place.prices.find((price) => price.onlyNonRefundableTariff);
      });
    });
  }, [props.freePlacesByCompartments]);

  const freePlaces = _(props.freePlacesByCompartments)
    .map((x) =>
      x.places.map((x) => {
        let typeDesciption = [
          CAR_SEAT_TYPES[x.type],
          CAR_SEAT_TYPES[x.type],
          CAR_SEAT_TYPES[x.type],
        ];
        let type = x.type;

        switch (x.type) {
          case 'Upper':
          case 'LastCompartmentUpperWithHigherLevelOfNoise':
          case 'SideUpperWithHigherLevelOfNoise':
          case 'SideUpper': {
            typeDesciption = ['верхнее', 'верхних', 'верхних'];
            type = 'Upper';
            break;
          }

          case 'Lower':
          case 'LastCompartmentLowerWithHigherLevelOfNoise':
          case 'SideLowerWithHigherLevelOfNoise':
          case 'SideLower': {
            typeDesciption = ['нижнее', 'нижних', 'нижних'];
            type = 'Lower';
            break;
          }
        }
        return {
          ...x,
          typeDesciption,
          type,
        };
      }),
    )
    .flatten()
    .value();

  const placeTypeStr = _(freePlaces)
    .groupBy((x) => x.type)
    .map((x) => {
      const desc = x[0].typeDesciption;
      return (
        x.length +
        ' – ' +
        Helper.pluralWord(x.length, desc[0], desc[1], desc[2])
      );
    })
    .toArray()
    .join('; ');

  const tariff = props.carPriceDetalizations.find((x) =>
    onlyChildInTrip ? x.tariffType === 'Child' : x.tariffType === 'Full',
  )!;
  const minPrice = tariff?.minPrice?.totalPrice || 0;
  const maxPrice = tariff?.maxPrice?.totalPrice || 0;

  let stepText = 'На следующем шаге Вы перейдете к бронированию.';
  if (
    trainSearch.currentSearchStep === TRAIN_SEARCH_STEPS.TICKET_FORWARD &&
    dates.to
  ) {
    stepText = 'На следующем шаге необходимо выбрать поезд обратно.';
  }

  const reservationTypes = React.useMemo(() => {
    return getAllPlaceReservationTypesFromCar(props);
  }, [props.index]);

  const hasTwoPlacesAtOnce = useMemo(() => {
    return reservationTypes.includes('TwoPlacesAtOnce');
  }, [reservationTypes]);

  const carServices = () => {
    return (
      !!props.services?.length && (
        <HeaderServices>
          {props.services.map((service, index) => {
            return <RailwayCarService key={index} code={service} />;
          })}
        </HeaderServices>
      )
    );
  };

  const setStepHandler = () => {
    if (isBackward) {
      dispatch(setStep({ step: 'BOOKING', needClear: false }));
    } else {
      if (hasBackwardRoute) {
        dispatch(setStep({ step: 'TICKET_BACKWARD', needClear: false }));
      } else {
        dispatch(setStep({ step: 'BOOKING', needClear: false }));
      }
    }
  };

  const buttonTitle = () => {
    if (
      chosenCarPlaceFromState?.length === 0 ||
      chosenCarPlaceFromState?.length === maxSeats ||
      maxSeats - chosenCarPlaceFromState?.length < 0
    ) {
      return 'Продолжить';
    } else {
      return `Выберите ещё ${
        maxSeats - chosenCarPlaceFromState?.length
      } ${Helper.pluralWord(
        maxSeats - chosenCarPlaceFromState?.length,
        'место',
        'места',
        'мест',
      )}`;
    }
  };

  const handleBeddingChange = () => {
    if (isBackward) {
      dispatch(setBedClothesBackward(!bedClothes));
    } else {
      dispatch(setBedClothesForward(!bedClothes));
    }
  };

  const hasBedsheetService = props.services?.includes('Bedclothes');
  const needShowBedSheetCheckbox =
    props.isBeddingSelectionPossible ||
    (!props.isBeddingSelectionPossible && hasBedsheetService);
  const bedsheetServiceCost =
    props.carPriceDetalizations.find((tariff) => tariff.tariffType === 'Full')
      ?.serviceCost.totalPrice || 0;

  const totalPrice = useMemo(() => {
    return getTicketCost(
      chosenCarPlaceFromState,
      bedClothes,
      totalPriceIsPriceForOneSeat,
      maxSeats,
      bedsheetServiceCost,
      onlyChildInTrip,
    );
  }, [
    chosenCarPlaceFromState,
    bedClothes,
    totalPriceIsPriceForOneSeat,
    maxSeats,
    bedsheetServiceCost,
  ]);

  return (
    <Wrapper>
      <Head>
        <BigText>
          {props.carNumber} вагон{' '}
          <BigText className="gray">
            / {props.serviceClass} / {props.carrier} /{' '}
            {CAR_TYPES_TITLES[props.carType]}
            {/*<IconContainer title={'Маршрут поезда'}>*/}
            {/*  <InfoIcon />*/}
            {/*</IconContainer>*/}
          </BigText>
        </BigText>
        {!isMobile && carServices()}
      </Head>
      {props.notBrandedCar && (
        <BaseText>Нефирменный вагон в фирменном поезде</BaseText>
      )}
      {props.hasAnotherArrivalDateTime && (
        <BaseText color={'#F25F61'}>
          Прибытие:{' '}
          <span>
            {moment(props.carArrivalDateTime).format('HH:mm, DD MMMM YYYY')}
          </span>{' '}
          (время местное)
        </BaseText>
      )}
      {props.open && (
        <PlacesDescription>
          <Text>
            {freePlaces.length}{' '}
            {Helper.pluralWord(
              freePlaces.length,
              'свободное место',
              'свободных места',
              'свободных мест',
            )}
            : <Text className="gray">{placeTypeStr}</Text>
          </Text>
          {hasTwoPlacesAtOnce && (
            <>
              <AttentionTextContainer>
                <Text className={'bold'}>
                  <RedText>Внимание!</RedText> В вагоне есть купе, которые можно
                  выкупить только целиком.{' '}
                  {props.carType === CAR_TYPES.Luxury
                    ? `Тариф “Детский“ не применяется.`
                    : ``}
                </Text>
                {maxSeats > 2 && (
                  <Text className={'bold'}>
                    <RedText>Внимание!</RedText> Оформление в купе Люкс доступно
                    разными заказами максимум для двух пассажиров (в одном
                    заказе не более 1 купе). Пожалуйста, разделите заказ на два.
                  </Text>
                )}
                {luxWithChildren && (
                  <Text className={'bold'}>
                    Данный тариф включает бесплатный проезд максимум 2-х детей в
                    возрасте до 10 лет. Если вы планируете поездку с детьми,
                    необходимо внести их данные на следующем шаге.
                  </Text>
                )}
              </AttentionTextContainer>
            </>
          )}
        </PlacesDescription>
      )}
      {isMobile && carServices()}
      {props.open && (
        <CarSchemeContainer>
          {props.isTwoStorey && <FloorText>Верхний этаж</FloorText>}
          <CarScheme
            type={props.isTwoStorey ? CarFloor.UPPER : CarFloor.LOWER}
            isSapsan={props.isSapsan}
          />
          {props.isTwoStorey && <FloorText>Нижний этаж</FloorText>}
          {props.isTwoStorey && (
            <CarScheme type={CarFloor.LOWER} isSapsan={props.isSapsan} />
          )}
        </CarSchemeContainer>
      )}
      {!props.open && (
        <Bottom>
          <PlaceWrapper isMobile={isMobile}>
            <Text>
              {freePlaces.length}{' '}
              {Helper.pluralWord(
                freePlaces.length,
                'свободное место',
                'свободных места',
                'свободных мест',
              )}
              {!isMobile && <Text className="gray">: {placeTypeStr}</Text>}
            </Text>
            {isMobile && <Text className="gray">{placeTypeStr}</Text>}
          </PlaceWrapper>
          {!isMobile && (
            <PriceText>
              от {Helper.formatPrice(minPrice)} – {Helper.formatPrice(maxPrice)}
            </PriceText>
          )}
          <ButtonContainer
            type="outlined"
            template={'blue'}
            disabled={carsStatus === 'loading'}
            onClick={() => {
              if (isBackward) {
                dispatch(selectBackCar(props.index));
              } else {
                dispatch(selectCar(props.index));
              }
            }}
          >
            {isMobile ? 'Выбрать' : 'Выбрать вагон'}
          </ButtonContainer>
        </Bottom>
      )}
      {props.open && (
        <div>
          <PriceDescBlock>
            <PriceDesc>
              <PriceSquar />
              <Text className="bold">
                {minPrice === maxPrice ? (
                  Helper.formatPrice(minPrice)
                ) : (
                  <>
                    {Helper.formatPrice(minPrice)} –{' '}
                    {Helper.formatPrice(maxPrice)}
                  </>
                )}
              </Text>
            </PriceDesc>
            <PriceDesc>
              <PriceSquareDisabled />
              <Text className="bold">Место занято</Text>
            </PriceDesc>
            {(props.hasNonRefundableTariffs ||
              inFreePlacesHasOnlyOnoRefundablePlace) && (
              <PriceDesc>
                <PriceSquareNonRefunded />
                <Text className="bold">Невозвратный билет</Text>
              </PriceDesc>
            )}
          </PriceDescBlock>
          {needShowBedSheetCheckbox && (
            <ServiceContainer>
              <Bedsheet>
                <Checkbox
                  type={'squar'}
                  disabled={!props.isBeddingSelectionPossible}
                  checked={bedClothes === true}
                  onChange={handleBeddingChange}
                />
                <Text>
                  Постельное бельё{' '}
                  {props.isBeddingSelectionPossible
                    ? `(+${bedsheetServiceCost}₽ за пассажира)`
                    : ''}
                </Text>
              </Bedsheet>
            </ServiceContainer>
          )}
        </div>
      )}
      {props.open && props.hasGenderCabins && (
        <GenderLegend>
          <Text>
            <BoldText>В</BoldText> – можно выбрать пол для купе
          </Text>
          <Text>
            <BoldText>М</BoldText> – мужское купе
          </Text>
          <Text>
            <BoldText>Ж</BoldText> – женское купе
          </Text>
          <Text>
            <BoldText>С</BoldText> – смешанное купе
          </Text>
        </GenderLegend>
      )}
      {props.open && <StyledSeparator />}
      {props.open && (
        <OpenedBottom>
          <TextBlock>
            {chosenCarPlaceFromState?.length > 0 ? (
              <BigText>
                Стоимость за{' '}
                <BigText className="blue">
                  {chosenCarPlaceFromState.length}
                </BigText>{' '}
                {Helper.pluralWord(
                  chosenCarPlaceFromState.length,
                  'место',
                  'места',
                  'мест',
                )}
                {hasBedsheetService && (
                  <>
                    {isBoolean(bedClothes)
                      ? bedClothes
                        ? ', с бельём'
                        : ', без белья'
                      : ''}
                  </>
                )}
                :{' '}
                <BigText
                  className="blue"
                  data-cy={CYPRESS_RAILWAY_ATTRS.CAR_SCHEME_TOTAL_PRICE}
                >
                  <AnimatedNumber
                    duration={300}
                    value={totalPrice}
                    formatValue={(value: number) => Helper.formatPrice(value)}
                  />
                </BigText>
              </BigText>
            ) : (
              <BigText>Выберите место, чтобы увидеть стоимость</BigText>
            )}

            <MutedText>{stepText}</MutedText>
          </TextBlock>
          <ButtonWrapper>
            <StyledButton
              type="primary"
              color="yellow"
              disabled={chosenCarPlaceFromState?.length < maxSeats}
              onClick={() => {
                setStepHandler();
              }}
            >
              {buttonTitle()}
            </StyledButton>
          </ButtonWrapper>
        </OpenedBottom>
      )}
    </Wrapper>
  );
}
