import { ApplicationState } from '../index';
import { createSelector } from 'reselect';

export const getMainSelector = (state: ApplicationState) => state.mainReducer;

export const getPassengers = (state: ApplicationState) =>
  state.searchReducer.simpleSearchForm.passengers;

export const searchFormDates = (state: ApplicationState) =>
  state.searchReducer.simpleSearchForm.dates;

export const connectionFilterStatus = (state: ApplicationState) =>
  state.mainReducer.connectionFilter;

export const getFlightSearch = (state: ApplicationState) =>
  state.mainReducer.flightSearch;

export const getTicketList = (state: ApplicationState) =>
  state.mainReducer.ticketList;

export const getPluginToken = (state: ApplicationState) =>
  state.mainReducer.plugintoken;

export const getFiltersCounter = createSelector(
  getMainSelector,
  (state) =>
    Object.entries(state.flightSearch.filter.isDefault).filter(([_, val]) => {
      return !val;
    }).length,
);

export const selectTicketListData = createSelector(
  [getTicketList],
  (ticketList) => ({
    visibleItems: ticketList.visibleItems,
    page: ticketList.page,
    pageCount: ticketList.pageCount,
  }),
);
