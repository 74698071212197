import styled from 'styled-components';
import { Text } from '@components/ui';
import { ReactComponent as CrossIcon } from '@assets/media/error-notifications/mobile-cross.svg';
import Popup from 'reactjs-popup';

interface LinkProps {
  isActive: boolean;
}

interface LinkPropsIFrame {
  isMTS: boolean;
  isINT: boolean;
  isHeight: boolean;
  isSBP: boolean;
  isRus?: boolean;
  isAppeal?: boolean;
}

export const StyledCloseIcon = styled(CrossIcon)`
  cursor: pointer;
  flex-shrink: 0;
  margin-left: 10px;
`;

export const PaymentHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const SpbHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const SbpLeftSide = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
`;

export const SbpRightSide = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  position: relative;
  margin-top: 40px;
`;

export const ChoosePaymentBlock = styled.div`
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  height: 84px;
  display: flex;
  align-items: center;

  @media screen and (min-width: 320px) and (max-width: 1439px) {
    height: 90px;
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
  }
`;

export const ChoosePaymentElement = styled.div<LinkProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  padding: 0 15px;
  cursor: pointer;
  border: 1px solid #dcdcdc;
  border-color: ${({ isActive }) => (isActive ? '#4872F2' : '#dcdcdc')};
  border-radius: ${({ isActive }) => (isActive ? '8px' : '0px')};
  border-right: ${({ isActive }) => (isActive ? '1px solid #4872F2' : '0px')};
  border-left: ${({ isActive }) => (isActive ? '1px solid #4872F2' : '0px')};
  border-top: ${({ isActive }) => (isActive ? '1px solid #4872F2' : '0px')};
  border-bottom: ${({ isActive }) => (isActive ? '1px solid #4872F2' : '0px')};
  background: ${({ isActive }) => (isActive ? '#EDF1FE' : '')};
  height: 84px;
  width: 50%;
  text-align: center;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    height: 90px;
    text-align: center;
  }
`;

export const Title = styled(Text)`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding-left: 15px;
  }
`;

export const BlockText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3c3c3c;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 13px;
    margin-left: 15px;
  }
`;

export const IframeContainer = styled.div<LinkPropsIFrame>`
  width: ${({ isAppeal }) => (isAppeal ? '690px' : 'auto')};
  position: relative;
  overflow: hidden;
  height: ${({ isMTS, isINT, isHeight, isSBP }) =>
    isMTS
      ? '1200px'
      : isINT && !isHeight
        ? '680px'
        : !isINT && isHeight
          ? '350px'
          : isINT && isHeight
            ? '380px'
            : isSBP
              ? '300px'
              : '587px'};

  box-shadow: 5px 5px 10px 5px #0000000d;
  padding: 25px;

  margin-top: 20px;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    height: ${({ isMTS, isINT, isSBP }) =>
    isMTS ? '1150px' : isINT ? '820px' : isSBP ? '350px' : '647px'};
    width: 100%;
    box-sizing: border-box;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
`;

export const Iframe = styled.iframe<LinkPropsIFrame>`
  min-width: 100%;
  border: none;
  height: 100%;
  max-height: ${({ isMTS, isINT, isHeight }) =>
    isMTS
      ? '1200px'
      : isINT && !isHeight
        ? '680px'
        : !isINT && isHeight
          ? '350px'
          : isINT && isHeight
            ? '360px'
            : '537px'};
  @media screen and (max-width: 767px) {
    display: ${({ isRus }) => (isRus ? 'none' : 'flex')};
  }
`;

export const IframeLoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
`;

export const QRCodeLoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const QRCodeText = styled.span`
  position: relative;
  top: 80px;
`;

export const PaymentButtonContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexAlign = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledPopup = styled(Popup)`
  max-height: 100vh;
  overflow-y: auto;

  &-overlay {
    @media (max-width: 767px) {
      z-index: 1000 !important;
    }
  }

  &-content {
    width: 400px;
    border: 1px solid #dcdcdc;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    max-height: 100%;
    overflow-y: auto;
  }
`;

export const MobilePaymentList = styled.ul`
  display: flex;
  gap: 20px;
  flex-direction: column;
  list-style-type: none;
  padding-left: 0;
`;

export const MobilePaymentItem = styled.li<{ isActive: boolean }>`
  padding: 14px;
  box-shadow: 0 5px 10px rgba(0, 5, 10, 0);
  border-radius: 8px;
  border: 1px solid #dcdcdc;
  border-color: ${({ isActive }) => (isActive ? '#4872F2' : '#DCDCDC')};
`;

export const MobilePaymentHeader = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

export const MobilePaymentHeaderLeft = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-items: flex-start;
`;

export const SBPContainer = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
  margin-top: 14px;
`;

export const SBPPaymentButton = styled.button`
  width: 100%;
  background-color: #0d0437;
  padding: 8px;
  border: 0;
  border-radius: 4px;
  font-family: 'Open Sans', serif;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;

  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`;

export const BlueText = styled.div`
  color: #4872f2;
  margin-top: 7px;
  font-weight: 600;
`;

export const PaymentIcon = styled.img`
  margin-right: 7px;
  width: 25px;
  height: 25px;
`;

export const DesktopHeader = styled.div`
  margin-bottom: 20px;
`;

