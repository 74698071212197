import React, { useCallback, useEffect, useState } from 'react';
import Arrow from '../../../assets/images/triangle';
import styled from 'styled-components';
import { CategoryProps } from './types';
import { Collapse } from '@components/ui';

interface CategoryWrapperProps {
  active: boolean;
}

const CategoryWrapper = styled.div<{ disableBottomLine?: boolean }>`
  ${({ disableBottomLine }) =>
    !disableBottomLine ? `border-bottom: 1px solid #f2f2f2;` : ''}
`;
const CategoryContainer = styled.li<CategoryWrapperProps>`
  ${({ active, theme: { colors } }) => `
        list-style-type: none;
        padding: 24px 0;
        // border-bottom: 1px solid #F2F2F2;
        text-align: left;
        & .range-time-with-plane {
            margin-bottom: 24px;
        }
        & > :first-child {
            & > svg {
              fill: ${colors.gray};
              transform: rotateX(${!active ? '0' : '180deg'});
            }
          }
    `}
`;

const CategoryHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;

  & > svg {
    transition: transform 150ms;
    width: 8.7px;
    height: 5.61px;
    padding-right: 9px;
  }
`;

const CategoryName = styled.span`
  font-family: ${({ theme: { fonts } }) => fonts.regular};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  user-select: none;
  color: ${({ theme: { colors } }) => colors.blackGray};
  margin-right: 9px;
`;

const CategoryBody = styled.div`
  margin-top: 21px;
  min-width: calc(100% - 6px);
  padding-inline: 3px;
  padding-bottom: 3px;
  & > label {
    display: block;
    margin-bottom: 16px;
  }
`;

const CategoryResetButton = styled.button`
  background: transparent;
  width: 16px;
  height: 16px;
  padding: 0;
  position: relative;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 14px;
    width: 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: #b9b9b9;
    border-radius: 1px;
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

const CategoryResetButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
`;

const Category: React.FC<CategoryProps> = ({
  name,
  active = false,
  visible = true,
  children,
  resetAvailable = false,
  onReset,
  disableBottomLine,
}) => {
  const [isActive, setActive] = useState(active);

  useEffect(() => {
    setActive(active);
  }, [active]);

  const handleClick: React.MouseEventHandler<HTMLDivElement> =
    useCallback(() => {
      setActive((isActive) => !isActive);
    }, []);

  const handleReset: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    typeof onReset == 'function' && onReset();
  };

  if (!visible) return null;

  return (
    <CategoryWrapper
      className="category-wrapper"
      disableBottomLine={disableBottomLine}
    >
      <CategoryContainer active={isActive} className="category-container">
        <CategoryHeader onClick={handleClick}>
          <CategoryName>{name}</CategoryName>
          {name !== '' && <Arrow />}
          {resetAvailable && (
            <CategoryResetButtonWrapper>
              <CategoryResetButton onClick={handleReset} />
            </CategoryResetButtonWrapper>
          )}
        </CategoryHeader>
        <Collapse active={isActive}>
          <CategoryBody>{children}</CategoryBody>
        </Collapse>
      </CategoryContainer>
    </CategoryWrapper>
  );
};

export default Category;
