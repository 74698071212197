import React from 'react';
import { AcquiringCommissions } from '@modules/orders';
import { PAYMENT_METHODS } from '@components/common/PaymentContainer/constants';
import { Text } from '@components/ui';

import {
  IframeContainer as IframeContainerStyled,
  Iframe,
  FlexAlign,
} from './Payment.styles';
import styled from 'styled-components';

interface Props {
  acquiringCommission: AcquiringCommissions;
  chosenPaymentMethod: string;
  height: string;
  isAppeal?: boolean;
  paymentTinkoffUrl: string | null;
  paymentUrl: string | null;
  qrCode: string | null;
}

export const BlockText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3c3c3c;
  margin-bottom: 20px;

  &:first-child {
    margin-top: 10px;
  }
`;

const PaymentIframe: React.FC<Props> = (props) => {
  const {
    chosenPaymentMethod,
    height,
    isAppeal,
    acquiringCommission,
    paymentUrl,
    paymentTinkoffUrl,
    qrCode,
  } = props;

  return (
    <IframeContainerStyled
      id="payment_block"
      isRus={chosenPaymentMethod === PAYMENT_METHODS.RF_CARD}
      isHeight={height !== '0px'}
      isAppeal={isAppeal}
      isMTS={chosenPaymentMethod === PAYMENT_METHODS.CREDIT}
      isINT={chosenPaymentMethod === PAYMENT_METHODS.FOREIGN_CARD}
      isSBP={chosenPaymentMethod === PAYMENT_METHODS.SBP}
    >
      {chosenPaymentMethod === PAYMENT_METHODS.FOREIGN_CARD && (
        <BlockText>
          Оплата будет произведена в белорусских рублях по текущему курсу к
          российскому рублю.
        </BlockText>
      )}
      {chosenPaymentMethod === PAYMENT_METHODS.FOREIGN_CARD && (
        <BlockText>
          Включён сбор за оплату зарубежной картой:{' '}
          <Text fontWeight={'600'}>
            {' '}
            {acquiringCommission.acquiringAmount} ₽
          </Text>
        </BlockText>
      )}

      {chosenPaymentMethod !== PAYMENT_METHODS.SBP ? (
        <Iframe
          isRus={chosenPaymentMethod === PAYMENT_METHODS.RF_CARD}
          isMTS={chosenPaymentMethod === PAYMENT_METHODS.CREDIT}
          isINT={chosenPaymentMethod === PAYMENT_METHODS.FOREIGN_CARD}
          isHeight={height !== '0px'}
          isSBP={chosenPaymentMethod === PAYMENT_METHODS.SBP}
          id="iFrame1"
          src={
            paymentUrl
              ? paymentUrl
              : paymentTinkoffUrl
                ? paymentTinkoffUrl
                : undefined
          }
          loading="lazy"
        ></Iframe>
      ) : (
        <>
          {chosenPaymentMethod === PAYMENT_METHODS.SBP && (
            <BlockText>
              Отсканируйте QR-код с помощью своего смартфона или мобильного
              приложения банка.
            </BlockText>
          )}
          {qrCode && (
            <FlexAlign>
              <img
                src={`data:image/svg+xml;utf8,${encodeURIComponent(qrCode)}`}
              />
            </FlexAlign>
          )}
        </>
      )}
    </IframeContainerStyled>
  );
};

export default PaymentIframe;
