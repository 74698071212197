import React from 'react';
import styled from 'styled-components';
import { Point, Stop, TechLanding } from '@components/common';
import { Text } from '@components/ui';
import Tippy from '@tippyjs/react';
import { Helper } from '@utils';
import { useMediaQuery } from 'react-responsive';

interface Props {
  fromDate: string;
  toDate: string;
  stops: Stop[] | [];
  techLanding: TechLanding[] | [];
  duration: number;
}

const PlaceTimeWrapper = styled.div`
  display: flex;
`;

const Time = styled(Text)`
  font-size: 24px;
  line-height: 32px;
  display: inline-block;
  margin-bottom: 3px;

  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
`;

const ProgressInfo = styled.div`
  text-align: center;
  width: 100%;
  margin: 0 10px;

  span {
    margin-bottom: 7px;
  }
`;

const ProgressLine = styled.div`
  margin-top: 20px;
  height: 2px;
  background-color: #dcdcdc;
  position: relative;

  & > div {
    top: -8px;
  }

  & > :last-child {
    right: 0;
  }

  @media (max-width: 767px) {
    margin-top: 13px;
    & > div {
      top: -6px;
    }
  }
`;

const PointsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
`;

const PathTime = styled(Text)`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #737373;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  bottom: 3px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const Timeline: React.FC<Props> = (props) => {
  const { fromDate, toDate, stops, duration, techLanding } = props;

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const hasStops = stops.length > 0;
  const hasTechLanding = techLanding.length > 0;

  return (
    <PlaceTimeWrapper>
      <Time color="#3C3C3C" bold>
        {fromDate}
      </Time>
      <ProgressInfo>
        <ProgressLine>
          <PointsContainer>
            <Point />
            {!isMobile &&
              stops.map((x, key) => (
                <Tippy
                  key={key}
                  content={`Пересадка ${Helper.formatDuration(
                    x.duration,
                  )}, аэропорт ${x.city.airport}, ${x.city.city}`}
                  theme="light"
                  maxWidth={190}
                >
                  <div>
                    <Point key={key} upperText={x.city.code} />
                  </div>
                </Tippy>
              ))}
            {hasTechLanding && (
              <Point color={'red'} techLanding={techLanding[0]} />
            )}
            {isMobile && hasStops && (
              <Point
                mobileProps={{
                  duration: duration,
                  stopDuration: stops[0].duration,
                  stopCount: stops.length,
                  city: {
                    name: stops[0].city.city,
                    code: stops[0].city.code,
                  },
                  airport: {
                    name: stops[0].city.airport,
                    code: stops[0].city.code,
                  },
                }}
              />
            )}
            {isMobile && !hasStops && (
              <PathTime>{Helper.formatDuration(duration)}</PathTime>
            )}
            <Point />
          </PointsContainer>
        </ProgressLine>
      </ProgressInfo>
      <Time color="#3C3C3C" bold>
        {toDate}
      </Time>
    </PlaceTimeWrapper>
  );
};

export default Timeline;
