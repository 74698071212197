import React from 'react';
import { useDispatch } from 'react-redux';
import { AcquiringCommissions, getQrRequest } from '@modules/orders';
import { RoundIcon } from '@components/loader';
import NotifyLoader from '../NotifyLoader';
import { Hooks } from '@utils';
import { PAYMENT_METHODS } from '@components/common/PaymentContainer/constants';

import {
  Wrapper,
  ChoosePaymentBlock,
  ChoosePaymentElement,
  Title,
  FlexRow,
  BlueText,
  IframeContainer,
  IframeLoaderContainer,
  PaymentIcon,
  DesktopHeader,
} from './Payment.styles';
import { PaymentMethod } from '@components/common/PaymentContainer/PaymentContainer';
import PaymentIframe from '@components/common/PaymentContainer/PaymentIframe';

interface Props {
  acquiringCommissions: AcquiringCommissions[];
  canPay: boolean;
  chosenPaymentImage: string;
  chosenPaymentMethod: string;
  height: string;
  isAppeal?: boolean;
  isNotifyLoader: boolean;
  isPaymentLoader?: boolean;
  paymentMethods: PaymentMethod[];
  paymentQr: { svg: string } | null;
  paymentTinkoffQrNumber: number | null;
  paymentTinkoffUrl: string | null;
  paymentUrl: string | null;
  qrCode: string | null;
  orderNumber: number;
  orderPositionIds: string[];

  setChosenPaymentImage(img: string): void;

  setChosenPaymentMethod(method: string): void;

  setQrCodeIsLoading(state: boolean): void;

  setSbpModalIsOpen(state: boolean): void;
}

const PaymentDesktop: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { isMobile } = Hooks.useMediaSizes();
  const {
    acquiringCommissions,
    canPay,
    chosenPaymentImage,
    chosenPaymentMethod,
    height,
    isNotifyLoader,
    isPaymentLoader,
    paymentMethods,
    paymentQr,
    paymentTinkoffQrNumber,
    paymentTinkoffUrl,
    paymentUrl,
    qrCode,
    orderPositionIds,
    orderNumber,
  } = props;

  const choosePaymentClick = (item: PaymentMethod) => {
    if (item.label === PAYMENT_METHODS.SBP && paymentTinkoffQrNumber) {
      if (!paymentQr) {
        props.setQrCodeIsLoading(true);
      }
      dispatch(
        getQrRequest({
          orderNumber: orderNumber,
          orderPositionIds: orderPositionIds,
          tinkoffSettingType: 0,
          tinkoffQrType: isMobile ? 1 : 0,
        }),
      );
      props.setSbpModalIsOpen(true);
    } else {
      props.setChosenPaymentMethod(item.label);
      props.setChosenPaymentImage(item.img);
      if (item?.value) {
        dispatch(item.value);
      }
    }
  };

  return (
    <Wrapper>
      {isNotifyLoader && <NotifyLoader />}

      <>
        <DesktopHeader>
          <Title>Способы оплаты</Title>
        </DesktopHeader>
        {canPay && (
          <>
            {/* <PaymentHeading id="payment_block">Способы оплаты</PaymentHeading> */}
            {height === '0px' && (
              <ChoosePaymentBlock>
                {paymentMethods.map((item, index) => (
                  <>
                    <ChoosePaymentElement
                      key={index}
                      isActive={chosenPaymentMethod == item.label}
                      onClick={() => choosePaymentClick(item)}
                    >
                      <PaymentIcon src={item.img} /> <div> {item.label} </div>
                      <BlueText>
                        {item.label !== PAYMENT_METHODS.CREDIT ? (
                          <>
                            {item.amount
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            &nbsp;₽
                          </>
                        ) : (
                          <> &nbsp;</>
                        )}
                      </BlueText>
                    </ChoosePaymentElement>
                  </>
                ))}
              </ChoosePaymentBlock>
            )}

            {paymentUrl && !isPaymentLoader ? (
              <PaymentIframe
                acquiringCommission={acquiringCommissions[1]}
                chosenPaymentMethod={chosenPaymentMethod}
                height={height}
                paymentTinkoffUrl={paymentTinkoffUrl}
                paymentUrl={paymentUrl}
                qrCode={qrCode}
              />
            ) : (
              <IframeContainer
                isMTS={chosenPaymentMethod === PAYMENT_METHODS.CREDIT}
                isINT={chosenPaymentMethod === PAYMENT_METHODS.FOREIGN_CARD}
                isHeight={height !== '0px'}
                isSBP={chosenPaymentMethod === PAYMENT_METHODS.SBP}
              >
                <FlexRow>
                  <PaymentIcon src={chosenPaymentImage} /> {chosenPaymentMethod}
                </FlexRow>
                <IframeLoaderContainer>
                  <RoundIcon />
                </IframeLoaderContainer>
              </IframeContainer>
            )}
          </>
        )}
      </>
    </Wrapper>
  );
};

export default PaymentDesktop;
